import { Image } from '../Image';
import { Link } from '../Link';

export function MenuDrawer({
  handleMenuDrawerClose,
  handleMenuDrawerStayOpen,
  handleMenuHoverOut,
  menuDrawerContent,
}) {
  const { imageLinks, links, mainLink } = { ...menuDrawerContent };
  const hasContent = imageLinks?.length > 0 || links?.length > 0;

  return (
    <div
      className={`absolute left-0 top-[100%] hidden w-full origin-top border-border bg-background transition duration-200 lg:block ${
        hasContent ? 'scale-y-100 shadow-hairline-b' : 'scale-y-0'
      }`}
      onMouseEnter={handleMenuDrawerStayOpen}
      onMouseLeave={handleMenuHoverOut}
    >
      {hasContent && (
        <div className="mx-[auto] flex flex-col max-w-[70rem] gap-5 p-5 md:p-10">
          <div className="grid grid-cols-[repeat(auto-fit,_minmax(240px,_1fr))] gap-5">
            {links?.map(({ link , links}, index) => {
              return (
                <div key={index} className="flex flex-col items-center">
                  <ul className="flex flex-col gap-[5px]">
                    <li className="mb-2.5">
                      <Link
                        aria-hidden={!hasContent}
                        aria-label={
                          link?.newTab 
                            ? `Open in a new tab to view ${link?.text}` 
                            : link?.text
                        }
                        className="text-body-lg uppercase font-medium text-link"
                        href={link?.url}
                        newTab={link?.newTab}
                        onClick={handleMenuDrawerClose}
                        tabIndex={hasContent ? '0' : '-1'}
                        type={link?.type}
                      >
                        {link?.text}
                      </Link>
                    </li>
                    {links?.map(({ link }, index) => {
                      return (
                        <li key={index}>
                          <Link
                            aria-hidden={!hasContent}
                            aria-label={
                              link?.newTab 
                                ? `Open in a new tab to view ${link?.text}` 
                                : link?.text
                            }
                            className="hover-text-underline"
                            href={link?.url}
                            newTab={link?.newTab}
                            onClick={handleMenuDrawerClose}
                            tabIndex={hasContent ? '0' : '-1'}
                          >
                            {link?.text}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
          <ul className="w-full grid grid-cols-8 gap-5">
            {imageLinks?.map(({ alt, caption, image, link }, index) => {
              return (
                <li key={index}>
                  <Link
                    aria-hidden={!hasContent}
                    aria-label={
                      link?.newTab
                        ? `Open in a new tab to view ${caption}`
                        : caption
                    }
                    href={link?.url}
                    newTab={link?.newTab}
                    onClick={handleMenuDrawerClose}
                    tabIndex={hasContent ? '0' : '-1'}
                    type={link?.type}
                  >
                    <div className="relative aspect-[16/9] w-full overflow-hidden bg-offWhite">
                      {image?.src && (
                        <Image
                          alt={alt}
                          className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
                          crop="center"
                          height={Math.floor(400 / (16 / 9))}
                          src={image.src}
                          width="400"
                        />
                      )}
                    </div>

                    <p className="mt-3 text-sm">{caption}</p>
                  </Link>
                </li>
              );
            })}
          </ul>
          {mainLink?.text && (
            <Link
              aria-hidden={!hasContent}
              aria-label={mainLink.text}
              aria-label={
                mainLink?.newTab
                  ? `Open in a new tab to view ${mainLink?.text}`
                  : mainLink?.text
              }
              className="text-link mx-auto"
              href={mainLink.url}
              newTab={mainLink.newTab}
              onClick={handleMenuDrawerClose}
              tabIndex={hasContent ? '0' : '-1'}
              type={mainLink.type}
            >
              {mainLink.text}
            </Link>
          )}
        </div>
      )}
    </div>
  );
}

MenuDrawer.displayName = 'MenuDrawer';
