import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSettings } from '@backpackjs/storefront'
import { useColorSwatches } from '../../hooks';

import { ProductItem } from '../ProductItem';
import { A11y, Pagination, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

export function CartUpsellRecommendations({ cartItems }) {
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [resetQuickShop, setResetQuickShop] = useState(false);

  const settings = useSettings();
  const { upsell } = { ...settings?.cart };
  const { message } = { ...settings?.cart?.upsell };
  const { swatchesMap } = useColorSwatches();

  const showUpsell = cartItems?.length;

  const handleSlideChange = (swiper) => {
    const newActiveIndex = swiper.activeIndex;
    setActiveSlideIndex(newActiveIndex);
    setResetQuickShop(true);
  };

  const fetchRecommendations = useCallback(async (cartItems) => {
    try {
      if (!cartItems) return;

      const endpoint = '/api/cartUpsellRecommendations';
      const options = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: JSON.stringify({
          action: 'getCartRecommendations',
          cartItems,
        }),
      };

      const response = await fetch(endpoint, options);
      const data = await response.json();
      const results = data[0]?.results || [];
      setRecommendedProducts(results);
    } catch (error) {
      console.error(`fetchRecommendations error: ${error.message}`);
      throw error;
    }
  }, []);

  useEffect(() => {
    // Call the fetchRecommendations function with the provided cartItems
    fetchRecommendations(cartItems);
  }, [fetchRecommendations, cartItems]);

  return showUpsell && recommendedProducts ? (
    <li className="shadow-hairline bg-background flex flex-col items-center mt-auto p-5 -mx-5">
      <h3 className="text-body font-medium mb-2">{upsell?.message}</h3>
      <div className="w-full overflow-hidden">
          <Swiper
            modules={[A11y, Pagination, Keyboard]}
            a11y={{ enabled: true }}
            onSlideChange={handleSlideChange}
            grabCursor
            keyboard={{
              enabled: true,
              onlyInViewport: true,
            }}
            pagination={{
              el: '.swiper-pagination',
              clickable: true,
              clickable: true,
              renderBullet(index, className) {
                return `<span class="bg-gray opacity-100 ${className}" aria-label="Upsell Slide ${index + 1}" type="button"></span>`;
              },
            }}
            role="region"
            aria-label="Cart Upsell Recommendations Slider"
            tabIndex="0"
          >
            {recommendedProducts.length > 0 &&
              recommendedProducts.map((product, index) => {
                const { url } = product?.mappings?.core;
                const cleanUrl = url ? url.substring(url.lastIndexOf('/products/') + '/products/'.length) : '';

                return (
                  <SwiperSlide key={`cart-upsell-slide-${index}`}>
                    <ProductItem
                      key={`${product.id}-${index}`}
                      enabledColorNameOnHover={false}
                      enabledColorSelector={upsell?.enabledColorSelector}
                      enabledQuickShop={upsell?.enabledQuickShop}
                      enabledStarRating={upsell?.enabledStarRating}
                      enabledQuickShopBIS={false}
                      handle={cleanUrl}
                      index={index}
                      swatchesMap={swatchesMap}
                      isCompact
                      resetQuickShop={activeSlideIndex !== index && resetQuickShop}
                    />
                  </SwiperSlide>
                );
              })
            }

            <div className="swiper-pagination !relative !top-0 active-bullet-black flex w-full justify-center gap-2 mt-3" />
          </Swiper>
        </div>
    </li>
  ): null;
}

CartUpsellRecommendations.displayName = 'CartUpsellRecommendations';
CartUpsellRecommendations.propTypes = {
  cartItems: PropTypes.string,
};