import { useCallback, useEffect } from 'react';
import { useCartItems, useCustomer, useRouter } from '@backpackjs/storefront';

import { useSearchspring } from './';
import { useSearchspringContext } from '../../contexts';
import { decodedId } from '../../utilities';

const LAST_VIEWED_KEY = `${
  process.env.SHOPIFY_DOMAIN?.split('.')[0]
}_last_viewed_product_ids`;

export const useSearchspringSearchResults = () => {
  const router = useRouter();
  const { requestSearchspring } = useSearchspring();
  
  const customer = useCustomer();
  const cartItems = useCartItems();
  const lastViewed =
    (typeof window !== 'undefined' &&
      window.localStorage.getItem(LAST_VIEWED_KEY)) ||
    '';

  const {
    state: {
      searchData,
      searchQuery,
    },
    actions: {
      setSearchData,
    },
  } = useSearchspringContext();

  const customerId = customer?.id || '';
  const cart = cartItems?.map((item) => item.variant.sku).join(',') || '';
  const searchIsAutocomplete = true;

  const { asPath } = router;
  const path = asPath?.split('?')[0];

  const deps = [
    searchQuery,
  ];

  // Get search results from query from ss
  const getSearchResults = useCallback(
    async () => {
      try {
        if (!searchQuery) {
          setSearchData(null);
          return null;
        }

        const shopper = decodedId(customerId)?.split('/').pop();

        const data = await requestSearchspring({
          action: 'getSearchResults',
          params: {
            query: searchQuery || '',
            resultsPerPage: 10, // limit # of results shown; integer from 1-100
            page: 1,
            searchIsAutocomplete,
            domain: `${process.env.SITE_URL}/${path}`, // full url of current page
            cart, // comma-separated list of product skus in the user's cart
            lastViewed, // comma-separated list of 5 most recent product ids or skus
            ...(shopper ? { shopper } : null), // customer id (if logged in)
          },
        });

        let _data = data;

        setSearchData(_data || null);
        // return _data || null;
      } catch (error) {
        console.error(`Error with getSearchResults: ${error.message}`);
        throw error;
      }
    },
    [...deps]
  );

  useEffect(() => {
    getSearchResults();
  }, [...deps]);

  useEffect(() => {
    return () => {
      setSearchData(null);
    };
  }, []);

  return {
    results: searchData?.results || [],
    totalResults: searchData?.pagination?.totalResults || 0,
    // id: searchData?.id || null,
  };
};