export function Schema() {
  return {
    category: 'Media',
    label: 'Images Grid',
    key: 'images-grid',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/social-feed-preview.jpg?v=1675730338',
    fields: [
      {
        label: 'Images',
        name: 'images',
        description: 'Grid requires four images',
        component: 'group-list',
        itemProps: {
          label: '{{item.alt}}',
        },
        validate: {
          maxItems: 64,
        },
        fields: [
          {
            label: 'Image Alt',
            name: 'alt',
            component: 'text',
            description: 'Brief description of image',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Image Link',
            name: 'link',
            component: 'link',
          },
        ],
        defaultItem: {
          alt: 'Man in white short sleeve shirt',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/kenzie-kraft-9QW52RyBLao-unsplash.jpg?v=1672348135',
          },
          url: 'https://www.instagram.com',
        },
        defaultValue: [
          {
            alt: 'Man in white short sleeve shirt',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/kenzie-kraft-9QW52RyBLao-unsplash.jpg?v=1672348135',
            },
            url: 'https://www.instagram.com',
          },
          {
            alt: 'Menswear everyday carry',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/nordwood-themes-Nv4QHkTVEaI-unsplash-2.jpg?v=1672787938',
            },
            url: 'https://www.instagram.com',
          },
          {
            alt: 'Man in white t-shirt leaning against wall',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/mad-rabbit-tattoo-X0Ob-bhTdz8-unsplash.jpg?v=1672787932',
            },
            url: 'https://www.instagram.com',
          },
          {
            alt: 'Rack of neutral tone shirts',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/fernando-lavin-fi5YSQfxbVk-unsplash.jpg?v=1672787923',
            },
            url: 'https://www.instagram.com',
          },
        ],
      },
      {
        label: 'Text Block',
        name: 'textBlock',
        description: 'Optional text block',
        component: 'group',
        fields: [
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'text',
            name: 'text',
            component: 'textarea',
          },
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Pop Up',
            name: 'popUp',
            description: 'Optional pop up',
            component: 'group',
            fields: [
              {
                label: 'Button Text',
                name: 'buttonText',
                component: 'text',
              },
              {
                label: 'Pop Up Heading',
                name: 'heading',
                component: 'text',
              },
              {
                label: 'Pop Up Text',
                name: 'text',
                component: 'textarea',
              },
              {
                label: 'HTML',
                name: 'html',
                component: 'html',
              },
              {
                label: 'Typeform id',
                name: 'typeformId',
                component: 'text',
              }
            ],
          },
        ],
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Full width, full bleed',
        fields: [
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Full Bleed',
            name: 'fullBleed',
            component: 'toggle',
            description: 'Removes padding of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Items Per Row',
            name: 'itemsPerRow',
            component: 'select',
            description: 'Number of items per row',
            options: [
              { label: '4', value: 'grid-cols-2 sm:grid-cols-4' },
              { label: '5', value: 'grid-cols-2 sm:grid-cols-5' },
              { label: '6', value: 'grid-cols-2 sm:grid-cols-6' },
              { label: '7', value: 'grid-cols-2 sm:grid-cols-7' },
              { label: '8', value: 'grid-cols-2 sm:grid-cols-8' },
            ],
          },
          {
            label: 'Image Size',
            name: 'imageSize',
            component: 'text',
            description: 'Size of image',
          },
        ],
        defaultValue: {
          fullWidth: false,
          fullBleed: true,
          itemsPerRow: 'grid-cols-2 sm:grid-cols-4',
        },
      },
    ],
  };
}
