import PropTypes from 'prop-types';
import { Svg } from '../Svg';

export function RatingBarGraph({ ratingCounts, selectedRate, onRateClick }) {
  // Find the maximum count to calculate the width of the bars
  const maxCount = Math.max(...ratingCounts.map((rating) => rating.count));
  
  return (
    <div className="flex flex-col gap-[5px]">
      <p className='font-medium'>Rating</p>
      <div className="rating-bar-graph flex flex-col-reverse gap-[5px] mb-auto">
        {ratingCounts.map((rating) => (
          <button 
            key={rating.rating} 
            className={`relative rating-bar w-full flex items-center gap-[5px] ${
              rating.rating === selectedRate ? 'bg-white shadow-hairline p-[5px]' : ''
            }`}
            onClick={() => onRateClick(rating.rating)}
            aria-label={`Filter by ${rating.rating} star rating`}
            type="button"
            >
            <div className="w-[156px] flex items-center gap-[5px]">
              <div className="flex">
                <span className="text-sm">{rating.rating}</span>
                <Svg 
                  className="w-3"
                  src="/svgs/star-full.svg#star-full"
                  alt="Star Icon"
                  title={`${rating.rating} Star`}
                  viewBox="0 0 24 24"
                />
              </div>
              <div className="relative h-2 w-full bg-gray">
                <div
                  className="bar absolute h-2 bg-primary overflow-hidden"
                  style={{ width: `${(rating.count / maxCount) * 100}%` }}
                />
              </div>
            </div>
            
            <span className="text-xs">{rating.count}</span>
          </button>
        ))}
      </div>
    </div>
    
  );
};

RatingBarGraph.displayName = 'RatingBarGraph';
RatingBarGraph.propTypes = {
  ratingCounts: PropTypes.array.isRequired,
  selectedRate: PropTypes.number,
  onRateClick: PropTypes.func.isRequired,
};
