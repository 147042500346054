import { Link, TilesRow } from '../../snippets';
import { Schema } from './Tiles.schema';

export function Tiles({ cms }) {
  const { button, heading, hideHeading, slider, section, tiles, itemsPerRow } = cms;
  const { aspectRatio, bgColor, fullWidth, spacing, textColor, iconColor } = { ...section };

  const { sliderStyle } = { ...slider };
  const isFullBleedAndCentered =
    sliderStyle === 'fullBleed' || sliderStyle === 'fullBleedWithGradient';
  const isLoop = isFullBleedAndCentered || sliderStyle === 'containedWithLoop';
  const maxWidthClass =
    fullWidth || isFullBleedAndCentered
      ? 'max-w-[var(--content-max-xl-width)]'
      : 'max-w-[var(--content-max-lg-width)]';

  return (
    <div
      className={spacing}
      style={{ backgroundColor: bgColor }}
    >
      {heading && !hideHeading && (
        <div className={`${maxWidthClass}`}>
          <h2 className="text-title-h2 mb-6 md:mb-10">
            {heading}
          </h2>
        </div>
      )}

      <TilesRow
        slider={slider}
        sliderStyle={sliderStyle}
        isFullBleedAndCentered={isFullBleedAndCentered}
        isLoop={isLoop}
        aspectRatio={aspectRatio}
        maxWidthClass={maxWidthClass}
        textColor={textColor}
        iconColor={iconColor}
        tiles={tiles}
        itemsPerRow={itemsPerRow}
      />

      {button?.text && (
        <div className="mt-10 flex flex-col items-center">
          <Link
            aria-label={
              button?.newTab 
                ? `Open in a new tab to view ${button?.text}` 
                : button?.text
            }
            className={`${section?.buttonStyle || 'btn-primary'}`}
            href={button.url}
            newTab={button.newTab}
            type={button.type}
          >
            {button.text}
          </Link>
        </div>
      )}
    </div>
  );
}

Tiles.displayName = 'Tiles';
Tiles.Schema = Schema;
