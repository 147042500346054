import { useCartCount, useCustomer, useSettings } from '@backpackjs/storefront';

import { Link } from '../Link';
import { Svg } from '../Svg';
import { useGlobalContext } from '../../contexts';

export function Menu({
  handleOpenSidebar,
  handleMenuDrawerClose,
  handleMenuHoverIn,
  handleMenuHoverOut,
  menuDrawerContent,
}) {
  const customer = useCustomer();
  const {
    actions: { openCart, openSearch },
  } = useGlobalContext();
  const cartCount = useCartCount();
  const settings = useSettings();
  const { 
    links: additionalLinks,
    menuItems 
  } = { ...settings?.header?.menu };

  return (
    <div className="h-full flex flex-col">
      {additionalLinks?.length > 0 && (
        <div className="hidden items-center shadow-hairline md:px-5 lg:flex">
          <ul className="flex flex-row gap-5 py-1 mb-0">
            {additionalLinks.map(({ link }, index) => {
              return (
                <li key={index}>
                  <Link
                    aria-label={
                      link?.newTab 
                        ? `Open in a new tab to view ${link?.text}` 
                        : link?.text
                    }
                    href={link?.url}
                  >
                    {link?.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      <div className="relative z-[1] flex flex-1 h-full items-center shadow-hairline bg-white transition lg:px-5">
        <div className="h-full flex items-center pl-5 lg:hidden">
          <button
            aria-label="Open menu"
            className="w-6"
            onClick={handleOpenSidebar}
            type="button"
          >
            <Svg
              className="w-full text-text"
              src="/svgs/menu.svg#menu"
              alt="Open Menu Icon"
              title="Menu"
              viewBox="0 0 32 32"
            />
          </button>
        </div>
        <div className="flex items-center md:mr-2.5 max-lg:ml-5">
          <Link aria-label="Go to homepage" href="/">
            <Svg
              className="text-text hidden lg:block"
              src="/svgs/logo.svg#logo"
              alt="Rhoback Logo"
              title="Rhoback"
              viewBox="0 0 210 50"
              width="181px"
              height="43px"
            />
            <Svg
              className="text-text block lg:hidden"
              src="/svgs/logo-mobile.svg#logo-mobile"
              alt="Rhoback Logo"
              title="Rhoback"
              viewBox="0 0 128 30"
              width="128px"
              height="30px"
            />
          </Link>
        </div>
        <nav className="hidden h-full lg:flex">
          <ul className="flex">
            {menuItems?.map((item, index) => {
              const isHovered =
                item.menuItem?.text === menuDrawerContent?.menuItem?.text;

              return (
                <li key={index} className="flex">
                  <Link
                    aria-label={item.menuItem?.text}
                    className="group relative flex items-center px-2 transition border-y-4 border-transparent hover:border-b-primary active:border-b-secondary xl:px-4 "
                    href={item.menuItem?.url}
                    onClick={handleMenuDrawerClose}
                    onMouseEnter={() => handleMenuHoverIn(index)}
                    onMouseLeave={handleMenuHoverOut}
                  >
                    <p className="text-nav">{item.menuItem?.text}</p>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>

        <div className="flex items-center justify-end gap-2.5 ml-auto max-lg:mr-5">
          <button
            aria-label="Open search"
            className="w-5"
            onClick={openSearch}
            type="button"
          >
            <Svg
              className="w-full text-text"
              src="/svgs/search.svg#search"
              alt="Open Search Icon"
              title="Search"
              viewBox="0 0 32 32"
            />
          </button>

          <Link
            aria-label="Go to account page"
            href={customer ? '/account/orders' : '/account/login'}
          >
            <Svg
              className="w-5 text-text"
              src="/svgs/account.svg#account"
              alt="Open Account Icon"
              title="Account"
              viewBox="0 0 32 32"
            />
          </Link>

          <div className="relative">
            <button
              aria-label="Open cart"
              className="flex items-center"
              onClick={openCart}
              type="button"
            >
              <Svg
                className="w-5 text-text"
                src="/svgs/cart.svg#cart"
                alt="Open Cart Icon"
                title="Cart"
                viewBox="0 0 32 32"
              />
              <span className="text-label-sm w-4 whitespace-nowrap pl-px font-bold">
                ({cartCount || 0})
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

Menu.displayName = 'Menu';
