import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { useDataLayerActions } from '../../hooks';  
import useCollection from './CollectionSearchspring/useCollection';

import { CollectionSearchspring } from './CollectionSearchspring';
import { CollectionPack } from './CollectionPack';

export function Collection({
  handle = undefined,
  id = undefined,
  appEnabled,
  products = [],
  productsReady = true,
  title = '',
}) {
  const isFirstLoad = useRef(true);
  const router = useRouter();

  const [savedProductId, setSavedProductId] = useState(null);

  const {
    searchspringProducts,
    totalProducts,
    selectedResultsPerPage,
    currentResultsPage,
    totalPages,
    loadedPages,
    selectedSort,
    sortOptions,
    filters,
    filterSummary,
    loadSort,
    selectSort,
    loadFilters,
    addToFilters,
    removeFromFilters,
    clearFilters,
    goToPreviousPage,
    goToFollowingPage,
    goToSpecificPage,
    updatePageInView,
  } = useCollection();

  const loadingGrid = new Array(48).fill(0);

  useEffect(() => {
    if (router.isReady && isFirstLoad.current) {
      const filtersFromParams = Object.entries(router.query).flatMap(([key, value]) => {
        if (!key.startsWith('filter.')) return [];
        return Array.isArray(value) ? value.map(v => ({ key, value: v })) : [{ key, value }];
      });

      const sortFromParams = Object.entries(router.query).flatMap(([key, value]) => {
        if (!key.startsWith('sort.')) return [];
        return Array.isArray(value) ? value.map(v => ({ field: key.replace(/^sort\./, ''), direction: v })) : [{ field: key.replace(/^sort\./, ''), direction: value }];
      });

      const handleLoad = () => {
        if (filtersFromParams.length > 0) {
          loadFilters(filtersFromParams);
        }
        if (sortFromParams.length > 0) {
          loadSort(sortFromParams);
        }
        if (router.query.page) {
          goToSpecificPage(router.query.page);
        }
      };
      setTimeout(() => {
        handleLoad();
        isFirstLoad.current = false;
      }, 100);
    }
  }, [router.isReady, router.query]);

  useEffect(() => {
    const savedProductId = sessionStorage.getItem('savedProductId');
    if (savedProductId) {
      setSavedProductId(savedProductId);
      sessionStorage.removeItem('savedProductId');
    }
  }, []);

  const { sendViewCollectionEvent } = useDataLayerActions();
  useEffect(() => {
    sendViewCollectionEvent({
      products: searchspringProducts,
    });
  }, [
    // dependency from collection only whether if products is empty or not
    `${id}${!!totalProducts}`,
    sendViewCollectionEvent,
  ]);

  const collectionComponent = appEnabled ? (
    <>
      {searchspringProducts.length > 0 ? (
        <CollectionSearchspring
          handle={handle}
          title={title}
          searchspringProducts={searchspringProducts}
          totalProducts={totalProducts}
          selectedResultsPerPage={selectedResultsPerPage}
          currentResultsPage={currentResultsPage}
          totalPages={totalPages}
          loadedPages={loadedPages}
          selectedSort={selectedSort}
          sortOptions={sortOptions}
          filters={filters}
          filterSummary={filterSummary}
          selectSort={selectSort}
          loadFilters={loadFilters}
          addToFilters={addToFilters}
          removeFromFilters={removeFromFilters}
          clearFilters={clearFilters}
          goToPreviousPage={goToPreviousPage}
          goToFollowingPage={goToFollowingPage}
          goToSpecificPage={goToSpecificPage}
          updatePageInView={updatePageInView}
          savedProductId={savedProductId}
        />
      ) : (
        <div className="mx-auto flex max-w-[var(--content-max-xl-width)] flex-col">
          <div className="h-[48px] filter-panel flex flex-row flex-wrap bg-background w-full"></div>
          <div className="collection-container mt-5 flex w-full flex-row flex-wrap">
            <div className="order-4 w-full">
              <div className="mx-auto grid grid-cols-2 gap-x-5 gap-y-6 md:grid-cols-2 lg:grid-cols-4 md:px-0">
                {loadingGrid.map((_, index) => (
                  <li className="flex flex-col" key={index}>
                    <div className="w-full aspect-[159/232] bg-offWhite"></div>
                  </li>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}  
    </>
  ) : (
    <CollectionPack
      handle={handle}
      id={id}
      productsReady={productsReady}
      products={products}
    />
  );

  return (
    <>
      {collectionComponent}
    </>
  );
}

Collection.displayName = 'Collection';
Collection.propTypes = {
  handle: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  productsReady: PropTypes.bool,
};