import { useMemo } from 'react';

import { CollectionPackDropdownFilter } from './CollectionPackDropdownFilter';
import { CollectionPackFiltersSummary } from './CollectionPackFiltersSummary';
import { CollectionPackMenuSidebar } from '../CollectionPackMenuSidebar';
import { Svg } from '../../../Svg';

export function CollectionPackMobileFilters({
  activeFilters,
  addFilter,
  clearFilters,
  collectionCount,
  filterByInStock,
  filters,
  filtersMap,
  inStockFilterEnabled,
  inStockLabel,
  optionsMaxCount,
  removeFilter,
  setFilterByInStock,
  showCount,
  swatchesMap,
  isOpen,
  setIsOpen,
}) {
  const totalFilters = useMemo(() => {
    return Object.values(activeFilters || {}).reduce((acc, filterValues) => {
      return acc + filterValues.length;
    }, 0);
  }, [activeFilters]);

  return (
    <>
      <button
        aria-label="Open filters sidebar"
        className="shadow-hairline flex w-max items-center justify-between bg-offWhite p-3 text-base lg:hidden lg:px-0"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <Svg
          className="text-text"
          src="/svgs/filter.svg#filter"
          alt="Filter Icon"
          title="Filter"
          viewBox="0 0 24 24"
          width="24px"
          height="24px"
        />
        <div className="flex flex-1 items-center gap-2 overflow-hidden xs:gap-3">
          <h3 className="text-base">Filters</h3>

          {totalFilters > 0 && (
            <p className="truncate text-xs text-mediumGray xs:text-sm">
              {totalFilters} Selected
            </p>
          )}
        </div>
      </button>

      <CollectionPackMenuSidebar
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Filters"
      >
        <div className="shadow-hairline-b sticky top-0 z-[1] bg-offWhite p-5">
          <div className="mb-4 flex justify-between gap-2">
            <h3 className="text-nav">
              Filters Summary{' '}
              <span className="text-xs">
                {totalFilters ? `(${totalFilters})` : ''}
              </span>
            </h3>

            {totalFilters > 0 && (
              <button
                className="text-main-underline text-xs"
                onClick={() => {
                  clearFilters();
                  const clearFilterElement = document.querySelector('[clear-filter-icons="true"]');
                  if (clearFilterElement) clearFilterElement.click();
                }}
                type="button"
              >
                Clear All
              </button>
            )}
          </div>

          <div className="scrollbar-hide max-h-[10rem] min-h-[2rem] overflow-y-auto">
            {totalFilters ? (
              <CollectionPackFiltersSummary
                activeFilters={activeFilters}
                filtersMap={filtersMap}
                removeFilter={removeFilter}
              />
            ) : (
              <p className="leading-[2rem] text-mediumGray">
                No filters selected yet
              </p>
            )}
          </div>
        </div>

        <ul className="bg-white">
          {filters.map((filter, index) => {
            if (
              !filter.values.length ||
              (filter.values.length === 1 &&
                filter.values[0].count === collectionCount)
            )
              return null;

            return (
              <li key={index} className={filter.hideFromFilter ? 'hidden' : ''}>
                <CollectionPackDropdownFilter
                  activeFilters={activeFilters}
                  addFilter={addFilter}
                  defaultOpen={filter.defaultOpenMobile}
                  filter={filter}
                  optionsMaxCount={optionsMaxCount}
                  removeFilter={removeFilter}
                  showCount={showCount}
                  swatchesMap={swatchesMap}
                />
              </li>
            );
          })}
        </ul>

        {inStockFilterEnabled && (
          <button
            aria-label={inStockLabel}
            className={`shadow-hairline-b group mb-px flex h-14 w-full items-center gap-3 px-4 text-left text-base transition md:gap-2 hover:md:text-text ${
              filterByInStock ? 'text-text' : 'text-mediumGray'
            }`}
            onClick={() => setFilterByInStock(!filterByInStock)}
            type="button"
          >
            <div
              className={`shadow-hairline relative flex h-5 w-5 items-center justify-center overflow-hidden transition md:mt-[3px] md:h-[18px] md:w-[18px] group-hover:md:border-text ${
                filterByInStock ? 'border border-text bg-black' : 'bg-offWhite'
              }`}
            >
              <div
                className={`absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 border-white transition-[border-width] duration-100 ${
                  filterByInStock
                    ? 'border-[0px] md:border-[0px]'
                    : 'border-[0px]'
                }`}
              />

              <Svg
                src="/svgs/checkmark.svg#checkmark"
                viewBox="0 0 24 24"
                className={`pointer-events-none w-3 text-white transition ${
                  filterByInStock ? 'opacity-100' : 'opacity-0'
                }`}
                alt="Select Filter Icon"
                title="Filter Checkmark"
              />
            </div>

            <p className="flex-1">{inStockLabel}</p>
          </button>
        )}
      </CollectionPackMenuSidebar>
    </>
  );
}

CollectionPackMobileFilters.displayName = 'CollectionPackMobileFilters';
