import { useCallback, useEffect, useState } from 'react';
import { useRouter } from '@backpackjs/storefront';

import { useSearchspring, useSearchspringCollectionSort, useSearchspringCollectionFilters } from './';
import { useSearchspringContext } from '../../contexts';

export const useSearchspringCollection = () => {
  const router = useRouter();
  const { requestSearchspring } = useSearchspring();
  const [{ selectedSort }, { sortCollection }] = useSearchspringCollectionSort();
  const [{ selectedFilters }] = useSearchspringCollectionFilters();

  const {
    state: {
      collection,
      selectedResultsPerPage: resultsPerPage,
      currentResultsPage: currentPage,
    },
    actions: { setCollection },
  } = useSearchspringContext();

  const [allPageResults, setAllPageResults] = useState([]);
  const [previousFilters, setPreviousFilters] = useState([]);
  const [previousSort, setPreviousSort] = useState(null);
  const [previousPage, setPreviousPage] = useState(1);

  const collectionHandle = router?.query?.handle;
  const { asPath }  = router;
  const path = asPath?.split('?')[0];

  const deps = [
    collectionHandle,
    selectedSort?.field,
    selectedSort?.direction,
    JSON.stringify(selectedFilters),
    resultsPerPage,
    currentPage,
    path,
  ];

  // Get collection from ss
  const getCollection = useCallback(async () => {

    try {
      const data = await requestSearchspring({
        action: 'getCollection',
        params: {
          collectionHandle,
          sort: selectedSort,
          filters: selectedFilters,
          page: currentPage,
          resultsPerPage,
          domain: `${process.env.SITE_URL}/${path}`, // full url of current page
        },
      });

      let _data = data;
      const banners = data?.merchandising?.content?.inline;

      if (banners?.length) {
        const results = banners.reduce(
          (arr, item, index) => {
            const pos = item.config.position.index;

            if (
              pos >= (currentPage - 1) * _data?.pagination?.perPage &&
              pos < currentPage * _data?.pagination?.perPage
            ) {
              const banner = {
                isBanner: true,
                value: item.value,
                id: `banner-index-${index}`,
              };

              arr.splice(
                pos - (currentPage - 1) * _data?.pagination?.perPage,
                0,
                banner
              );
            }

            return arr;
          },
          [..._data.results]
        );
        _data = { ..._data, results };
      }

      let newResults;
      if (JSON.stringify(selectedFilters) !== JSON.stringify(previousFilters) || JSON.stringify(selectedSort) !== JSON.stringify(previousSort)) {
        newResults = [...(_data?.results || [])];
      } else {
        if (currentPage < previousPage) {
          newResults = [...(_data?.results || []), ...allPageResults];
        } else {
          newResults = [...allPageResults, ...(_data?.results || [])];
        }
      }
      setAllPageResults(newResults);
      setPreviousFilters(selectedFilters);
      setPreviousSort(selectedSort);
      setPreviousPage(currentPage);
      setCollection(_data || null);
    } catch (error) {
      console.error(`Error with getCollection: ${error.message}`);
      throw error;
    }
  }, [...deps]);

  useEffect(() => {
    getCollection();
  }, [...deps]);

  useEffect(() => {
    return () => setCollection(null);
  }, []);

  useEffect(() => {
    sortCollection({ field: null, direction: null });
    return () => sortCollection({ field: null, direction: null });
  }, [collectionHandle]);

  return {
    // products: collection?.results || null,
    products: allPageResults,
    totalProducts: collection?.pagination?.totalResults || 0,
    merchandising: collection?.merchandising || null,
  };
};