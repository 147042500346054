import { useEffect, useState } from 'react';

import { Link } from '../../Link';
import { SearchHeader } from './SearchHeader';
import { SearchInput } from './SearchInput';
import { SearchResults } from './SearchResults';
import { SearchSuggestions } from './SearchSuggestions';
import {
  useSearchCollectionResults,
  useSearchProductResults,
} from '../../../hooks';
import { useGlobalContext } from '../../../contexts';

export function FuseSearch() {
  const {
    state: { searchOpen },
    actions: { closeSearch },
  } = useGlobalContext();

  const [rawTerm, setRawTerm] = useState('');
  const [term, setTerm] = useState(rawTerm);

  const { productResults } = useSearchProductResults({
    term,
    mounted: searchOpen,
  });
  const { collectionResults } = useSearchCollectionResults({
    term,
    mounted: searchOpen,
  });

  const hasProductResults = productResults?.length > 0;
  const hasCollectionResults = collectionResults?.length > 0;
  const hasResults = hasProductResults || hasCollectionResults;

  useEffect(() => {
    // Debounce raw term
    const debouncedHandler = window.requestTimeout(() => {
      setTerm(rawTerm);
    }, 300);
  
    // Event listener for "Escape" key press
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeSearch();
      }
    };
    if (searchOpen) {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        window.clearRequestTimeout(debouncedHandler);
      };
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.clearRequestTimeout(debouncedHandler);
    };
  }, [searchOpen, closeSearch, rawTerm]);

  return (
    <aside
      className={`fixed left-full top-0 z-[70] flex h-full w-full flex-col justify-between overflow-hidden bg-white transition md:max-w-[23.5rem] ${
        searchOpen
          ? 'pointer-events-auto -translate-x-full'
          : 'pointer-events-none translate-x-0'
      }`}
      aria-hidden={!searchOpen}
    >
      {searchOpen && (
        <>
          <SearchHeader closeSearch={closeSearch} />

          <SearchInput
            closeSearch={closeSearch}
            rawTerm={rawTerm}
            searchOpen={searchOpen}
            setRawTerm={setRawTerm}
          />

          {hasResults ? (
            <SearchResults
              closeSearch={closeSearch}
              collectionResults={collectionResults}
              productResults={productResults}
            />
          ) : (
            <SearchSuggestions term={term} setRawTerm={setRawTerm} />
          )}

          {hasProductResults && (
            <div className="border-t border-t-border p-4">
              <Link
                aria-label="See all search results"
                className="btn-secondary w-full"
                href={{ pathname: '/pages/search', query: { term } }}
                onClick={closeSearch}
              >
                See All {productResults.length} Results
              </Link>
            </div>
          )}
        </>
      )}
    </aside>
  );
}

FuseSearch.displayName = 'FuseSearch';
