import { useCallback } from 'react';
import { useCountry } from '@backpackjs/storefront';

import { fetchProductsFromHandles } from '../../../utilities';
import {
  useLocalizedProducts,
  useLocalizedSelectedVariant,
} from '../../localization';

const isDevelopment = process.env.NODE_ENV === 'development';

export const useDataLayerActions = () => {
  const { getLocalizedVariant } = useLocalizedSelectedVariant();
  const { getLocalizedProductsWithFirstVariant } = useLocalizedProducts();
  const { country } = useCountry();
  const countryCode = country?.isoCode;

  const sendClickProductItemEvent = useCallback(
    async ({
      isSearchResult,
      listIndex,
      localized,
      product,
      selectedVariant,
    }) => {
      try {
        if (!selectedVariant || typeof listIndex !== 'number') return;

        const localizedVariant = {
          ...selectedVariant,
          priceV2: localized?.priceV2 || selectedVariant?.priceV2,
          compareAtPriceV2:
            localized?.compareAtPriceV2 || selectedVariant?.compareAtPriceV2,
          image: selectedVariant.image || product?.featuredImage || '',
          index: listIndex,
          product: {
            ...selectedVariant?.product,
            vendor: product?.vendor,
          },
          list: window.location.pathname.startsWith('/collections')
            ? window.location.pathname
            : '',
        };

        PubSub.publish(
          isSearchResult ? 'CLICK_SEARCH_ITEM' : 'CLICK_COLLECTION_ITEM',
          localizedVariant
        );
      } catch (error) {
        if (!isDevelopment) return;
        console.error(
          'DataLayer:sendClickProductItemEvent error',
          error.message
        );
      }
    },
    []
  );

  const sendViewCollectionEvent = useCallback(
    async ({ countryCode: passedCountryCode, products }) => {
      try {
        if (!products?.length) return;
        if (!passedCountryCode && !countryCode) return;

        const fullProducts = await fetchProductsFromHandles(
          products.slice(0, 7).map(({ handle }) => handle)
        );

        const localizedProducts = await getLocalizedProductsWithFirstVariant({
          products: fullProducts,
          countryCode: passedCountryCode || countryCode,
        });

        PubSub.publish('VIEW_COLLECTION_PAGE', localizedProducts);
      } catch (error) {
        if (!isDevelopment) return;
        console.error('DataLayer:sendViewCollectionEvent error', error.message);
      }
    },
    [countryCode]
  );

  const sendViewProductEvent = useCallback(
    async ({
      countryCode: passedCountryCode,
      product,
      selectedVariant: _selectedVariant,
    }) => {
      try {
        if (!product) return;
        if (!passedCountryCode && !countryCode) return;
        let selectedVariant = _selectedVariant;
        // if no selected variant is passed, use the first variant
        if (!selectedVariant) {
          selectedVariant = product.variants?.[0];
        }
        if (!selectedVariant) return;

        let localizedVariant = await getLocalizedVariant({
          variant: selectedVariant,
          countryCode: passedCountryCode || countryCode,
        });

        localizedVariant = {
          ...localizedVariant,
          image: localizedVariant.image || product.featuredImage,
          product: {
            ...localizedVariant.product,
            vendor: product.vendor,
          },
        };

        PubSub.publish('VIEW_PRODUCT_PAGE', localizedVariant);
      } catch (error) {
        if (!isDevelopment) return;
        console.error('DataLayer:sendViewProductEvent error', error.message);
      }
    },
    [countryCode]
  );

  const sendViewSearchResultsEvent = useCallback(
    async ({ countryCode: passedCountryCode, isSearchPage, products }) => {
      try {
        if (!products?.length) return;
        if (!passedCountryCode && !countryCode) return;

        const fullProducts = await fetchProductsFromHandles(
          products.slice(0, 7).map(({ handle }) => handle)
        );

        const localizedProducts = await getLocalizedProductsWithFirstVariant({
          products: fullProducts,
          countryCode: passedCountryCode || countryCode,
        });

        PubSub.publish(
          isSearchPage ? 'VIEW_SEARCH_PAGE_RESULTS' : 'VIEW_SEARCH_RESULTS',
          localizedProducts
        );
      } catch (error) {
        if (!isDevelopment) return;
        console.error(
          'DataLayer:sendViewSearchResultsEvent error',
          error.message
        );
      }
    },
    [countryCode]
  );

  const sendSubscribeEvent = useCallback(({ email, phone }) => {
    if (email) {
      PubSub.publish('SUBSCRIBE_EMAIL', email);
    }
    if (phone) {
      PubSub.publish('SUBSCRIBE_PHONE', phone);
    }
  }, []);

  const sendLogInEvent = useCallback(() => {
    PubSub.publish('CUSTOMER_LOGGED_IN');
  }, []);

  const sendRegisterEvent = useCallback(() => {
    PubSub.publish('CUSTOMER_REGISTERED');
  }, []);

  return {
    sendClickProductItemEvent,
    sendViewCollectionEvent,
    sendViewProductEvent,
    sendViewSearchResultsEvent,
    sendSubscribeEvent,
    sendLogInEvent,
    sendRegisterEvent,
  };
};
