import { useEffect } from 'react';
import Script from 'next/script';

import { Schema } from './Referral.schema';

export function Referral({ cms }) {
  const { section } = cms;

  const maxWidthContainerClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-lg-width)]';

  // Function to manually initialize Yotpo widget
  const initializeYotpo = () => {
    yotpoWidgetsContainer.initWidgets();
  };

  useEffect(() => {
    // Ensure that the Yotpo global object and init function are available
    if (window.Yotpo && yotpoWidgetsContainer.initWidgets) {
      initializeYotpo();
    } else {
      // Listen for the Yotpo script to load then initialize
      document.addEventListener('yotpoScriptLoaded', initializeYotpo);
    }

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener('yotpoScriptLoaded', initializeYotpo);
    };
  }, []); // Dependency array is empty to run only once per mount

  return (
    <>
      <div
        className={`${section?.fullBleed ? '' : 'px-contained'} ${
          section?.verticalPadding ? 'py-contained' : ''
        }`}
      >
        <div
          className={`relative ${maxWidthContainerClass}`}
        >
          <div className="yotpo-widget-instance" data-yotpo-instance-id="429927"></div>
        </div>
      </div>    
      <Script 
        src="https://cdn-widgetsrepository.yotpo.com/v1/loader/CoJMVKJR-lsPoTFaOHzLUw" 
        strategy="afterInteractive"
        onLoad={() => {
          document.dispatchEvent(new Event('yotpoScriptLoaded'));
        }}
      />
    </>
  )

}

Referral.displayName = 'Referral';
Referral.Schema = Schema;