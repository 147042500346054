import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

export function useScrollRestoration() {
  const router = useRouter();
  const isBackNavigation = useRef(false);

  const saveScrollPosition = (url) => {
    console.log(`Saving scroll position for ${url}: ${window.scrollY}`);
    sessionStorage.setItem(`scrollPosition:${url}`, window.scrollY);
  };

  const restoreScrollPosition = (url) => {
    const scrollY = sessionStorage.getItem(`scrollPosition:${url}`);
    console.log(`Restoring scroll position for ${url}: ${scrollY}`);
    if (scrollY !== null) {
      window.scrollTo(0, parseInt(scrollY, 10));
    } else {
      console.log(`No scroll position saved for ${url}`);
    }
  };

  useEffect(() => {
    if (!('scrollRestoration' in window.history)) return;

    window.history.scrollRestoration = 'manual';

    const onBeforeUnload = (event) => {
      saveScrollPosition(router.asPath);
      delete event['returnValue'];
    };

    const onRouteChangeStart = (url) => {
      console.log(`Route change start: ${url}`);
      if (!isBackNavigation.current) {
        saveScrollPosition(router.asPath);
      }
    };

    const onRouteChangeComplete = (url) => {
      console.log(`Route change complete: ${url}`);
      if (isBackNavigation.current) {
        console.log(`Back navigation detected, restoring scroll position for ${url}`);
        setTimeout(() => restoreScrollPosition(url), 0); // Ensure the scroll restoration happens after the route change
        isBackNavigation.current = false;
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);
    router.events.on('routeChangeStart', onRouteChangeStart);
    router.events.on('routeChangeComplete', onRouteChangeComplete);

    router.beforePopState(() => {
      console.log('Before pop state');
      isBackNavigation.current = true;
      return true;
    });

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
      router.events.off('routeChangeStart', onRouteChangeStart);
      router.events.off('routeChangeComplete', onRouteChangeComplete);
      router.beforePopState(() => true);
    };
  }, [router]);

  useEffect(() => {
    // Restore scroll position on initial load
    restoreScrollPosition(router.asPath);
  }, []);
}