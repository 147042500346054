import PropTypes from 'prop-types';

import { BackInStockModal } from '../BackInStockModal';
import { Spinner } from '../Spinner';
import { useAddToCart } from '../../hooks';

export function AddToCart({
  addToCartText = '',
  className = '',
  isPdp = false,
  quantity = 1,
  selectedVariant = null,
  optionChanges = {},
  needsSelection = true,
  forceSoldOut = false,
  isMobileViewport = false,
  smoothScrollTo = () => {},
}) {
  const {
    state: { buttonText, isAdding, isLoading, isNotifyMe, isSoldOut, subtext },
    actions: { handleAddToCart, handleNotifyMe },
  } = useAddToCart({
    addToCartText,
    quantity,
    selectedVariant,
    forceSoldOut,
  });
  const selectedOptionsMap = selectedVariant?.selectedOptionsMap || {};
  const numberOfProperties = Object.keys(selectedOptionsMap).length;
  const hasColorProperty = Object.keys(selectedOptionsMap).some(key => key === "Color");
  const hasMoreThanOneProperty = numberOfProperties >= 2 || !hasColorProperty;
  const filteredOptions = Object.keys(selectedOptionsMap).filter(key => !optionChanges.hasOwnProperty(key));

  const selectButton = needsSelection && isPdp && hasMoreThanOneProperty;
  const text = selectButton ? `Select ${filteredOptions.join(' / ')}` : buttonText;
  const isDisabled = isSoldOut && !isNotifyMe || selectButton ;

  const selectClass = selectButton ? 'btn-atc-select' : '';

  let cursorClass = '';
  if (isAdding) cursorClass = 'cursor-default';
  else if (isLoading) cursorClass = 'cursor-wait';

  return (
    <div>
      <button
        aria-label={text}
        className={`${
          isNotifyMe || isSoldOut ? 'btn-inverse-dark' : 'btn-primary'
        } ${selectClass} relative w-full ${cursorClass} ${className}`}
        disabled={!isMobileViewport && isDisabled}
        onClick={() => {
          if (selectButton && isMobileViewport) {
            smoothScrollTo('select-option');
          } else if (isNotifyMe || isSoldOut) {
            handleNotifyMe(<BackInStockModal selectedVariant={selectedVariant} />);
          } else {
            handleAddToCart();
          }
        }}
        type="button"
      >
        {isAdding ? (
          <Spinner width="20" />
        ) : (
          <p className={`transition ${isLoading ? 'opacity-30' : ''}`}>
            {text}
          </p>
        )}
      </button>

      {isPdp && subtext && (
        <p className="mt-1 text-center text-xs">{subtext}</p>
      )}
    </div>
  );
}

AddToCart.displayName = 'AddToCart';
AddToCart.propTypes = {
  addToCartText: PropTypes.string,
  className: PropTypes.string,
  isPdp: PropTypes.bool,
  quantity: PropTypes.number,
  selectedVariant: PropTypes.object,
};
