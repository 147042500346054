import { useMemo } from 'react';

export function useCartItemPrices({ item }) {
  const { discountAllocations, estimatedCost, id, quantity, variant } = {
    ...item,
  };

  return useMemo(() => {
    const totalAmount = parseFloat(estimatedCost?.totalAmount?.amount || '0');

    // compare at price from variant
    const variantAmount = parseFloat(variant?.priceV2?.amount || '0');
    const variantCompareAtAmount = parseFloat(
      variant?.compareAtPriceV2?.amount || '0'
    );
    const compareAtPriceFromVariant =
      variantCompareAtAmount > variantAmount ? variantCompareAtAmount : 0;

    // compare at price from discount
    const subtotalAmount = parseFloat(
      estimatedCost?.subtotalAmount?.amount || '0'
    );
    const totalDiscount = parseFloat(
      discountAllocations?.[0]?.discountedAmount?.amount || '0'
    );
    const compareAtPriceFromDiscount = totalDiscount
      ? subtotalAmount / quantity
      : 0;

    // prices
    const price = totalAmount / quantity;
    const compareAtPrice =
      compareAtPriceFromVariant || compareAtPriceFromDiscount;

    return {
      price: `$${price.toFixed(2).replace('.00', '')}`,
      compareAtPrice: compareAtPrice
        ? `$${compareAtPrice.toFixed(2).replace('.00', '')}`
        : null,
    };
  }, [id, quantity]);
}
