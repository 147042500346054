import React, { useCallback, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

export function useCollectionProducts({
  pagination = {},
  goToNextPage,
  goToPrevPage,
  currentPage,
  totalPages,
  loadedPages,
  updatePageInView,
  savedProductId,
}) {
  const minLoadedPage = Math.min(...loadedPages);
  const maxLoadedPage = Math.max(...loadedPages);

  const isInfiniteScroll =
    pagination?.enabled && pagination?.loadType === 'infinite';

  const prevPageRef = useRef(currentPage);
  const { ref: loadMoreRef, inView: loadMoreIsVisible } = useInView({
    rootMargin: '600px',
    triggerOnce: false,
  });

  const loadMoreProducts = useCallback(() => {
    if (maxLoadedPage >= totalPages) return;
    if (!isInfiniteScroll) {
      goToNextPage();
      return;
    }
  }, [maxLoadedPage, currentPage, isInfiniteScroll, goToNextPage]);

  const loadPreviousProducts = useCallback(() => {
    if (minLoadedPage === 1) return;
    if (!isInfiniteScroll) {
      goToPrevPage();
      return;
    }
  }, [minLoadedPage, isInfiniteScroll, goToPrevPage]);

  // auto loads more products if infinite scroll is enabled
  useEffect(() => {
    if (!loadMoreIsVisible || !isInfiniteScroll) return;
    loadMoreProducts();
  }, [loadMoreIsVisible, isInfiniteScroll]);

  // Scroll to top when currentPage is 1
  useEffect(() => {
    if (prevPageRef.current > 1 && currentPage === 1) {
      window.scrollTo(0, 0);
    }
    prevPageRef.current = currentPage;
  }, [currentPage]);

  // Update pageInView when a new page is in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const page = parseInt(entry.target.getAttribute('page'), 10);
            updatePageInView(page);
          }
        });
      },
      { threshold: 0.9 }
    );

    const elements = document.querySelectorAll('li[page]');
    elements.forEach((element) => observer.observe(element));

    return () => {
      elements.forEach((element) => observer.unobserve(element));
    };
  }, [updatePageInView]);

  // Scroll to the element with the reference value of savedProductId
  useEffect(() => {
    if (savedProductId) {
      const timeoutId = setTimeout(() => {
        const element = document.querySelector(`[product-id="${savedProductId}"]`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 700); // Delay in milliseconds (500ms in this example)
  
      return () => clearTimeout(timeoutId); // Cleanup the timeout if the effect is re-run or unmounted
    }
  }, [savedProductId]);

  return {
    state: {
      isInfiniteScroll,
      minLoadedPage,
      maxLoadedPage,
      totalPages,
    },
    actions: {
      loadMoreProducts,
      loadPreviousProducts,
    },
    refs: {
      loadMoreRef,
    },
  };
}
