import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useCartAddItem } from '@backpackjs/storefront';
import { useGlobalContext } from '../../../contexts';
import { useMatchMedia } from '../../../hooks';

import { Spinner, BackInStockModal } from '../../';

export function QuickShopOption({
  inventory,
  inventoryFetched,
  optionName,
  selectedProduct,
  selectedVariant,
  value,
  isCompact = false,
  isSoldOut = false,
  enabledQuickShopBIS = true,
  quickShopBISSingleText = 'Notify Me',
}) {
  const {
    actions: { openCart, openModal },
  } = useGlobalContext();
  const { cartAddItem } = useCartAddItem();

  const isMobileViewport = useMatchMedia('(max-width: 1024px)');

  const [isAdding, setIsAdding] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const variantToAdd = useMemo(() => {
    return selectedProduct.variants?.find((variant) => {
      const color = variant.selectedOptionsMap?.Color;
      return (
        variant.selectedOptionsMap[optionName] === value &&
        (!color || color === selectedVariant.selectedOptionsMap?.Color)
      );
    });
  }, [optionName, selectedProduct.id, selectedVariant.id, value]);

  const variantInventory = inventory?.variants?.[variantToAdd?.id];
  const variantIsSoldOut = isSoldOut
    ? true
    : inventoryFetched && !variantInventory?.availableForSale;
  // const variantIsPreorder =
  //   !variantIsSoldOut && variantInventory?.currentlyNotInStock;
  const disabled = !inventoryFetched || !variantToAdd;
  const handleAddToCart = useCallback(async () => {
    if (!variantToAdd?.id || !inventoryFetched || isAdding) return;
    setIsAdding(true);
    await cartAddItem({
      merchandiseId: variantToAdd.id,
      quantity: 1,
    });
    setIsAdding(false);
    openCart();
  }, [variantToAdd?.id, inventoryFetched, isAdding]);

  const handleNotifyMe = useCallback(
    async (component) => {
      if (!variantToAdd?.id || !inventoryFetched) return;
      openModal(component);
    },
    [inventoryFetched, variantToAdd?.id]
  );

  const validClass =
    (disabled || variantIsSoldOut || isSoldOut) && !enabledQuickShopBIS
      ? 'cursor-not-allowed'
      : 'cursor-pointer hover:bg-primary hover:text-white';
  const unavailableClass = variantIsSoldOut
    ? 'text-gray overflow-hidden'
    : '';

  return (
    <button
      aria-label={value}
      className={`group/option flex h-full w-full items-center justify-center text-center transition px-1 ${variantIsSoldOut && enabledQuickShopBIS && !isMobileViewport ? '' : 'whitespace-nowrap'} ${validClass} ${unavailableClass} ${
        isCompact
          ? 'text-xs'
          : 'text-title-h6 '
      }`}
      disabled={(disabled || variantIsSoldOut) && !enabledQuickShopBIS}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (variantIsSoldOut && enabledQuickShopBIS) {
          handleNotifyMe(
            <BackInStockModal selectedVariant={variantToAdd} />
          );
        } else {
          handleAddToCart();
        }
      }}
      type="button"
    >
      {isAdding ? (
        <div className="text-mediumGray group-hover/option:text-white">
          <Spinner width="20" />
        </div>
      ) : (
        <>
          {isHovered && variantIsSoldOut && enabledQuickShopBIS && !isMobileViewport ? (
            <span className="text-xs animate-fade-in">{quickShopBISSingleText}</span> 
          ) : (
            value.split(' (')[0]
          )}
        </>
      )}
    </button>
  );
}

QuickShopOption.displayName = 'QuickShopOption';
QuickShopOption.propTypes = {
  inventory: PropTypes.object,
  inventoryFetched: PropTypes.bool,
  optionName: PropTypes.string,
  selectedProduct: PropTypes.object,
  selectedVariant: PropTypes.object,
  value: PropTypes.string,
  isCompact: PropTypes.bool,
  isSoldOut: PropTypes.bool,
  enabledQuickShopBIS: PropTypes.bool,
  quickShopBISSingleText: PropTypes.string,
};
