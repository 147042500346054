import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';

import { useSearchspringContext } from '../../contexts';

import { routerReplaceUrlParams } from './';

export const useSearchspringCollectionFilters = () => {
  const router = useRouter();
  const {
    state: { collection, selectedFilters },
    actions: { setCurrentResultsPage, setSelectedFilters, setLoadedPages },
  } = useSearchspringContext();
  
  const selectedFiltersDep = JSON.stringify(selectedFilters);

  // Logic to filter out facets with only one value
  // const totalResults = collection?.pagination?.totalResults || 0;

  // const filters = useMemo(() => {
  //   if (!totalResults || !collection?.facets?.length) return null;

  //   return collection.facets.filter((facet) => {
  //     if (facet.values.length === 1 && facet.values[0].count === totalResults) {
  //       return false;
  //     }
  //     return true;
  //   });
  // }, [collection?.facets, totalResults]);

  const filters = useMemo(() => {
    if (!collection?.facets?.length) return null;
    return collection.facets;
  }, [collection?.facets]);

  // get filter entries based on type
  const getFilterEntries = useCallback(({ field, option }) => {
    let _filters = {};

    // if option type is range, separate into low and high entries
    if (option.type === 'range') {
      const [key1, value1] = [`filter.${field}.low`, option.low];
      const [key2, value2] = [`filter.${field}.high`, option.high];
      _filters = {
        [`${key1}.${value1}`]: { key: key1, value: value1 },
        [`${key2}.${value2}`]: { key: key2, value: value2 },
      };
    } else {
      const [key, value] = [`filter.${field}`, option.value || ''];
      _filters = {
        [`${key}.${value}`]: { key, value },
      };
    }

    return _filters;
  }, []);

  // transform entries to modify URL params
  const transformEntries = useCallback(({ field, option }) => {
    const entries = getFilterEntries({ field, option });
    let transformedEntries = {};
    Object.keys(entries).forEach(key => {
      let entry = entries[key];
      transformedEntries[entry.key] = [entry.value];
    });
    return transformedEntries;
  }, []);

  // load filters from params
  const loadSelectedFilters = useCallback(
    (filtersFromParams) => {
      if (!filtersFromParams) {
        return console.error('loadSelectedFilters: missing filtersFromParams');
      }

      setSelectedFilters(filtersFromParams);
      setCurrentResultsPage(1);
      setLoadedPages([1]);
    },
    [selectedFiltersDep]
  );

  // add to filters using filterOptions
  const addToSelectedFilters = useCallback(
    ({ field, option }) => {
      if (!field || !option) {
        return console.error('addToSelectedFilters: missing field or option');
      }
      // Example ss field: 'product_type'
      // Example ss option: { active: false, type: 'value', value: 'Shirts' ... }

      // Entries to add to URL params
      const addedEntries = transformEntries({ field, option });

      // Update URL params
      routerReplaceUrlParams({
        router,
        entriesToAdd: Object.entries(addedEntries),
        keysToRemove: ['page'],
      });

      // create map of previous selected filters
      const selectedFiltersMap = selectedFilters?.reduce((obj, filter) => {
        obj[`${filter.key}.${filter.value}`] = filter;
        return obj;
      }, {});

      // prevent duplicates by spreading previous and new filters into new map
      const newSelectedFiltersMap = {
        ...selectedFiltersMap,
        ...getFilterEntries({ field, option }),
      };

      // convert filters map back into array
      const newSelectedFilters = Object.values(newSelectedFiltersMap);

      setSelectedFilters(newSelectedFilters);
      setCurrentResultsPage(1);
      setLoadedPages([1]);
    },
    [selectedFiltersDep]
  );

  // remove from selected filters
  const removeFromSelectedFilters = useCallback(
    ({ field, option }) => {
      if (!field || !option) {
        return console.error(
          'removeFromSelectedFilters: missing field or option'
        );
      }
      // Example ss field / option: 'collection_name', { value: 'Shirts', type: 'value }
      // Example ss field / option w/ range: 'ss_price', { high: '80', low: '60', type: 'range }

      // Entries to remove from URL params
      const removedEntries = transformEntries({ field, option });

      // Update URL params
      routerReplaceUrlParams({
        router,
        entriesToRemove: Object.entries(removedEntries),
        keysToRemove: ['page'],
      });

      if (!selectedFilters?.length) return;

      // filter out filters that match the field and option
      const newSelectedFilters = selectedFilters.filter((filter) => {
        if (option.type === 'range') {
          const isEntryLow =
            filter.key === `filter.${field}.low` && filter.value === option.low;

          const isEntryHigh =
            filter.key === `filter.${field}.high` &&
            filter.value === option.high;

          return !isEntryLow && !isEntryHigh;
        }
        const isEntry =
          filter.key === `filter.${field}` && filter.value === option.value;

        return !isEntry;
      });

      setSelectedFilters(newSelectedFilters);
      setCurrentResultsPage(1);
      setLoadedPages([1]);
    },
    [selectedFiltersDep]
  );

  // clear filters
  const clearSelectedFilters = useCallback(() => {
    if (!selectedFilters || selectedFilters.length === 0) {
      return;
    }
    // create map of removed entries
    const transformedFilters = selectedFilters.reduce((acc, filter) => {
      const key = filter.key; 
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(filter.value);
      return acc;
    }, {});

    // Update URL params
    routerReplaceUrlParams({
      router,
      keysToRemove: [...Object.keys(transformedFilters), 'page'],
    });

    setSelectedFilters(null);
    setCurrentResultsPage(1);
    setLoadedPages([1]);
  }, [selectedFilters]);

  return [
    // state
    {
      selectedFilters: selectedFilters,
      filters,
      filterSummary: collection?.filterSummary || null,
    },
    // actions
    {
      addToSelectedFilters,
      removeFromSelectedFilters,
      clearSelectedFilters,
      loadSelectedFilters,
    },
  ];
};