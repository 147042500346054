import { COLORS } from '../../settings/common';

export function Schema({ collection }) {
  if (!collection) return null;

  return {
    category: 'Collection',
    label: 'Collection Icon Row',
    key: 'collection-icon-row',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/icon-row-preview.jpg?v=1675730317',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Collection Icon Row Heading',
      },
      {
        label: 'Subtext',
        name: 'subtext',
        component: 'markdown',
        defaultValue:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
      {
        label: 'Icons',
        name: 'icons',
        component: 'group-list',
        itemProps: {
          label: '{{item.iconText}}',
        },
        fields: [
          {
            label: 'Icon',
            name: 'iconText',
            component: 'text',
            description: 'Icon name. Example: shipping. If filled it will override the icon select.',
          },
          {
            label: 'Icon',
            name: 'icon',
            component: 'select',
            options: [
              { label: 'Shipping', value: 'shipping' },
              { label: 'Customer Service', value: 'customer-service' },
              { label: 'Warranty', value: 'warranty' },
              { label: 'Innovation', value: 'innovation' },
            ],
          },
          {
            label: 'Label',
            name: 'label',
            component: 'text',
          },
          {
            label: 'Filter Link',
            name: 'filterLink',
            component: 'text',
            description: 'Filter link to apply when clicking on the icon. If empty, no filter will be applied.',
          }
        ],
        defaultValue: [
          { icon: 'shipping', label: 'Free Shipping' },
          { icon: 'customer-service', label: 'Top Customer Service' },
          { icon: 'warranty', label: 'Lifetime Warranty' },
          { icon: 'innovation', label: 'Innovative Designs' },
        ],
        defaultItem: { icon: 'shipping', label: 'New Icon' },
      },
      {
        label: 'Button Text',
        name: 'buttonText',
        component: 'text',
        description: 'Text for the button if used for filters',
        defaultValue: 'Select',
      },
      {
        label: 'Collapse Content on Mobile',
        name: 'collapseContent',
        component: 'toggle',
        description: 'Collapse content on mobile to show only the first 2 rows of icons.',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
        defaultValue: false,
      },
      {
        label: 'View More Text',
        name: 'viewMoreText',
        component: 'text',
        description: 'View more text to apply when clicking on the "view more" button.',
        defaultValue: 'View More',
      },
      {
        label: 'View Less Text',
        name: 'viewLessText',
        component: 'text',
        description: 'View less text to apply when clicking on the "view less" button.',
        defaultValue: 'View Less',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Background color, text color, icon color, full width',
        fields: [
          {
            label: 'Background Color',
            name: 'bgColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Text Color',
            name: 'textColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Icon Color',
            name: 'iconColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Spacing',
            name: 'spacing',
            component: 'select',
            description: 'Addjust space around this section',
            options: [
              { label: 'Vertical padding', value: 'py-contained' },
              { label: 'Remove top padding', value: 'py-contained !pt-0' },
              { label: 'Remove bottom padding', value: 'py-contained !pb-0' },
            ],
          },
          {
            label: 'Content Alignment (tablet/desktop)',
            name: 'alignmentDesktop',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              { label: 'Left', value: 'md:text-left md:items-start md:mx-0' },
              {
                label: 'Center',
                value: 'md:text-center md:items-center md:mx-auto',
              },
              { label: 'Right', value: 'md:text-right md:items-end md:mx-0' },
            ],
          },
          {
            label: 'Content Alignment (mobile)',
            name: 'alignmentMobile',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              { label: 'Left', value: 'text-left items-start mx-0' },
              { label: 'Center', value: 'text-center items-center mx-auto' },
              { label: 'Right', value: 'text-right items-end mx-0' },
            ],
          },
          {
            label: 'Icon Position',
            name: 'iconPosition',
            component: 'radio-group',
            variant: 'radio',
            options: [
              { label: 'Vertical', value: 'flex-col' },
              { label: 'Horizontal', value: 'flex-row' },
            ],
          },
          {
            label: 'Icon Size Desktop',
            name: 'iconSizeDesktop',
            component: 'select',
            options: [
              { label: 'Small', value: 'md:basis-20 md:max-w-20' },
              { label: 'Medium', value: 'md:basis-32 md:max-w-32' },
              { label: 'Large', value: 'md:basis-40 md:max-w-40' },
            ],
          },
          {
            label: 'Icon Size Mobile',
            name: 'iconSizeMobile',
            component: 'select',
            options: [
              { label: 'Small', value: 'basis-16 max-w-16' },
              { label: 'Medium', value: 'basis-24 max-w-24' },
              { label: 'Large', value: 'basis-32 max-w-32' },
            ],
          },
        ],
        defaultValue: {
          bgColor: 'var(--off-white)',
          textColor: 'var(--text)',
          iconColor: 'var(--primary)',
          fullWidth: false,
          alignmentDesktop: 'md:text-center md:items-center md:mx-auto',
          alignmentMobile: 'text-center items-center mx-auto',
          iconPosition: 'flex-col',
        },
      },
    ],
  };
}
