import { useState, useCallback } from 'react';
import { useRouter } from 'next/router';

import { Markdown, Svg } from '../../snippets';
import { Schema } from './CollectionIconRow.schema';

export function CollectionIconRow({ cms }) {
  const { heading, subtext, icons, buttonText, collapseContent, viewMoreText, viewLessText, section,  } = cms;
  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-lg-width)]';
  const alignmentClasses = `${section?.alignmentMobile} ${section?.alignmentDesktop}`;
  const iconPositionClass = `${section?.iconPosition}`;
  const iconSizeClasses = `${section?.iconSizeMobile} ${section?.iconSizeDesktop}`;

  const router = useRouter();
  const searchParams = router.asPath.split('?')[1];
  
  const [selectedFilterLink, setSelectedFilterLink] = useState(null);
  const [selected, setSelected] = useState(() => {
    const activeIconIndex = icons.findIndex(({ filterLink }) => {
      const formattedFilterLink = filterLink.replace('-', '=');
      return searchParams?.includes(formattedFilterLink);
    });
  
    if (activeIconIndex !== -1) {
      setSelectedFilterLink(icons[activeIconIndex].filterLink);
    }
  
    return activeIconIndex !== -1 ? activeIconIndex : null;
  });
  const [isExpanded, setIsExpanded] = useState(false);
  const [collapsableContent, setCollapsableContent] = useState(() => {
    const activeIconIndex = icons.findIndex(({ filterLink }) => {
      const formattedFilterLink = filterLink.replace('-', '=');
      return searchParams?.includes(formattedFilterLink);
    });
  
    return activeIconIndex !== -1 ? false : collapseContent;
  });

  const collapseContentClass = collapsableContent ? (isExpanded ? 'h-auto' : 'max-md:h-28') : '';

  const toggleExpansion = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <div
      className={`px-contained ${section?.spacing}`}
      style={{ backgroundColor: section?.bgColor, color: section?.textColor }}
    >
      <div
        className={`flex flex-col gap-4 md:gap-6 ${maxWidthClass} ${alignmentClasses}`}
      >
        {heading && (
          <h2 className={`text-title-h2 max-w-[46rem] ${alignmentClasses}`}>{heading}</h2>
        )}

        {subtext && (
          <div className={`max-w-[46rem] [&_p]:text-base [&_h2]:text-base [&_h1]:text-base [&_h3]:text-base [&_h4]:text-base [&_h5]:text-base [&_h6]:text-base [&_a]:underline ${alignmentClasses}`}>
            <Markdown>{subtext}</Markdown>
          </div>
        )}

        {icons?.length > 0 && (
          <div className="relative w-full">
            <ul className={`flex flex-wrap overflow-hidden gap-4 ${alignmentClasses} ${collapseContentClass}`}>
              {icons.map(({ iconText, icon, label, filterLink }, index) => {
                const iconName = iconText || icon;

                return (
                  <li
                    key={index}
                    className={`flex grow gap-1 md:gap-2.5 ${alignmentClasses} ${iconPositionClass} ${iconSizeClasses} ${selected !== null && selected !== index ? 'hidden' : ''}`}
                    {...(filterLink && {
                      onClick: () => {
                        if (selected === index) {
                          setSelected(null);
                        } else {
                          setSelected(index);
                        }
                        setSelectedFilterLink(filterLink);
                        const filterElement = document.querySelector(`[filter-value="${filterLink}"]`);
                        if (filterElement) filterElement.click();
                        setCollapsableContent(!collapsableContent);
                      },
                      'aria-label': `${iconName} filter`,
                      'clear-filter-type': `${filterLink}`,
                      type: "button",
                      role: "button",
                    })}
                  >
                    <Svg
                      className="w-8"
                      src={`/svgs/noprecache/icons/${iconName}.svg#${iconName}`}
                      style={{ color: section?.iconColor }}
                      alt={label || iconName}
                      title={label || iconName}
                      viewBox="0 0 24 24"
                    />

                    {label && <p className="font-bold">{label}</p>}
                  </li>
                );
              })}
            </ul>
            {selected !== null && (
              <>
                <button 
                  className="hidden"
                  onClick={() => {
                    setSelected(null);
                    setCollapsableContent(true);
                  }}
                  clear-filter-icons="true"
                >
                  { buttonText }
                </button>
                <button 
                  className="sticky right-0 h-auto max-w-full min-w-[3rem] text-sm text-main-underline"
                  onClick={() => {
                    const filterElement = document.querySelector(`[clear-filter-type="${selectedFilterLink}"]`);
                    if (filterElement) filterElement.click();
                    setSelected(null);
                  }}
                >
                  { buttonText }
                </button>
              </>
            )}
            {collapsableContent && (
              <div className={`w-full md:hidden ${isExpanded ? 'relative' : 'max-md:absolute bottom-0'}`}>
                <div className={`w-full h-16 bg-gradient-to-b from-transparent to-white ${isExpanded ? 'hidden' : ''}`}></div>
                <div className={`flex flex-row bg-white ${alignmentClasses} `}>
                  <button
                      onClick={toggleExpansion}
                      className="review-more-button flex-row text-sm mt-2.5"
                      aria-label={`${isExpanded ? 'Collapse' : 'Expand'}`}
                      type="button"
                    >
                      {isExpanded ? `- ${viewLessText}` : `+ ${viewMoreText}`}
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

CollectionIconRow.displayName = 'CollectionIconRow';
CollectionIconRow.Schema = Schema;
