import React, { useState, useEffect } from 'react';

import { CollectionSearchspringMenuSidebar } from '../CollectionSearchspringMenuSidebar';
import { Select } from '../../..';

export function CollectionSearchspringSort({
  isSearchResults,
  selectedSort, 
  selectSort,
  sortOptions,
  isOpen,
  setIsOpen,
 }) {
  const defaultLabel = isSearchResults ? 'Best Match' : 'Featured';
  const [options, setOptions] = useState(sortOptions);

  
  const findMatchingOption = (selectedSort, options, defaultLabel) => {
    if (selectedSort !== null) {
      const matchingOption = options?.find(
        option => option.field === selectedSort.field && option.direction === selectedSort.direction
      );
      if (matchingOption) {
        return { label: matchingOption.label, value: selectedSort.field, direction: selectedSort.direction };
      }
    }
    return { label: defaultLabel, value: 'relevance', direction: 'desc' };
  };
  
  const [selectedOption, setSelectedOption] = useState(() => findMatchingOption(selectedSort, options, defaultLabel));
  
  useEffect(() => {
    setSelectedOption(findMatchingOption(selectedSort, options, defaultLabel));
  }, [selectedSort, options, defaultLabel]);
  
  useEffect(() => {
    if (sortOptions) {
      if (!isSearchResults) {
        setOptions([{field: 'relevance', direction: 'desc', label: 'Featured'}, ...sortOptions]);
      } else {
        setOptions(sortOptions);
      }
    }
  }, [isSearchResults, sortOptions]);

  const handleSelect = (selectedOption) => {
    const option = options.find(option => 
      option.field === selectedOption.value && option.direction === selectedOption.direction
    );
    selectSort(option.field, option.direction);
  }

  return (
    <>
      {/* desktop */}
      <div className="ml-auto hidden max-w-[11rem] lg:block">
        <Select
          onSelect={handleSelect}
          options={options?.map(option => ({ label: option.label, value: option.field, direction: option.direction }))}
          placeholder="Sort"
          selectedOption={selectedOption}
          placeholderClass="text-text"
        />
      </div>

      {/* mobile */}
      <button
        aria-label="Open sort sidebar"
        className="flex w-auto items-center justify-between bg-offWhite  shadow-hairline p-3 text-base ml-auto lg:hidden"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <div className="flex flex-1 items-center gap-2 overflow-hidden xs:gap-3">
          <h3 className="text-base">Sort</h3>

          {selectedOption?.label && (
            <p className="truncate text-xs text-mediumGray xs:text-sm">
              {selectedOption?.label || placeholder}
            </p>
          )}
        </div>
      </button>

      <CollectionSearchspringMenuSidebar title="Sort" isOpen={isOpen} setIsOpen={setIsOpen}>
        <ul className="overflow-y-auto shadow-hairline-b bg-white gap-2">
          {options?.map((option, index) => {
            const isActive = option.label === selectedOption?.label && option.direction === selectedOption?.direction;
            
            return (
              <li key={index}>
                <button
                  aria-label={option.label}
                  className={`h-14 flex w-full items-center gap-4 shadow-hairline-b p-5 text-left text-text ${
                    isActive ? 'text-text' : 'text-mediumGray'
                  }`}
                  onClick={() => {
                    const newSelectedOption = {
                      label: option.label,
                      value: option.field,
                      direction: option.direction,
                    };
                    handleSelect(newSelectedOption);
                    setIsOpen(false);
                  }}
                  type="button"
                >
                  <div
                    className={`relative flex h-6 w-6 items-center justify-center overflow-hidden border border-border transition md:hover:border-text ${
                      isActive ? 'border-text' : ''
                    }`}
                  >
                    <div
                      className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 overflow-hidden transition-[height,width] ${
                        isActive
                          ? 'h-[calc(100%-5px)] w-[calc(100%-5px)] bg-primary'
                          : 'h-full w-full bg-offWhite'
                      }`}
                    />
                  </div>

                  <span className="flex-1">{option.label}</span>
                </button>
              </li>
            );
          })}
        </ul>
      </CollectionSearchspringMenuSidebar>
    </>
  );
}

CollectionSearchspringSort.displayName = 'CollectionSearchspringSort';
