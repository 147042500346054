import { useState } from 'react';
import { A11y, Navigation, Pagination, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Link, ProductItem, Spinner, Svg } from '../../snippets';
import { Schema } from './ProductsSlider.schema';
import { useColorSwatches } from '../../hooks';

export function ProductsSlider({ cms }) {
  const { button, heading, productItem, products, section, slider, textColor } =
    cms;

  const { swatchesMap } = useColorSwatches();

  const [swiper, setSwiper] = useState(null);

  const { sliderStyle } = { ...slider };
  const slidesPerViewDesktop = slider?.slidesPerViewDesktop || 4;
  const slidesPerViewTablet = slider?.slidesPerViewTablet || 3.4;
  const slidesPerViewMobile = slider?.slidesPerViewMobile || 1.4;
  const isFullBleedAndCentered =
    sliderStyle === 'fullBleed' || sliderStyle === 'fullBleedWithGradient';
  const isLoop = isFullBleedAndCentered || sliderStyle === 'containedWithLoop';
  const maxWidthClass =
    section?.fullWidth || isFullBleedAndCentered
      ? 'max-w-none'
      : 'max-w-[var(--content-max-lg-width)]';
    const sliderArrowsDesktop = slider?.sliderNavigationDesktop === 'arrows' || slider?.sliderNavigationDesktop === 'both' ? true : false;
    const sliderPaginationDesktop = slider?.sliderNavigationDesktop === 'dots' || slider?.sliderNavigationDesktop === 'both' ? true : false;
    const sliderArrowsMobile = slider?.sliderNavigationMobile === 'arrows' || slider?.sliderNavigationMobile === 'both' ? true : false;
    const sliderPaginationMobile = slider?.sliderNavigationMobile === 'dots' || slider?.sliderNavigationMobile === 'both' ? true : false;

  return (
    <div
      className={`${section?.spacing} ${
        !isFullBleedAndCentered ? 'px-contained' : ''
      }`}
    >
      <div className="m-auto flex flex-col max-md:items-center">
        <div className={`flex items-center gap-5 max-lg:px-5 ${slider?.align === 'justify-center' ? 'justify-center' : 'justify-between'}`}>
          <h2 className={`text-title-h2 ${slider?.align === 'justify-center' ? 'text-center' : 'text-left'}`} style={{ color: textColor }}>
            {heading}
          </h2>
          {button?.text && (
            <Link
              aria-label={
                button?.newTab
                  ? `Open in a new tab to view ${button?.text}`
                  : button?.text
              }
              className={`flex ${section?.buttonStyle || 'btn-primary'}`}
              href={button.url}
              newTab={button.newTab}
              type={button.type}
            >
              {button.text}
            </Link>
          )}
        </div>

        {products && products.length > 1 ? (
          <>
            <Swiper
              modules={[A11y, Navigation, Pagination, Keyboard]}
              a11y={{ enabled: true }}
              centeredSlides={
                isFullBleedAndCentered &&
                products.length >= slidesPerViewMobile * 2
              }
              grabCursor
              keyboard={{
                enabled: true,
                onlyInViewport: true,
              }}
              pagination={{
                el: '.swiper-pagination',
                clickable: true,
              }}
              loop={isLoop && products.length >= slidesPerViewMobile * 2}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                disabledClass: 'cursor-not-allowed opacity-0',
              }}
              onSwiper={setSwiper}
              slidesOffsetAfter={isFullBleedAndCentered ? 0 : 0}
              slidesOffsetBefore={isFullBleedAndCentered ? 0 : 0}
              slidesPerView={slidesPerViewMobile}
              spaceBetween={20}
              breakpoints={{
                768: {
                  centeredSlides:
                    isFullBleedAndCentered &&
                    products.length >= slidesPerViewTablet * 2,
                  loop: isLoop && products.length >= slidesPerViewTablet * 2,
                  slidesPerView: slidesPerViewTablet,
                  slidesOffsetBefore: isFullBleedAndCentered ? 0 : 0,
                  slidesOffsetAfter: isFullBleedAndCentered ? 0 : 0,
                },
                1024: {
                  centeredSlides:
                    isFullBleedAndCentered &&
                    products.length >= slidesPerViewDesktop * 2,
                  loop: isLoop && products.length >= slidesPerViewDesktop * 2,
                  slidesPerView: slidesPerViewDesktop,
                  slidesOffsetBefore: 0,
                  slidesOffsetAfter: 0,
                },
              }}
              className={`relative mt-5 w-full xl:mt-10 ${maxWidthClass} ${
                sliderStyle === 'fullBleedWithGradient'
                  ? 'before:swiper-offset-gradient-270-left after:swiper-offset-gradient-270-right'
                  : ''
              }`}
              wrapperClass={`${slider?.align || 'justify-start'} `}
              role="region"
              aria-label={`${heading} Slider`}
              tabIndex="0"
            >
              {swiper && products.map(({ product }, index) => {
                return (
                  <SwiperSlide key={index} className="pb-px md:h-auto">
                    <ProductItem
                      enabledColorNameOnHover={
                        productItem?.enabledColorNameOnHover
                      }
                      enabledColorSelector={productItem?.enabledColorSelector}
                      enabledQuickShop={productItem?.enabledQuickShop}
                      enabledQuickShopBIS={productItem?.enabledQuickShopBIS}
                      enabledStarRating={productItem?.enabledStarRating}
                      enabledProductType={productItem?.enabledProductType}
                      handle={product?.handle}
                      index={index}
                      swatchesMap={swatchesMap}
                    />
                  </SwiperSlide>
                );
              })}

              {products.length > slidesPerViewMobile && (
                <>
                  <div className="z-1 absolute left-0 right-0 top-[calc(50%-28px)] md:px-8 xl:px-14">
                    <div
                      className={`relative mx-auto ${maxWidthClass} ${
                        isFullBleedAndCentered ? 'min-[90rem]:max-w-full' : ''
                      }`}
                    >
                      <div
                        className={`swiper-button-prev shadow-hairline left-2.5 top-[calc(50%-1.6875rem)] bg-slate-50/60 h-7 w-7 md:!h-14 md:!w-14 after:hidden ${
                          sliderArrowsDesktop ? 'lg:flex' : 'lg:hidden'
                        } ${sliderArrowsMobile ? 'flex' : '!hidden'} ${
                          !isFullBleedAndCentered ? 'xl:-left-[1.6875rem]' : ''
                        }`}
                        aria-label="Previous Slide"
                        type="button"
                        role="button"
                      >
                        <Svg
                          className="w-2.5 md:w-5 text-black"
                          src="/svgs/arrow-left.svg#arrow-left"
                          alt="Previous Slide Icon"
                          title="Arrow Left"
                          viewBox="0 0 24 24"
                        />
                      </div>

                      <div
                        className={`swiper-button-next shadow-hairline right-2.5 top-[calc(50%-1.6875rem)] bg-slate-50/60 h-7 w-7 md:!h-14 md:!w-14 after:hidden ${
                          sliderArrowsDesktop ? 'lg:flex' : 'lg:hidden'
                        } ${sliderArrowsMobile ? 'flex' : '!hidden'} ${
                          !isFullBleedAndCentered ? 'xl:-right-[1.6875rem]' : ''
                        }`}
                        aria-label="Next Slide Icon"
                        type="button"
                        role="button"
                      >
                        <Svg
                          className="w-2.5 md:w-5 text-black"
                          src="/svgs/arrow-right.svg#arrow-right"
                          alt="Next Slide icon"
                          title="Arrow Right"
                          viewBox="0 0 24 24"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className={`active-bullet-black swiper-pagination relative !bottom-0 !top-0 mt-2.5 w-full justify-center gap-2.5 ${
                      sliderPaginationDesktop ? 'lg:flex' : 'lg:hidden'
                    } ${
                      sliderPaginationMobile ? 'flex' : '!hidden'}
                    `}
                  />
                </>
              )}
            </Swiper>

            {!swiper && (
              <div className="relative flex min-h-[20rem] items-center justify-center">
                <Spinner width="32" />
              </div>
            )}
          </>
        ) : (
          products && (
            <div className="w-full grid grid-cols-1 px-contained mt-5 md:grid-cols-3 xl:grid-cols-5">
              <div className="hidden md:block" />
              <div className="hidden xl:block" />
              {products.map(({ product }, index) => {
                return (
                  <ProductItem
                    key={index}
                    enabledColorNameOnHover={
                      productItem?.enabledColorNameOnHover
                    }
                    enabledColorSelector={productItem?.enabledColorSelector}
                    enabledQuickShop={productItem?.enabledQuickShop}
                    enabledQuickShopBIS={productItem?.enabledQuickShopBIS}
                    enabledStarRating={productItem?.enabledStarRating}
                    enabledProductType={productItem?.enabledProductType}
                    handle={product?.handle}
                    index={index}
                    swatchesMap={swatchesMap}
                  />
                );
              })}
              <div className="hidden xl:block" />
              <div className="hidden md:block" />
            </div>
          )
        )}
      </div>
    </div>
  );
}

ProductsSlider.displayName = 'ProductsSlider';
ProductsSlider.Schema = Schema;
