import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { A11y, Navigation, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Svg } from '../Svg';

export function ProductReviewsPagination({
  reviewsPerPage,
  totalPages,
  totalFilteredPages,
  filteredTotalPages,
  currentPage,
  setCurrentPage,
}) {
  const [useSwiper, setUseSwiper] = useState(false);

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    // Check if the number of elements is more than reviewsPerPage
    if (totalFilteredPages ? filteredTotalPages > reviewsPerPage : totalPages > reviewsPerPage) {
      setUseSwiper(true);
    } else {
      setUseSwiper(false);
    }
  }, [totalPages, totalFilteredPages]);

  // Helper function to render individual pagination buttons
  const renderPaginationButton = (index) => (
    <button
      key={index + 1}
      className={`text-body-sm text-primary ${
        index + 1 === currentPage ? 'font-medium underline' : ''
      }`}
      onClick={() => handlePagination(index + 1)}
    >
      {index + 1}
    </button>
  );

  if (useSwiper) {
    const customNavigation = {
      prevEl: '.swiper-button-prev-nav',
      nextEl: '.swiper-button-next-nav',
      disabledClass: 'cursor-not-allowed opacity-20',
    };
    return (
      <div className="w-full max-w-[305px] flex items-center">
        <div 
          className="swiper-button-prev-nav flex !h-3 !w-3 cursor-pointer after:hidden"
          aria-label="Previous Pages"
          type="button"
        >
          <Svg
            className="w-3 text-primary"
            src="/svgs/arrow-left.svg#arrow-left"
            alt="Previous Pages Icon"
            title="Arrow Left"
            viewBox="0 0 24 24"
          />
        </div>
        <Swiper
          modules={[A11y, Navigation, Keyboard]}
          a11y={{ enabled: true }}
          slidesPerView={reviewsPerPage}
          slidesPerGroup={reviewsPerPage}
          spaceBetween={0}
          navigation={customNavigation}
          role="region"
          aria-label="Reviews Pagination"
          tabIndex="0"
          keyboard={{
            enabled: true,
            onlyInViewport: true,
          }}
        >
          {Array.from({ length: totalFilteredPages ? filteredTotalPages : totalPages }, (_, index) => (
            <SwiperSlide className="flex justify-center" key={index}>{renderPaginationButton(index)}</SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-button-next-nav flex !h-3 !w-3 cursor-pointer after:hidden"
          aria-label="Next Pages"
          type="button"
        >
          <Svg
            className="w-3 text-primary"
            src="/svgs/arrow-right.svg#arrow-right"
            alt="Next Pages Icon"
            title="Arrow Right"
            viewBox="0 0 24 24"
          />
        </div>
      </div>
    );
  }

  // Regular pagination without Swiper
  return (
    <div className="flex flex-row flex-wrap gap-5">
      {Array.from({ length: totalFilteredPages ? filteredTotalPages : totalPages }, (_, index) =>
        renderPaginationButton(index)
      )}
    </div>
  );
}

ProductReviewsPagination.displayName = 'ProductReviewsPagination';
ProductReviewsPagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  totalFilteredPages: PropTypes.number,
  filteredTotalPages: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};
