import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ReviewStars } from '../ReviewStars';

export function ProductStars({ 
  legacyResourceId,
  isCompact = false,
  isButton = false,
  smoothScrollTo = () => {},
}) {
  const [reviewBottomline, setReviewBottomline] = useState(null);

  const fetchReviewBottomline = useCallback(async (productId) => {
    try {
      if (!productId) return;

      const endpoint = '/api/yotpo/getReviewsBottomline';
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'getReviewsBottomline',
          productId,
        }),
      };

      const response = await fetch(endpoint, options);
      const data = await response.json();

      if (!data || !data.response || !data.response.bottomline) {
        throw new Error('No data returned from fetchReviewBottomline');
      }

      // Set the review Bottomline data in the state
      setReviewBottomline({
        rating: data.response.bottomline.average_score,
        count: data.response.bottomline.total_reviews,
      });
    } catch (error) {
      console.error(`fetchReviewBottomline error: ${error.message}`);
      throw error;
    }
  }, []);

  useEffect(() => {
    // Call the fetchReviewBottomline function with the provided productId
    fetchReviewBottomline(legacyResourceId);
  }, [fetchReviewBottomline, legacyResourceId]);

  return reviewBottomline?.count > 0 && (
    isButton ? (
      <button
        aria-label="Scroll to product reviews"
        onClick={() => {
          smoothScrollTo('product-reviews')
        }}
        type="button"
      >
        <div className="flex flex-wrap items-center gap-1"> 
          <ReviewStars rating={reviewBottomline.rating} size="small" />
          <p className="text-2xs text-mediumDarkGray underline underline-offset-[3px]">
            ({reviewBottomline.count} Reviews)
          </p>
        </div>
      </button>
    )
    : (
      <div className="flex flex-wrap items-center gap-1"> 
        <ReviewStars rating={reviewBottomline.rating} size="small" />
        {!isCompact && (
          <p className="text-2xs text-mediumDarkGray underline underline-offset-[3px]">
            ({reviewBottomline.count})
          </p>
        )}
      </div>
    )
  );
}

ProductStars.displayName = 'ProductStars';
ProductStars.propTypes = {
  legacyResourceId: PropTypes.string,
  isCompact: PropTypes.bool,
  isButton: PropTypes.bool,
  smoothScrollTo: PropTypes.func,
};