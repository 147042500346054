import { useSettings } from '@backpackjs/storefront';

import { useGlobalContext } from '../../contexts';

import { FuseSearch } from './FuseSearch';
import { SearchspringSearch } from './SearchspringSearch';

export function Search() {
  const settings = useSettings();
  const appEnabled = settings?.search?.appEnabled;

  const {
    state: { searchOpen },
    actions: { closeSearch },
  } = useGlobalContext();

  return (
    <>
      {appEnabled && searchOpen ? (
        <SearchspringSearch
          searchOpen={searchOpen}
          closeSearch={closeSearch}
        />
      ) : (
        <FuseSearch />
      )}
    </>
  );
}

Search.displayName = 'Search';
