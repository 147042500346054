import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { Collection, Spinner } from '../../snippets';
import { useDataLayerActions, useSearchProductResults } from '../../hooks';

export function SearchResultsFuse() {
  const { query } = useRouter();
  const { productResults } = useSearchProductResults({
    term: query.term,
  });
  const { sendViewSearchResultsEvent } = useDataLayerActions();

  useEffect(() => {
    sendViewSearchResultsEvent({
      products: productResults,
      isSearchPage: true,
    });
  }, [productResults, sendViewSearchResultsEvent]);

  return (
    <div className="px-contained py-contained">
      {productResults?.length > 0 && (
        <>
          <h1 className="text-title-h3 mx-auto mb-6 max-w-[50rem] text-center max-md:px-4 lg:mb-8">
            {query.term
              ? `Found ${productResults.length} ${
                  productResults.length === 1 ? 'result' : 'results'
                } for "${query.term}"`
              : null}
          </h1>

          <Collection
            handle="search"
            products={productResults}
            showHeading={false}
          />
        </>
      )}

      {productResults?.length === 0 && (
        <h1 className="text-title-h3 mx-auto mb-6 max-w-[50rem] text-center max-md:px-4 lg:mb-8">
          {`Found 0 results for "${query.term || ''}"`}
        </h1>
      )}

      {!Array.isArray(productResults) && (
        <div className="flex min-h-[20rem] items-center justify-center">
          <Spinner width="32" />
        </div>
      )}
    </div>
  );
}

SearchResultsFuse.displayName = 'SearchResultsFuse';
