import { useMemo } from 'react';

import { isLightHexColor } from '../../../../utilities';
import { Svg, Image } from '../../..';

export function CollectionSearchspringFilterOption({
  addToSelectedFilters,
  isColor,
  field,
  removeFromSelectedFilters,
  showCount,
  swatchesMap,
  element,
}) {
  const { count, value, label } = element;

  const isActive = useMemo(() => {
    return element.active;
  }, [element]);

  let swatch = null;
  let hasImage = false;

  if (isColor) {
    swatch = swatchesMap[value.toLowerCase().trim()];
    hasImage = swatch?.startsWith('http');
  }

  const checkmarkColor = useMemo(() => {
    if (!isColor) return 'text-white';
    if (!swatch) return 'text-primary';
    return isLightHexColor(swatch) ? 'text-primary' : 'text-white';
  }, [isColor, swatch]);

  const colorBackground = swatch || 'var(--off-white)';
  const nonColorBackground = isActive ? 'var(--primary)' : 'var(--off-white)';

  return (
    <button
      aria-label={`${ isActive ? 'Remove' : 'Add' } ${label} to  filters`}
      className={`group flex gap-3 text-left text-base transition max-md:w-full max-md:items-center md:gap-2 hover:md:text-text ${
        isActive ? 'text-text' : 'text-mediumGray'
      }`}
      onClick={() => {
        const filterOption = element;
        const clearFilterType = document.querySelector(`[clear-filter-type="${field}-${filterOption.value}"]`);
        if (isActive) {
          removeFromSelectedFilters({ field, option: filterOption });
        } else {
          addToSelectedFilters({ field, option: filterOption });
        }
        if (clearFilterType) clearFilterType.click();
      }}
      type="button"
      filter-value={`${field}-${value}`}
    >
      <div
        className={`relative flex h-5 w-5 items-center justify-center overflow-hidden shadow-hairline transition md:mt-[3px] md:h-[18px] md:w-[18px] group-hover:md:border-text ${
          isActive ? 'border border-text' : ''
        }`}
        style={{
          backgroundColor: isColor ? colorBackground : nonColorBackground,
        }}
      >
        {hasImage && (
          <Image
            alt={value}
            className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
            height="24"
            width="24"
            src={swatch}
          />
        )}

        <div
          className={`absolute top-1/2 left-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 border-white transition-[border-width] duration-100 ${
            isActive ? 'border-[0px] md:border-[0px]' : 'border-[0px]'
          }`}
        />

        <Svg
          src="/svgs/checkmark.svg#checkmark"
          viewBox="0 0 24 24"
          className={`pointer-events-none w-3 transition md:w-2.5 ${checkmarkColor} ${
            isActive ? 'opacity-100' : 'opacity-0'
          }`}
          alt="Select Filter Icon"
          title="Filter Checkmark"
        />
      </div>

      <p className="flex-1">
        {label}{' '}
        <span className={`text-xs ${showCount ? 'inline' : 'hidden'}`}>
          ({count})
        </span>
      </p>
    </button>
  );
}

CollectionSearchspringFilterOption.displayName = 'CollectionSearchspringFilterOption';
