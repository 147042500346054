import { useState } from 'react';
import { A11y, Navigation, Pagination, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useMatchMedia } from '../../hooks';

import { Spinner, Svg } from '../../snippets';
import { ImageTile } from './ImageTile';
import { Schema } from './ImageTiles.schema';

export function ImageTiles({ cms }) {
  const { content, heading, hideHeading, slider, section, tiles, itemsPerRow } = cms;

  const [swiper, setSwiper] = useState(null);

  const isMobileViewport = useMatchMedia('(max-width: 1023px)');

  const { sliderStyle } = { ...slider };
  const slidesPerViewDesktop = slider?.slidesPerViewDesktop || 6;
  const slidesPerViewTablet = slider?.slidesPerViewTablet || 3.4;
  const slidesPerViewMobile = slider?.slidesPerViewMobile || 1.4;
  const isFullBleedAndCentered =
    sliderStyle === 'fullBleed' || sliderStyle === 'fullBleedWithGradient';
  const isLoop = isFullBleedAndCentered || sliderStyle === 'containedWithLoop';
  const maxWidthClass =
    section?.fullWidth || isFullBleedAndCentered
      ? 'max-w-[var(--content-max-xl-width)] mx-auto'
      : 'max-w-[var(--content-max-lg-width)] mx-auto';
  const sliderArrowsMobile = slider?.sliderNavigationMobile === 'arrows' || slider?.sliderNavigationMobile === 'both' ? true : false;
  const sliderPaginationMobile = slider?.sliderNavigationMobile === 'dots' || slider?.sliderNavigationMobile === 'both' ? true : false;
  const sliderArrowsDesktop = slider?.sliderNavigationDesktop === 'arrows' || slider?.sliderNavigationDesktop === 'both' ? true : false;
  const sliderPaginationDesktop = slider?.sliderNavigationDesktop === 'dots' || slider?.sliderNavigationDesktop === 'both' ? true : false;

  const showPaginationAndNavigation = tiles?.length > slidesPerViewMobile || tiles?.length > slidesPerViewTablet || tiles?.length > slidesPerViewDesktop ? true : false;

  return (
    <div
      className={`${section?.spacing} ${
        !isFullBleedAndCentered ? 'lg:px-contained' : ''
      }`}
      style={{ backgroundColor: section?.bgColor }}
    >
      <div className="m-auto">
        {heading && !hideHeading && (
          <div className={`${maxWidthClass}`}>
            <h2 className="text-title-h2 mb-6 md:mb-10">
              {heading}
            </h2>
          </div>
        )}

        {(tiles?.length > 0 && (isMobileViewport) || tiles?.length > 6) && !slider.disableSlider && (
          <Swiper
            modules={[A11y, Navigation, Pagination, Keyboard]}
            a11y={{ enabled: true }}
            centeredSlides={
              isFullBleedAndCentered && tiles?.length >= slidesPerViewMobile * 2
            }
            grabCursor
            keyboard={{
              enabled: true,
              onlyInViewport: true,
            }}
            pagination={{
              el: '.swiper-pagination',
              clickable: true,
            }}
            loop={isLoop && tiles?.length >= slidesPerViewMobile * 2}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              disabledClass: 'cursor-not-allowed opacity-0',
            }}
            onSwiper={setSwiper}
            slidesOffsetAfter={isFullBleedAndCentered ? 0 : 0}
            slidesOffsetBefore={isFullBleedAndCentered ? 0 : 0}
            slidesPerView={slidesPerViewMobile}
            spaceBetween={20}
            breakpoints={{
              768: {
                centeredSlides:
                  isFullBleedAndCentered &&
                  tiles?.length >= slidesPerViewTablet * 2,
                loop: isLoop && tiles?.length >= slidesPerViewTablet * 2,
                slidesPerView: slidesPerViewTablet,
                slidesOffsetBefore: isFullBleedAndCentered ? 0 : 0,
                slidesOffsetAfter: isFullBleedAndCentered ? 0 : 0,
              },
              1024: {
                centeredSlides:
                  isFullBleedAndCentered &&
                  tiles?.length >=
                    slidesPerViewDesktop * 2,
                loop:
                  isLoop &&
                  tiles?.length >=
                    slidesPerViewDesktop * 2,
                slidesPerView: slidesPerViewDesktop,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
              },
            }}
            className={`relative w-full ${maxWidthClass} ${
              sliderStyle === 'fullBleedWithGradient'
                ? 'before:swiper-offset-gradient-270-left after:swiper-offset-gradient-270-right'
                : ''
            }`}
            role="region"
            aria-label={`${heading} Slider`}
            tabIndex="0"
          >
            {swiper &&
              tiles.slice(0, 12).map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <ImageTile
                      aspectRatio={section?.aspectRatio}
                      content={content}
                      item={item}
                      isMobileViewport={isMobileViewport}
                    />
                  </SwiperSlide>
                );
              })}

            {showPaginationAndNavigation && (
              <>
                <div className="z-1 absolute left-0 right-0 top-1/2 md:px-8 xl:px-14">
                  <div
                    className={`relative mx-auto ${maxWidthClass} ${
                      isFullBleedAndCentered ? 'min-[90rem]:max-w-full' : ''
                    }`}
                  >
                    <div
                      className={`swiper-button-prev shadow-hairline inset-y-auto left-0 bg-slate-50/60 h-7 w-7 ml-5 md:!h-14 md:!w-14 after:hidden ${
                        sliderArrowsDesktop ? 'lg:flex' : 'lg:hidden'
                      } ${sliderArrowsMobile ? 'flex' : 'hidden'} ${
                        !isFullBleedAndCentered ? 'xl:-left-[1.6875rem]' : ''
                      }`}
                      aria-label="Previous Slide"
                      type="button"
                      role="button"
                    >
                      <Svg
                        className="w-2.5 md:w-5 text-black"
                        src="/svgs/arrow-left.svg#arrow-left"
                        alt="Previous Slide Icon"
                        title="Arrow Left"
                        viewBox="0 0 24 24"
                      />
                    </div>

                    <div
                      className={`swiper-button-next shadow-hairline inset-y-auto right-0 bg-slate-50/60 h-7 w-7 mr-5 md:!h-14 md:!w-14 after:hidden ${
                        sliderArrowsDesktop ? 'lg:flex' : 'lg:hidden'
                      } ${sliderArrowsMobile ? 'flex' : 'hidden'} ${
                        !isFullBleedAndCentered ? 'xl:-right-[1.6875rem]' : ''
                      }`}
                      aria-label="Next Slide Icon"
                      type="button"
                      role="button"
                    >
                      <Svg
                        className="w-2.5 md:w-5 text-black"
                        src="/svgs/arrow-right.svg#arrow-right"
                        alt="Next Slide icon"
                        title="Arrow Right"
                        viewBox="0 0 24 24"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`active-bullet-black swiper-pagination relative !bottom-0 !top-0 mt-2.5 w-full justify-center gap-2.5 ${
                    sliderPaginationDesktop ? 'lg:flex' : 'lg:hidden'
                  } ${
                    sliderPaginationMobile ? 'flex' : '!hidden'}
                  `}
                />
              </>
            )}
          </Swiper>
        )}

        {tiles?.length > 0 && isMobileViewport && !swiper && !slider?.disableSlider && (
          <Spinner
            width="32"
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        )}

        {/* desktop */}
        {/* {tiles?.length > 0 && !isMobileViewport && ( */}
        {tiles?.length > 0 && (!isMobileViewport && tiles?.length <= 6 || slider?.disableSlider ) && (
          <div className={`${maxWidthClass} gap-x-5 grid grid-cols-${slidesPerViewMobile} md:grid-cols-${slidesPerViewTablet} ${itemsPerRow} gap-5 2xl:gap-10`}>
            {/* hidden div: to add tailwind classes for grid */}
            <div className="hidden grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5 grid-cols-6"></div>
            {tiles.slice(0, 6).map((item, index) => {
              return (
                <div className="relative" key={index}>
                  <ImageTile
                    aspectRatio={section?.aspectRatio}
                    content={content}
                    item={item}
                  />
                </div>
              );
            })}
          </div>          
        )}
      </div>
    </div>
  );
}

ImageTiles.displayName = 'ImageTiles';
ImageTiles.Schema = Schema;
