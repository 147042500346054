import { useCallback, useState, useEffect } from 'react';
import { useCartItems, useCustomer, useRouter } from '@backpackjs/storefront';

import { useSearchspring, useSearchspringSearchSort, useSearchspringSearchFilters } from './';
import { useSearchspringContext } from '../../contexts';
import { decodedId } from '../../utilities';

const LAST_VIEWED_KEY = `${
  process.env.SHOPIFY_DOMAIN?.split('.')[0]
}_last_viewed_product_ids`;

export const useSearchspringSearchPageResults = () => {
  const router = useRouter();
  const { requestSearchspring } = useSearchspring();
  const [{ selectedSort }, { sortCollection }] = useSearchspringSearchSort();
  const [{ selectedFilters }] = useSearchspringSearchFilters();
  
  const customer = useCustomer();
  const cartItems = useCartItems();
  const lastViewed =
    (typeof window !== 'undefined' &&
      window.localStorage.getItem(LAST_VIEWED_KEY)) ||
    '';

  const {
    state: {
      searchSelectedResultsPerPage: resultsPerPage,
      searchCurrentResultsPage: currentPage,
      searchPageData,
      searchPageQuery,
      searchTags,
    },
    actions: {
      setSearchPageData,
    },
  } = useSearchspringContext();

  const [allPageResults, setAllPageResults] = useState([]);
  const [previousFilters, setPreviousFilters] = useState([]);
  const [previousSort, setPreviousSort] = useState(null);
  const [previousPage, setPreviousPage] = useState(1);

  const customerId = customer?.id || '';
  const cart = cartItems?.map((item) => item.variant.sku).join(',') || '';
  const searchIsAutocomplete = false;

  const { asPath } = router;
  const path = asPath?.split('?')[0];

  const pageDeps = [
    customerId, 
    cart, 
    lastViewed,
    searchPageQuery,
    selectedSort?.field,
    selectedSort?.direction,
    JSON.stringify(selectedFilters),
    resultsPerPage,
    currentPage,
    path,
  ];

  const getSearchPageResults = useCallback(
    async () => {
      try {
        if (!searchPageQuery && (!searchTags || searchTags.length === 0)) {
          setSearchPageData(null);
          return null;
        }
        
        const shopper = decodedId(customerId)?.split('/').pop();

        const data = await requestSearchspring({
          action: 'getSearchPageResults',
          params: {
            query: searchPageQuery || '',
            resultsPerPage: resultsPerPage, // limit # of results shown; integer from 1-100
            page: currentPage,
            searchIsAutocomplete,
            domain: `${process.env.SITE_URL}/${path}`, // full url of current page
            cart, // comma-separated list of product skus in the user's cart
            lastViewed, // comma-separated list of 5 most recent product ids or skus
            ...(!searchPageQuery && searchTags ? { searchTags } : null), // tag in url for landing pages
            ...(shopper ? { shopper } : null), // customer id (if logged in)
            ...(selectedSort ? { sort: selectedSort } : null), // sort order
            ...(selectedFilters
              ? { filters: selectedFilters }
              : null), // filters
          },
        });

        let _data = data;
        const banners = data?.merchandising?.content?.inline;

        if (banners?.length) {
          const resultsLength = _data.results?.length;
          const results = banners.reduce(
            (arr, item, index) => {
              const pos = item.config.position.index;
              if (resultsLength + index <= pos) return arr;
              return [
                ...arr.slice(0, pos),
                {
                  isBanner: true,
                  value: item.value,
                  id: `banner-index-${index}`,
                },
                ...arr.slice(pos),
              ];
            },
            [..._data.results]
          );
          _data = { ..._data, results };
        }

        
          let newResults;
          if (JSON.stringify(selectedFilters) !== JSON.stringify(previousFilters) || JSON.stringify(selectedSort) !== JSON.stringify(previousSort)) {
            newResults = [...(_data?.results || [])];
          } else {
            if (currentPage < previousPage) {
              newResults = [...(_data?.results || []), ...allPageResults];
            } else {
              newResults = [...allPageResults, ...(_data?.results || [])];
            }
          }
          setAllPageResults(newResults);
          setPreviousFilters(selectedFilters);
          setPreviousSort(selectedSort);
          setPreviousPage(currentPage);
          setSearchPageData(_data || null);
      } catch (error) {
        console.error(`Error with getSearchPageResults: ${error.message}`);
        throw error;
      }
    },
    [...pageDeps]
  );

  useEffect(() => {
      getSearchPageResults();
  }, [...pageDeps]);

  useEffect(() => {
    return () => {
      setSearchPageData(null);
    };
  }, []);

  useEffect(() => {
    sortCollection({ field: null, direction: null });
    return () => sortCollection({ field: null, direction: null });
  }, [searchPageQuery]);

  return {
    pageResults: allPageResults,
    totalPageResults: searchPageData?.pagination?.totalResults || 0,
    merchandising: searchPageData?.merchandising || null,
    // id: searchData?.id || null,
  };
};