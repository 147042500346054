import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer'; // Import the hook
import { Schema } from './AnimatedText.schema';

import{ Link } from '../../snippets';

export function AnimatedText({ cms }) {
  const { superheading, heading, text, textSize, readMore, section } = cms;

  const maxWidthContainerClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-lg-width)]';

  const sectionClasses = [
    section?.fullBleed ? '' : 'px-contained',
    section?.verticalPadding ? 'py-contained' : '',
    section?.fullHeight ? 'min-h-screen flex justify-center items-center' : '',
    'bg-gradient-to-b from-white via-offWhite to-gray',
  ].join(' ');

  const animation = section?.animation
    ? 'swift-up-text' : '';

  // Use the useInView hook to get the ref and inView properties
  const { ref, inView } = useInView({
    threshold: 0, // Adjust the threshold if needed
    triggerOnce: true,
  });

  useEffect(() => {
    // Perform your animations here using inView
    if (inView) {
      const swiftUpElements = document.querySelectorAll('.swift-up-text');

      swiftUpElements.forEach(elem => {
        const words = elem.textContent.split(' ');
        elem.innerHTML = '';

        words.forEach((el, index) => {
          words[index] = `
            <span class="inline-block overflow-hidden motion-safe:animate-swift-up-span leading-tight">
              <i class="relative top-[3.2rem] not-italic opacity-0 motion-safe:animate-swift-up-i">${words[index]}</i>
            </span>
          `;
        });

        elem.innerHTML = words.join(' ');

        const children = document.querySelectorAll('span > i');
        children.forEach((node, index) => {
          node.style.animationDelay = `${index * 0.2}s`;
        });
      });
    }
  }, [inView]);

  return (
    <div
      className={sectionClasses}
      style={{ backgroundColor: section?.bgColor }}
      ref={ref}
    >
      <div
        className={`relative mx-auto flex ${maxWidthContainerClass}`}
      >
        <div className="w-full py-contained">
          <div className={`container mx-auto ${section?.textAlign}`}>
            {superheading && (
              <h3 className="text-title-h6 uppercase">{superheading}</h3>
            )}
            <h2 className="text-title-h1 mb-2.5">
              {heading}
            </h2>
            <div className="flex flex-column flex-wrap">
              {text?.map(({text, spaceBellow}, index) => {
                return (
                  <h4
                    key={index}
                    className={`w-full ${textSize} ${animation} ${spaceBellow ? 'mb-5' : ''}`}
                  >
                    {text}
                  </h4>
                );
              })}
            </div>
            <Link
              aria-label={
                readMore?.newTab
                  ? `Open in a new tab to view ${readMore?.text}`
                  : readMore?.text
              }
              className={`swift-up-text swift-up-text-link uppercase ${animation}`}
              href={readMore?.url}
              newTab={readMore?.newTab}
              type={readMore?.type}
            >
              {readMore?.text}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

AnimatedText.displayName = 'AnimatedText';
AnimatedText.Schema = Schema;
