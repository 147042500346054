import { useEffect, useRef } from 'react';
import { Svg } from '../Svg';
import { useGlobalContext } from '../../contexts';

export function Modal() {
  const {
    state: { modal },
    actions: { closeModal },
  } = useGlobalContext();

  const modalRef = useRef(null);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus();
    }

    // Event listener for "Escape" key press
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [modal]);

  const { extraClassName, className, ...props } = { ...modal.props };

  const childrenClassName = modal?.children?.props?.className;
  const childrenExtraClassName = modal?.children?.props?.extraClassName;

  return modal.children ? (
    <div
      ref={modalRef}
      className={`fixed top-1/2 left-1/2 z-50 max-h-[calc(var(--viewport-height)-2rem)] w-[calc(100%-2rem)] max-w-[35rem] -translate-x-1/2 -translate-y-1/2 overflow-hidden bg-background ${className} ${childrenClassName}`}
      {...props}
      role="dialog"
      aria-modal="true"
      aria-labelledby="Modal"
      tabIndex="-1"
    >
      <button
        aria-label="Close modal"
        className="absolute right-5 top-5 z-10 bg-white flex h-6 w-6 items-center justify-center"
        onClick={closeModal}
        type="button"
      >
        <Svg
          className="w-full text-text"
          src="/svgs/close.svg#close"
          alt="Close Icon"
          title="Close"
          viewBox="0 0 32 32"
        />
      </button>

      <div className={`scrollbar-hide px-contained py-contained max-h-[calc(var(--viewport-height)-2rem)] overflow-y-auto ${extraClassName} ${childrenExtraClassName}`}>
        {modal.children}
      </div>
    </div>
  ) : null;
}

Modal.displayName = 'Modal';
