import { useSettings } from '@backpackjs/storefront';
import { Link } from '../Link';

export function TrustBuilders() {
  const settings = useSettings();
  const { trustBuilders } = { ...settings?.footer };
  const { enabled, trustBuilderLeft, trustBuilderRight } =
    { 
      ...trustBuilders, 
    };
  return enabled ? (
    <div className="relative w-full flex flex-col shadow-hairline">
      <div className="absolute hidden w-full h-full md:flex">
        <div className="md:w-1/2 h-full bg-offWhite"></div>
        <div className="md:w-1/2 h-full bg-offWhite"></div>
        <div className="w-10 h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-row">
          <div className="w-1/2 bg-secondary"></div>
          <div className="w-1/2 bg-primary"></div>
        </div>
      </div>
      <div className="relative w-full flex flex-col md:flex-row md:p-2.5 md:gap-12 md:py-5 md:px-6 lg:gap-20 lg:py-5 lg:px-10">
        <div className="w-full bg-offWhite flex flex-row gap-5 p-5 md:w-1/2 md:gap-5 md:p-0 lg:gap-10">
          {trustBuilderLeft?.map(({ title, subtitle, icon, link }, index) => {
            const content = (
              <>
                {icon && <div className="w-auto" dangerouslySetInnerHTML={{ __html: icon }}></div>}
                <div className="text-primary">
                  {title && <p className="uppercase mb-0">{title}</p>}
                  {subtitle && <p className="mb-0">{subtitle}</p>}
                </div>
              </>
            );
            return link ? (
              <Link
                key={index}
                aria-label={
                  link?.newTab 
                    ? `Open in a new tab to view ${link?.text}` 
                    : link?.text
                }
                href={link?.url}
                newTab={link?.newTab}
                type={link?.type}
                className="w-1/2 flex flex-row items-center justify-center gap-2.5"
              >
                {content}
              </Link>
            ) : (
              <div key={index} className="w-1/2 flex flex-row items-center justify-center gap-2.5">
                {content}
              </div>
            );
          })}
        </div>
        <div className="w-full h-10 flex flex-row md:hidden">
          <div className="h-full w-1/2 bg-secondary"></div>
          <div className="h-full w-1/2 bg-primary"></div>
        </div>
        <div className="w-full bg-offWhite flex flex-row gap-5 p-5 md:w-1/2 md:gap-5 md:p-0 lg:gap-10">
          {trustBuilderRight?.map(({ title, subtitle, icon, link }, index) => {
            const content = (
              <>
                {icon && <div className="w-auto" dangerouslySetInnerHTML={{ __html: icon }}></div>}
                <div className="text-primary">
                  {title && <p className="uppercase mb-0">{title}</p>}
                  {subtitle && <p className="mb-0">{subtitle}</p>}
                </div>
              </>
            );
            return link ? (
              <Link
                key={index}
                aria-label={
                  link?.newTab 
                    ? `Open in a new tab to view ${link?.text}` 
                    : link?.text
                }
                href={link?.url}
                newTab={link?.newTab}
                type={link?.type}
                className="w-1/2 flex flex-row items-center justify-center gap-2.5"
              >
                {content}
              </Link>
            ) : (
              <div key={index} className="w-1/2 flex flex-row items-center justify-center gap-2.5">
                {content}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  ) : null;
}

TrustBuilders.displayName = 'TrustBuilders';