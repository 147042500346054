import { useCallback } from 'react';
import { useSearchspringCollection, useSearchspringCollectionSort, useSearchspringCollectionFilters, useSearchspringCollectionPagination } from '../../../hooks';

export default function useCollection() {
  // Searchspring collection filters
  const [
    { selectedFilters, filters, filterSummary },
    { addToSelectedFilters, removeFromSelectedFilters, clearSelectedFilters, loadSelectedFilters}
  ] = useSearchspringCollectionFilters();

  // Searchspring collection sort
  const [{ selectedSort, sortOptions }, { loadSelectedSort, sortCollection }] = useSearchspringCollectionSort();

  // Searchspring collection pagination
  const [
    { selectedResultsPerPage, currentResultsPage, totalPages, loadedPages },
    { goToPrevPage, goToNextPage, goToPage, updatePageURLInView }
  ] = useSearchspringCollectionPagination();

  // Error handling for async functions
  function withErrorHandling(callback) {
    return async (...args) => {
      try {
        await callback(...args);
      } catch (error) {
        console.error(`Error in ${callback.name} function`, error);
        // Handle the error (e.g., set an error state, retry the operation, etc.)
      }
    };
  }

  const loadSort = useCallback(withErrorHandling(async (sortFromParams) => {
    loadSelectedSort(sortFromParams);
  }), [loadSelectedSort]);

  const selectSort = useCallback(withErrorHandling(async (field, direction) => {
    await sortCollection({ field, direction });
  }), [sortCollection]);

  const loadFilters = useCallback(withErrorHandling(async (filtersFromParams) => {
    loadSelectedFilters(filtersFromParams);
  }), [loadSelectedFilters]);
  
  const addToFilters = useCallback(withErrorHandling(async (field, option) => {
    addToSelectedFilters(field, option);
  }), [addToSelectedFilters]);
  
  const removeFromFilters = useCallback(withErrorHandling(async (field, option) => {
    removeFromSelectedFilters(field, option);
  }), [removeFromSelectedFilters]);
  
  const clearFilters = useCallback(withErrorHandling(async () => {
    clearSelectedFilters();
  }), [clearSelectedFilters]);
  
  const goToPreviousPage = useCallback(withErrorHandling(async () => {
    goToPrevPage();
  }), [goToPrevPage]);
  
  const goToFollowingPage = useCallback(withErrorHandling(async () => {
    goToNextPage();
  }), [goToNextPage]);
  
  const goToSpecificPage = useCallback(withErrorHandling(async (num) => {
    goToPage(num);
  }), [goToPage]);

  const updatePageInView = useCallback(withErrorHandling(async (num) => {
    updatePageURLInView(num);
  }), [updatePageURLInView]);

  // Searchspring collection
  const { products: searchspringProducts, totalProducts } = useSearchspringCollection();

  return {
    searchspringProducts,
    totalProducts,
    selectedSort,
    sortOptions,
    loadSort,
    selectSort,
    selectedFilters,
    filters,
    filterSummary,
    loadFilters,
    addToFilters,
    removeFromFilters,
    clearFilters,
    selectedResultsPerPage,
    currentResultsPage,
    totalPages,
    loadedPages,
    goToPreviousPage,
    goToFollowingPage,
    goToSpecificPage,
    updatePageInView,
  };
}