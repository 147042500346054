import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ReviewStars } from '../ReviewStars';
import { ProductReviewsFilter } from './ProductReviewsFilter';
import { ProductReviewsPagination } from './ProductReviewsPagination';
import { ProductReviewsReviews } from './ProductReviewsReviews';

// Utility functions
const mapAverageScoreToSize = (averageScore) => {
  switch (averageScore) {
    case 1:
      return 'Small';
    case 2:
      return 'True to size';
    case 3:
      return 'Big';
    default:
      return '';
  }
};

export function ProductReviews({ legacyResourceId }) {
  // State variables
  const [reviewAggregate, setReviewAggregate] = useState(null);
  const [topMentionedTopics, setTopMentionedTopics] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [allReviewsData, setAllReviewsData] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [filteredTotalPages, setFilteredTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRate, setSelectedRate] = useState(null);
  const [clearAllFilters, setClearAllFilters] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Constants
  const reviewsPerPage = 12;
  const totalPages = Math.ceil(allReviewsData.length / reviewsPerPage);
  const totalFilteredPages = Math.ceil(filteredReviews.length / reviewsPerPage);
  
  const fetchReviewAggregate = useCallback(async (productId, page = 1, perPage = 150, accumulatedReviews = []) => {
    try {
      if (!productId) return;
  
      const endpoint = '/api/yotpo/getProductReviews';
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'getProductReviews',
          productId,
          page, // Add the page parameter to the request body
          per_page: perPage, // Add the per_page parameter to the request body
        }),
      };
  
      const response = await fetch(endpoint, options);
      const data = await response.json();
  
      if (!data || !data.response || !data.response.bottomline) {
        throw new Error('No data returned from fetchReviewAggregate');
      }
  
      const customFields = data.response.bottomline.custom_fields_bottomline || {};
      const customFieldFitId = '--95085';
      let customFieldFitTitle = '';
      if (customFields && customFields[customFieldFitId] && customFields[customFieldFitId].title) {
        customFieldFitTitle = customFields[customFieldFitId].title;
      }
  
      // Get the average_score inside custom_fields_bottomline and transform it
      const averageScoreInsideCustomField = customFields[customFieldFitId]?.average_score ?? null;
      const size = mapAverageScoreToSize(averageScoreInsideCustomField);
  
      // Get the reviews data
      const reviews = data.response.reviews || [];
  
      const updatedReviews = [...accumulatedReviews, ...reviews];

      // Uncomment this code to add product names to the reviews
      // const groups = data.response.grouping_data || [];
      // const addProductNameToReviews = (updatedReviews, groups) => {
      //   updatedReviews.forEach(review => {
      //     const id = review.id.toString();
      //     if (groups[id]) {
      //       review.product_name = groups[id].product_name;
      //     }
      //   });
      // };
      // addProductNameToReviews(updatedReviews, groups);
      // console.log('updatedReviews after adding product names', updatedReviews);
  
      if (page < Math.ceil(data.response.bottomline.total_review / perPage)) {
        // Fetch the next page of reviews recursively
        return fetchReviewAggregate(productId, page + 1, perPage, updatedReviews);
      } else {
        // If all pages have been fetched, set the allReviewsData
        setAllReviewsData(updatedReviews);
      }
  
      // Set the review aggregate data in the state
      setReviewAggregate({
        rating: data.response.bottomline.average_score,
        count: data.response.bottomline.total_review,
        customFieldFitTitle,
        size,
      });
  
      // Set the top mentioned topics data in the state
      setTopMentionedTopics(data.topMentionedTopics.top_topics.top_mention_topics);
      return data; // Return the data object after setting the state
    } catch (error) {
      console.error(`fetchReviewAggregate error: ${error.message}`);
      throw error;
    }
  }, []);  

  // Fetch data on component mount
  useEffect(() => {
    // Function to handle loading and error states
    const fetchData = async () => {
      try {
        setIsLoading(true); 
        await fetchReviewAggregate(legacyResourceId);
        setIsLoading(false); // Hide loading state once data is fetched
      } catch (error) {
        setIsLoading(false); // Hide loading state in case of error
        setFetchError('An error occurred while fetching data.'); // Set the error message
      }
    };

    fetchData();
  }, [fetchReviewAggregate, legacyResourceId]);

  return reviewAggregate?.count > 0 && (
    <div id="product-reviews" className="flex min-h-[1rem] flex-wrap flex-col gap-5">
      {isLoading ? ( // Show loading state
        <p>Loading...</p>
      ) : (
        <>
          {reviewAggregate && (
            <>
              <h2 className="text-title-h2">Customer Reviews</h2>
              <div className="w-full flex flex-col gap-[5px]">
                <h3 className="text-title-h3">{reviewAggregate.rating.toFixed(1)}</h3>
                <ReviewStars rating={reviewAggregate.rating} size="large" />
                <p className="text-2xs text-mediumDarkGray">
                  {reviewAggregate.count} Reviews
                </p>
                {reviewAggregate.customFieldFitTitle && (
                  <p className="text-mediumDarkGray">
                    {reviewAggregate.customFieldFitTitle}:
                    {reviewAggregate.size && (
                      <span className="font-medium ml-1">{reviewAggregate.size}</span>
                    )}
                  </p>
                )}
              </div>
              {reviewAggregate?.count > 1 && (
                <ProductReviewsFilter
                  selectedRate={selectedRate}
                  searchQuery={searchQuery}
                  topMentionedTopics={topMentionedTopics}
                  selectedTopic={selectedTopic}
                  allReviewsData={allReviewsData}
                  setSearchQuery={setSearchQuery}
                  setSelectedTopic={setSelectedTopic}
                  setSelectedRate={setSelectedRate}
                  setClearAllFilters={setClearAllFilters}
                  setFilteredTotalPages={setFilteredTotalPages}
                  setCurrentPage={setCurrentPage}
                  setFilteredReviews={setFilteredReviews}
                  clearAllFilters={clearAllFilters}
                  reviewsPerPage={reviewsPerPage}
                  reviewCount={reviewAggregate.count}
                />
              )}
              {fetchError ? ( // Display error message if there is a fetch error
                <div>
                  <p>{fetchError}</p>
                </div>
              ) : (
                <ProductReviewsReviews
                  currentPage={currentPage}
                  reviewsPerPage={reviewsPerPage}
                  selectedRate={selectedRate}
                  selectedTopic={selectedTopic}
                  searchQuery={searchQuery}
                  allReviewsData={allReviewsData}
                  filteredReviews={filteredReviews}
                  reviewCount={reviewAggregate.count}
                />
              )}
              {totalPages > 1 && (
                <div className="w-full flex items-center">
                  <ProductReviewsPagination
                    reviewsPerPage={reviewsPerPage}
                    totalPages={totalPages}
                    totalFilteredPages={totalFilteredPages}
                    filteredTotalPages={filteredTotalPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

ProductReviews.displayName = 'ProductReviews';
ProductReviews.propTypes = {
  legacyResourceId: PropTypes.string,
};
