import { useVariantPrices } from '../../hooks';
import PropTypes from 'prop-types';

export function ProductItemPrice({ selectedVariant, isCompact = false }) {
  const { price, compareAtPrice } = useVariantPrices({
    variant: selectedVariant,
  });

  return (
    <div className="flex flex-wrap items-baseline gap-x-1">
      <p className={`${
        compareAtPrice ? 'text-secondary' : 'text-primary'
      } ${
        isCompact ? 'text-body' : 'text-body-lg min-h-[1.25rem]'
      }`}>{price}</p>
      {compareAtPrice && (
        <p className="text-sm text-mediumGray line-through">{compareAtPrice}</p>
      )}
    </div>
  );
}

ProductItemPrice.displayName = 'ProductItemPrice';
ProductItemPrice.propTypes = {
  selectedVariant: PropTypes.object,
  isCompact: PropTypes.bool,
};
