import { useSettings } from '@backpackjs/storefront';

import { CollectionSearchspringDropdownFilter } from './CollectionSearchspringDropdownFilter';

export function CollectionSearchspringFilters({
  openFilter,
  setOpenFilter,
  filterSummary,
  filters,
  addToSelectedFilters,
  removeFromSelectedFilters,
  swatchesMap,
}) {
  const settings = useSettings();
  const { optionsMaxCount, showCount, sticky } = {
    ...settings?.collection?.filters,
  };
  const stickyPromobar =
    settings?.header?.promobar?.enabled &&
    !settings?.header?.promobar?.autohide;
  const stickyTopClass = stickyPromobar
    ? 'md:top-[calc(var(--header-height)+var(--promobar-height)+3rem)]'
    : 'md:top-[calc(var(--header-height)+1.78rem)] xl:top-[calc(var(--header-height)+3rem)]';

  return (
    <div
      className={`filter-sidebar flex flex-col max-md:gap-5 md:sticky ${
        sticky ? stickyTopClass : ''
      }`}
    >
      <ul className="shadow-hairline bg-white max-md:hidden">
        {filters?.map((filter, index) => {
          const filterClass = filter.type === 'palette' ? 'hidden' : 'list-item';
          return (
            <li key={index} className={filterClass}>
              <CollectionSearchspringDropdownFilter
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
                filterSummary={filterSummary}
                filter={filter}
                optionsMaxCount={optionsMaxCount}
                showCount={showCount}
                addToSelectedFilters={addToSelectedFilters}
                removeFromSelectedFilters={removeFromSelectedFilters}
                swatchesMap={swatchesMap}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

CollectionSearchspringFilters.displayName = 'CollectionSearchspringFilters';
