import { useEffect } from 'react';

import { Svg } from '../../Svg';
import { useBodyScrollLock } from '../../../hooks';

export function CollectionPackMenuSidebar({ children, isOpen, setIsOpen, title }) {
  const { lockBodyScroll, unlockBodyScroll } = useBodyScrollLock();

  useEffect(() => {
    if (isOpen) {
      lockBodyScroll();
    } else {
      unlockBodyScroll();
    }

    // Event listener for "Escape" key press to close the sidebar
    const handleKeyDown = (event) => {
      if (isOpen && event.key === 'Escape') {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, setIsOpen]);

  return (
    <aside
      className={`${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      } position fixed inset-0 z-[100] flex h-full w-full flex-col bg-white transition lg:!hidden`}
      aria-hidden={!isOpen}
    >
      {isOpen && (
        <>
          <div className="h-[var(--menu-height)] relative flex items-center shadow-hairline-b bg-white transition gap-4 pr-5 mb-[0.5px]">
            <div className="h-full flex items-center pl-5">
              <button
                aria-label="Close menu sidebar"
                className="w-6"
                onClick={() => setIsOpen(false)}
                type="button"
              >
                <Svg
                  className="w-full text-text"
                  src="/svgs/close.svg#close"
                  alt="Close Menu Icon"
                  title="Close"
                  viewBox="0 0 32 32"
                />
              </button>
            </div>

            <h3 className="text-center text-lg">{title}</h3>
          </div>

          <div className="scrollbar-hide relative flex-1 overflow-y-auto">
            {children}
          </div>
        </>
      )}
    </aside>
  );
}

CollectionPackMenuSidebar.displayName = 'CollectionPackMenuSidebar';
