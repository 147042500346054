import { useCallback } from 'react';

import { useSearchspringSearchParameters, useSearchspringSearchResults } from '../../../hooks';

export function useSearch() {

  const { searchParameters } = useSearchspringSearchParameters()[1];
  const { results, totalResults } = useSearchspringSearchResults();

  function withErrorHandling(callback) {
    return async (...args) => {
      try {
        await callback(...args);
      } catch (error) {
        console.error(`Error in ${callback.name} function`, error);
        // Handle the error (e.g., set an error state, retry the operation, etc.)
      }
    };
  }

  const setParameters = useCallback(withErrorHandling(async (query) => {
    const { query: searchQuery } = query;
    await searchParameters({ query: searchQuery });
  }), []);

  return {
    setParameters,
    results,
    totalResults,
  };
}