import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';

import { useSearchspringContext } from '../../contexts';

import { routerReplaceUrlParams } from './';

export const useSearchspringCollectionPagination = () => {
  const router = useRouter();
  const {
    state: {
      collection,
      currentResultsPage,
      selectedResultsPerPage,
      loadedPages,
    },
    actions: {
      setCurrentResultsPage,
      setSelectedResultsPerPage,
      setLoadedPages,
      setPageInView,
    },
  } = useSearchspringContext();

  const { previousPage, nextPage, totalPages } = collection?.pagination || {};

  const updateResultsPerPage = useCallback((num) => {
    if (!num) {
      return console.error(
        'updateResultsPerPage: an integer from 1-100 must be passed'
      );
    }
    setSelectedResultsPerPage(num);
  }, []);

  const goToPrevPage = useCallback(() => {
    if (!previousPage) return;
    const minLoadedPage = Math.min(...loadedPages);
    if (minLoadedPage === 1) return;

    const newPage = minLoadedPage - 1;
    const newPages = [...new Set([...loadedPages, newPage])];

    routerReplaceUrlParams({
      router,
      page: newPage === 1 ? undefined : newPage,
      ...(newPage === 1 && { keysToRemove: ['page'] }),
    });

    setCurrentResultsPage(newPage);
    setLoadedPages(newPages);
  }, [previousPage]);

  const goToNextPage = useCallback(() => {
    if (!nextPage) return;
    const maxLoadedPage = Math.max(...loadedPages);
    if (maxLoadedPage === totalPages) return;

    const newPage = maxLoadedPage + 1;
    const newPages = [...new Set([...loadedPages, newPage])];
    
    routerReplaceUrlParams({
      router,
      page: newPage
    });

    setCurrentResultsPage(newPage);
    setLoadedPages(newPages);
  }, [nextPage]);

  const goToPage = useCallback(
    (num) => {
      if (!num || num > totalPages) return;
      setCurrentResultsPage(num);
      setLoadedPages([num]);
    },
    [totalPages]
  );

  const updatePageURLInView = useCallback(
    (num) => {
      routerReplaceUrlParams({
        router,
        page: num === 1 ? undefined : num,
        ...(num === 1 && { keysToRemove: ['page'] }),
      });
      setPageInView(num);
    },
    [router, setPageInView]
  );

  useEffect(() => {
    goToPage(1);
  }, []);

  return [
    // state
    {
      selectedResultsPerPage,
      currentResultsPage,
      totalPages: totalPages || 1,
      loadedPages,
    },
    // actions
    {
      updateResultsPerPage,
      goToPrevPage,
      goToNextPage,
      goToPage,
      updatePageURLInView,
    },
  ];
};