import { useMemo } from 'react';
import { useProductInventoryByHandle, useSettings } from '@backpackjs/storefront';
import PropTypes from 'prop-types';

export function Badges({
  className = '',
  isDraft = false, // in customizer, badge added to indicate product is draft
  tags = [],
  handle = '',
  soldOutQty = 1,
  isGiftCard = false,
}) {
  const { inventory, success: inventoryFetched } = useProductInventoryByHandle({
    handle: handle,
    withInventory: false,
  });

  const settings = useSettings();
  const { badgeColors } = { ...settings?.product?.badges };
  const { badgesMultiple } = { ...settings?.product?.badges };
  const { badgesMultipleNumber = 1 } = { ...settings?.product?.badges };
  const { soldOutBadge } = { ...settings?.product?.badges };

  const isSoldOut = isGiftCard ? false : inventory?.totalInventory < soldOutQty;
  const enabledSoldOutBadge = soldOutBadge?.enabled && isSoldOut;
  
  const badgeTags = useMemo(() => {
    if (!badgeColors) return [];
    let tags = badgeColors.map(badge => badge.tag).filter(Boolean);
    if (enabledSoldOutBadge) {
      tags.push(soldOutBadge.text);
    }
    return tags;
  }, [badgeColors, enabledSoldOutBadge, soldOutBadge]);

  const badgeColorsMap = useMemo(() => {
    if (!badgeColors) return {};
    let map = badgeColors.reduce((acc, badge) => {
      return { ...acc, [badge.tag?.trim()]: badge };
    }, {});
    if (enabledSoldOutBadge) {
      map[soldOutBadge.text] = {
        tag: soldOutBadge.text,
        bgColor: soldOutBadge.bgColor,
        textColor: soldOutBadge.textColor
      };
    }
    return map;
  }, [badgeColors, enabledSoldOutBadge, soldOutBadge]);

  const badges = useMemo(() => {
    return tags.reduce(
      (acc, tag) => {
        if (tag.startsWith('badge::')) {
          const value = tag.split('badge::')[1]?.trim();
          if (!value) return acc;
          return [...acc, value];
        }
        return acc;
      },
      isDraft ? ['Draft'] : []
    );
  }, [isDraft, tags]);

  // Sort the 'badges' array based on the order of 'badgeTags'
  const sortedBadges = useMemo(() => {
    let filteredBadges = badgeTags.filter(tag => badges.includes(tag));
    if (enabledSoldOutBadge) {
      filteredBadges.unshift(soldOutBadge.text);
    }
    return filteredBadges;
  }, [badgeTags, badges, enabledSoldOutBadge, soldOutBadge]);

  // Declare 'firstBadge' outside the 'if' block
  let firstBadge;

  // If 'badgesMultiple' is false, display only the first badge from 'sortedBadges'
  if (!badgesMultiple) {
    // Display only the first badge from 'sortedBadges'
    firstBadge = sortedBadges[0];
  }

  return (
    <div
      className={`text-xs flex flex-wrap gap-2.5 xs:gap-3 [&_div]:px-2 [&_div]:py-1 ${className}`}
    >
      {badgesMultiple ? (
        sortedBadges?.slice(0, badgesMultipleNumber).map((badge, index) => {
          const isWhiteBackground = badgeColorsMap[badge]?.bgColor === 'var(--white)';
          return (
            <div
              key={index}
              className={isWhiteBackground ? 'shadow-hairline-primary' : ''}
              style={{
                backgroundColor: badgeColorsMap[badge]?.bgColor || 'var(--black)',
                color: badgeColorsMap[badge]?.textColor || 'var(--white)',
              }}
            >
              {badge}
            </div>
          );
        })
      ) : (
        firstBadge && (
          <div
            className={
              badgeColorsMap[firstBadge]?.bgColor === 'var(--white)'
                ? 'shadow-hairline-primary'
                : ''
            }
            style={{
              backgroundColor: badgeColorsMap[firstBadge]?.bgColor || 'var(--black)',
              color: badgeColorsMap[firstBadge]?.textColor || 'var(--white)',
            }}
          >
            {firstBadge}
          </div>
        )
      )}
    </div>
  );
}

Badges.displayName = 'Badges';
Badges.propTypes = {
  className: PropTypes.string,
  isDraft: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string),
};
