import { COLORS, FLEX_POSITIONS, OBJECT_POSITIONS } from '../../settings/common';

export function Schema() {
  return {
    category: 'Media',
    label: 'Image Tiles',
    key: 'image-tiles',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/image-tiles-preview.jpg?v=1675730325',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        label: 'Hide Heading',
        name: 'hideHeading',
        component: 'toggle',
        description: 'Hide heading, toggle this off to mantain heading for accessibility',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
      },
      {
        label: 'Tiles',
        name: 'tiles',
        description: 'Min of 2 tiles, max of 12 tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        validate: {
          maxItems: 12,
        },
        fields: [
          {
            label: 'Image Alt',
            name: 'alt',
            component: 'text',
            description: 'Brief description of image',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Image Position',
            name: 'position',
            component: 'select',
            options: OBJECT_POSITIONS.mobile,
          },
          {
            label: 'Video Settings',
            name: 'video',
            description: 'Video link, poster image, autoplay, sound',
            component: 'group',
            fields: [
              {
                label: 'Video URL',
                name: 'src',
                component: 'text',
                description: 'Overrides tablet/desktop image option',
              },
              {
                label: 'Poster Image',
                name: 'poster',
                component: 'image',
              },
              {
                label: 'Autoplay When In View',
                name: 'autoplay',
                component: 'toggle',
                description:
                  'Disabling controls video through play button instead. Also applies muted and loop attributes',
                toggleLabels: {
                  true: 'On',
                  false: 'Off',
                },
              },
              {
                label: 'Sound',
                name: 'sound',
                component: 'toggle',
                description:
                  'Only applicable if autoplay is off. Also applies controls attribute',
                toggleLabels: {
                  true: 'On',
                  false: 'Off',
                },
              },
            ],
          },
          {
            label: 'Superheading',
            name: 'superheading',
            component: 'text',
          },
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'SVG',
            name: 'svg',
            component: 'group',
            fields: [
              {
                label: 'SVG File Name',
                name: 'svgName',
                component: 'text',
                description: 'SVG file name without the extension, e.g. `icon-name.svg#icon-name`',
              },
              {
                label: 'SVG Desktop Width',
                name: 'svgDesktopWidth',
                component: 'number',
              },
              {
                label: 'SVG Mobile Width',
                name: 'svgMobileWidth',
                component: 'number',
              },
              {
                label: 'SVG Desktop Height',
                name: 'svgDesktopHeight',
                component: 'number',
              },
              {
                label: 'SVG Mobile Height',
                name: 'svgMobileHeight',
                component: 'number',
              },
              {
                label: 'SVG Alt Text',
                name: 'svgAlt',
                component: 'text',
              },
            ],
          },
          {
            label: 'Buttons',
            name: 'buttons',
            component: 'group-list',
            description:
              'Max of 2 buttons. Second button will be hidden if image is set to be clickable',
            itemProps: {
              label: '{{item.link.text}}',
            },
            defaultItem: {
              link: {
                text: 'Shop Now',
                url: '',
              },
            },
            validate: {
              maxItems: 2,
            },
            fields: [
              {
                label: 'Link',
                name: 'link',
                component: 'link',
              },
            ],
          },
        ],
        defaultItem: {
          alt: 'Man in white and light tan outfit',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-in-white-and-light-tan-outfit.jpg?v=1672348139',
          },
          position: 'object-center',
          heading: 'Headline',
          buttons: [
            {
              link: {
                text: 'Shop Now',
                url: '',
              },
            },
          ],
        },
        defaultValue: [
          {
            alt: 'Man in white and light tan outfit',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-in-white-and-light-tan-outfit.jpg?v=1672348139',
            },
            position: 'object-center',
            heading: 'Headline',
            buttons: [
              {
                link: {
                  text: 'Shop Now',
                  url: '',
                },
              },
            ],
          },
          {
            alt: 'Man in brown coat sitting down',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/austin-wade-d2s8NQ6WD24-unsplash.jpg?v=1672348122',
            },
            position: 'object-center',
            heading: 'Headline',
            buttons: [
              {
                link: {
                  text: 'Shop Now',
                  url: '',
                },
              },
            ],
          },
          {
            alt: 'Man in gray sweater and tan coat',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-poses-in-light-colored-overcoat.jpg?v=1672348143',
            },
            position: 'object-center',
            heading: 'Headline',
            buttons: [
              {
                link: {
                  text: 'Shop Now',
                  url: '',
                },
              },
            ],
          },
        ],
      },
      {
        label: 'Items Per Row',
        name: 'itemsPerRow',
        component: 'select',
        description: 'Number of items per row',
        options: [
          { label: '2', value: 'lg:grid-cols-2' },
          { label: '3', value: 'lg:grid-cols-3' },
          { label: '4', value: 'lg:grid-cols-4' },
          { label: '5', value: 'lg:grid-cols-5' },
          { label: '6', value: 'lg:grid-cols-6' },
        ],
        defaultValue: 'grid-cols-6',
      },
      {
        label: 'Content Settings',
        name: 'content',
        component: 'group',
        description:
          'Dark overlay, content position, button styles, clickable image, hide buttons',
        fields: [
          {
            label: 'Dark Overlay',
            name: 'darkOverlay',
            component: 'toggle',
            description: 'Adds 20% opacity black overlay over media',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Text Shadow',
            name: 'textShadow',
            component: 'toggle',
            description: 'Adds shadow to text for better readability',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Heading Style',
            name: 'headingStyle',
            component: 'select',
            options: [
              { label: 'Normal', value: 'normal' },
              { label: 'Big', value: 'big' },
            ],
          },
          {
            label: 'Content Position',
            name: 'contentPosition',
            component: 'select',
            options: FLEX_POSITIONS.mobile,
          },
          {
            label: 'Primary Button Style',
            name: 'primaryButtonStyle',
            component: 'select',
            options: [
              { label: 'Primary', value: 'btn-primary' },
              { label: 'Secondary', value: 'btn-secondary' },
              { label: 'Inverse Light', value: 'btn-inverse-light' },
              { label: 'Inverse Dark', value: 'btn-inverse-dark' },
            ],
          },
          {
            label: 'Secondary Button Style',
            name: 'secondaryButtonStyle',
            component: 'select',
            options: [
              { label: 'Primary', value: 'btn-primary' },
              { label: 'Secondary', value: 'btn-secondary' },
              { label: 'Inverse Light', value: 'btn-inverse-light' },
              { label: 'Inverse Dark', value: 'btn-inverse-dark' },
            ],
          },
          {
            label: 'Clickable Image',
            name: 'clickableImage',
            component: 'toggle',
            description: `Makes entire image clickable using primary button's link; hides any secondary button`,
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Hide Buttons',
            name: 'hideButtons',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          darkOverlay: true,
          contentPosition: 'justify-center items-end',
          primaryButtonStyle: 'btn-inverse-light',
          secondaryButtonStyle: 'btn-inverse-light',
          clickableImage: true,
          hideButtons: false,
        },
      },
      {
        label: 'Slider Settings',
        name: 'slider',
        component: 'group',
        description: 'Slider style, slides per view',
        fields: [
          {
            label: 'Slider Style',
            name: 'sliderStyle',
            component: 'select',
            description:
              'Loop and centered settings only apply if the number of products is at least twice the number of slides per view',
            options: [
              { label: 'Contained', value: 'contained' },
              { label: 'Contained w/ Loop', value: 'containedWithLoop' },
              { label: 'Full Bleed, Centered w/ Loop', value: 'fullBleed' },
              {
                label: 'Full Bleed, Centered w/ Loop & Gradient',
                value: 'fullBleedWithGradient',
              },
            ],
          },
          {
            label: 'Slides Per View (desktop)',
            name: 'slidesPerViewDesktop',
            component: 'number',
            description: 'Tip: use decimals to show partial slides',
          },
          {
            label: 'Slides Per View (tablet)',
            name: 'slidesPerViewTablet',
            component: 'number',
            description: 'Tip: use decimals to show partial slides',
          },
          {
            label: 'Slides Per View (mobile)',
            name: 'slidesPerViewMobile',
            component: 'number',
            description: 'Tip: use decimals to show partial slides',
          },
          {
            label: 'Slider Navigation (desktop)',
            name: 'sliderNavigationDesktop',
            component: 'select',
            options: [
              { label: 'None', value: 'none' },
              { label: 'Arrows', value: 'arrows' },
              { label: 'Dots', value: 'dots' },
              { label: 'Both', value: 'both' },
            ],
          },
          {
            label: 'Slider Navigation (mobile)',
            name: 'sliderNavigationMobile',
            component: 'select',
            options: [
              { label: 'None', value: 'none' },
              { label: 'Arrows', value: 'arrows' },
              { label: 'Dots', value: 'dots' },
              { label: 'Both', value: 'both' },
            ],
          },
          {
            label: 'Disable Slider',
            name: 'disableSlider',
            component: 'toggle',
            description: 'When disabled, mobile and tablet column size will be assigned by Slides Per View (mobile) and Slides Per View (tablet)',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          }
        ],
        defaultValue: {
          sliderStyle: 'contained',
          slidesPerViewDesktop:6,
          slidesPerViewTablet: 3.4,
          slidesPerViewMobile: 1.4,
          sliderNavigationDesktop: 'arrows',
          sliderNavigationMobile: 'dots',
          disableSlider: false,
        },
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Image aspect ratio, full width',
        fields: [
          {
            label: 'Background Color',
            name: 'bgColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Image Aspect Ratio',
            name: 'aspectRatio',
            component: 'select',
            options: [
              { label: '3:2', value: 'aspect-[3/2]' },
              { label: '4:3', value: 'aspect-[4/3]' },
              { label: '5:4', value: 'aspect-[5/4]' },
              { label: '8:7', value: 'aspect-[8/7]' },
              { label: '1:1', value: 'aspect-[1/1]' },
              { label: '7:8', value: 'aspect-[7/8]' },
              { label: '4:5', value: 'aspect-[4/5]' },
              { label: '3:4', value: 'aspect-[3/4]' },
              { label: '2:3', value: 'aspect-[2/3]' },
            ],
          },
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Spacing',
            name: 'spacing',
            component: 'select',
            description: 'Addjust space around this section',
            options: [
              { label: 'Vertical and horizontal padding', value: 'px-contained py-contained' },
              { label: 'Only vertical padding', value: 'py-contained' },
              { label: 'Only horizontal padding', value: 'px-contained' },
              { label: 'Remove top padding', value: 'px-contained py-contained !pt-0' },
              { label: 'Remove bottom padding', value: 'px-contained py-contained !pb-0' },
            ],
            defaultValue: 'px-contained py-contained',
          },
        ],
        defaultValue: {
          aspectRatio: 'aspect-[3/4]',
          fullWidth: false,
        },
      },
    ],
  };
}
