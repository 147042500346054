import { useCartItems, useSettings } from '@backpackjs/storefront';

import { CartEmpty } from './CartEmpty';
import { CartItem } from './CartItem';
import { CartTotals } from './CartTotals';
import { CartUpsell } from './CartUpsell';
import { FreeShippingMeter } from './FreeShippingMeter';

export function CartPage() {
  const settings = useSettings();
  const cartItems = useCartItems();
  const heading = settings?.cart?.heading;
  const hasCartItems = cartItems?.length > 0;

  return (
    <div className="md:px-contained py-contained">
      <div className="mx-auto max-w-[80rem]">
        <h1 className="text-title-h2 mb-4 px-4">{heading || 'My Cart'}</h1>

        <div
          className={`grid gap-x-4 md:grid-flow-col-dense md:grid-rows-[auto_1fr] md:gap-y-4 ${
            hasCartItems
              ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-[3fr_2fr]'
              : 'grid-cols-1'
          }`}
        >
          <div className="md:row-span-2">
            <ul
              className={`relative border-y border-border ${
                hasCartItems ? '' : 'min-h-[20rem] py-12 md:min-h-[30rem]'
              }`}
            >
              {hasCartItems ? (
                cartItems.map((item) => {
                  return (
                    <li
                      key={item.id}
                      className="border-b border-b-border last:border-none"
                    >
                      <CartItem item={item} />
                    </li>
                  );
                })
              ) : (
                <CartEmpty />
              )}
            </ul>
          </div>

          {hasCartItems && (
            <div className="flex flex-col md:gap-4">
              <div className="[&>div]:max-md:border-t-0 [&>div]:md:rounded [&>div]:md:border [&>div]:md:border-border">
                <CartTotals />
              </div>

              <div className="[&>div]:border-t [&>div]:border-b-0 [&>div]:border-border [&>div]:md:rounded [&>div]:md:border [&>div]:md:border-b">
                <FreeShippingMeter />
              </div>

              <div className="[&>div]:border-border [&>div]:md:rounded [&>div]:md:border">
                <CartUpsell />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

CartPage.displayName = 'CartPage';
