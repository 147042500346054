import { useEffect, useState } from 'react';

import { useSearch } from './useSearch';
import {
  useSearchCollectionResults,
} from '../../../hooks';

import { Link } from '../../Link';
import { SearchHeader } from './SearchHeader';
import { SearchInput } from './SearchInput';
import { SearchResults } from './SearchResults';
import { SearchSuggestions } from './SearchSuggestions';


export function SearchspringSearch({ searchOpen, closeSearch }) {
  const [rawTerm, setRawTerm] = useState('');
  const [term, setTerm] = useState(rawTerm);

  const { setParameters, results, totalResults } = useSearch();
  
  const { collectionResults } = useSearchCollectionResults({
    term,
    mounted: searchOpen,
  });

  const hasProductResults = results?.length > 0;
  const hasCollectionResults = collectionResults?.length > 0;
  const hasResults = hasProductResults || hasCollectionResults;

  useEffect(() => {
    // Debounce raw term
    const debouncedHandler = window.requestTimeout(() => {
      setTerm(rawTerm);
    }, 300);
  
    // Event listener for "Escape" key press
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeSearch();
      }
    };

    setParameters({ query: rawTerm });
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.clearRequestTimeout(debouncedHandler);
    };
  }, [rawTerm]);

  return (
    <aside
      className={`fixed left-full top-0 z-[70] flex h-full w-full flex-col justify-between overflow-hidden bg-white transition md:max-w-[23.5rem] ${
        searchOpen
          ? 'pointer-events-auto -translate-x-full'
          : 'pointer-events-none translate-x-0'
      }`}
      aria-hidden={!searchOpen}
    >
      <>
        <SearchHeader closeSearch={closeSearch} />

        <SearchInput
          closeSearch={closeSearch}
          rawTerm={rawTerm}
          searchOpen={searchOpen}
          setRawTerm={setRawTerm}
        />

        {hasResults ? (
          <SearchResults
            closeSearch={closeSearch}
            collectionResults={collectionResults}
            productResults={results}
          />
        ) : (
          <SearchSuggestions term={term} setRawTerm={setRawTerm} />
        )}

        {hasProductResults && (
          <div className="border-t border-t-border p-4">
            <Link
              aria-label="See all search results"
              className="btn-secondary w-full"
              href={{ pathname: '/pages/search', query: { term } }}
              onClick={closeSearch}
            >
              {/* See All {productResults.length} Results */}
              See All {totalResults} Results
            </Link>
          </div>
        )}
      </>
    </aside>
  );
}

SearchspringSearch.displayName = 'SearchspringSearch';
