import { useEffect } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { useSearchspringSearchSuggestions } from '../../../hooks';

export function SearchAutocomplete({ setRawTerm, term }) {
  const settings = useSettings();
  const { enabled, heading, limit } = { ...settings?.search?.autocomplete };

  const {
    state: { suggestions },
    actions: { getSearchSuggestions },
  } = useSearchspringSearchSuggestions();

  useEffect(() => {
    if (term) {
      getSearchSuggestions({ query: term });
    }
  }, [term, getSearchSuggestions]);

  return enabled && suggestions.length > 0 ? (
    <div className="mt-4">
      <h3 className="text-xs italic">{heading}</h3>

      <ul className="flex flex-wrap gap-x-2">
        {suggestions.slice(0, limit || 5).map((suggestion, index) => {
          return (
            <li key={index}>
              <button
                aria-label={suggestion}
                onClick={() => setRawTerm(suggestion)}
                type="button"
              >
                <p className="text-underline text-xs">{suggestion}</p>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
}

SearchAutocomplete.displayName = 'SearchAutocomplete';
