import { useMemo, useState, useEffect } from 'react';
import { useSettings } from '@backpackjs/storefront';
import PropTypes from 'prop-types';
import { useDataLayerActions } from '../../../hooks';

import { CollectionPackFilters } from './CollectionPackFilters';
import { CollectionPackSortFilterBar } from './CollectionPackSortFilterBar';
import { CollectionPackGrid } from './CollectionPackGrid';
import { useCollectionFilters } from './useCollectionFilters';
import { useCollectionProducts } from './useCollectionProducts';
import { useColorSwatches } from '../../../hooks';

export function CollectionPack({
  handle = undefined,
  id = undefined,
  products = [],
  productsReady = true,
}) {
  const { swatchesMap } = useColorSwatches();
  const settings = useSettings();
  const {
    filters: filtersSettings,
    sort: sortSettings,
    pagination,
    promotion,
  } = { ...settings?.collection };

  const enabledFilters = filtersSettings?.enabled;
  const enabledSort = sortSettings?.enabled;
  const isSearchResults = handle === 'search';

  // Open desktop filters
  const [areOpen, setAreOpen] = useState(true);

  const promoTiles = useMemo(() => {
    if (!promotion?.campaigns?.length) return null;
    const campaign = promotion.campaigns.find(({ collections, enabled }) => {
      if (!enabled) return false;
      return collections?.some((colHandle) => colHandle.trim() === handle);
    });
    return campaign?.promoTiles || null;
  }, [handle, promotion?.campaigns]);

  const collectionFiltersData = useCollectionFilters({
    enabledFilters,
    products,
    productsReady,
  });

  const collectionProductsData = useCollectionProducts({
    activeFilters: collectionFiltersData.state.activeFilters,
    filterByInStock: collectionFiltersData.state.filterByInStock,
    filtersMap: collectionFiltersData.state.filtersMap,
    handle,
    pagination,
    products,
    productsReady,
    promoTiles,
  });

  const { sendViewCollectionEvent } = useDataLayerActions();
  useEffect(() => {
    sendViewCollectionEvent({
      products: products,
    });
  }, [
    // dependency from collection only whether if products is empty or not
    `${id}${!!products?.length}`,
    sendViewCollectionEvent,
  ]);

  return (
    <div className="mx-auto flex max-w-[var(--content-max-xl-width)] flex-col">
      <CollectionPackSortFilterBar
        enabledSort={enabledSort}
        enabledFilters={enabledFilters}
        selectedSort={collectionProductsData.state.selectedSort}
        selectSort={collectionProductsData.actions.selectSort}
        areOpen={areOpen}
        setAreOpen={setAreOpen}
        collectionFiltersData={collectionFiltersData}
        collectionCount={products?.length}
        swatchesMap={swatchesMap}
      />
      <div className="collection-container mt-5 flex w-full flex-row flex-wrap">
        {enabledFilters && (
          <div
            className={`order-3 ${
              areOpen
                ? 'max-md:w-1/2 md:hidden'
                : 'md:block md:w-1/5 md:pr-5 xl:pr-10'
            }`}
          >
            <CollectionPackFilters
              collectionFiltersData={collectionFiltersData}
              collectionCount={products?.length}
              swatchesMap={swatchesMap}
            />
          </div>
        )}
        <div className={`order-4 ${areOpen ? 'w-full' : 'w-full md:w-4/5'}`}>
          <CollectionPackGrid
            activeFilters={collectionFiltersData.state.activeFilters}
            collectionProductsData={collectionProductsData}
            enabledFilters={enabledFilters}
            isSearchResults={isSearchResults}
            promoTiles={promoTiles}
            swatchesMap={swatchesMap}
          />
        </div>
      </div>
    </div>
  );
}

CollectionPack.displayName = 'CollectionPack';
CollectionPack.propTypes = {
  handle: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  productsReady: PropTypes.bool,
};
