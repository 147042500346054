import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { QuickShopOption } from './QuickShopOption';

export function QuickShopOptions({
  quickShopMultiText,
  selectedProduct,
  selectedVariant,
  isCompact = false,
  inventory = {},
  inventoryFetched = false,
  isSoldOut = false,
  enabledQuickShopBIS = true, 
  quickShopBISSingleText, 
  quickShopBISMultitext,
}) {
  const option = useMemo(() => {
    if (!selectedProduct) return { name: '', values: [], text: '' };
    const options = selectedProduct.grouping?.isTransformed
      ? selectedProduct.grouping.options
      : selectedProduct.options;
    const _option = options.find(({ name }) => name !== 'Color');
    return {
      name: _option?.name,
      values: _option?.values || [],
      text: quickShopMultiText?.replace('{{option}}', _option?.name),
    };
  }, [quickShopMultiText, selectedProduct.id]);

  return (
    <div className={`relative flex w-full items-center justify-center shadow-hairline-primary bg-primary ${
      isCompact ? 'h-8' : 'group/quickshop h-[3.125rem] '
    }`}>
      <p className="btn-text text-white truncate px-3">
        {enabledQuickShopBIS && isSoldOut ? quickShopBISMultitext : option.text}
      </p>
      <ul
        className={`absolute inset-0 grid h-full w-full bg-white shadow-hairline ${
          isCompact ? '' : 'invisible group-hover/quickshop:visible group-focus/quickshop:visible'
        }`}
        style={{
          gridTemplateColumns: `repeat(${option.values.length}, 1fr)`,
        }}
      >
        {option.values.map((value) => {
          return (
            <li
              key={value}
              className="overflow-hidden shadow-hairline-r"
            >
              <QuickShopOption
                optionName={option.name}
                inventory={inventory}
                inventoryFetched={inventoryFetched}
                selectedProduct={selectedProduct}
                selectedVariant={selectedVariant}
                value={value}
                isCompact={isCompact}
                isSoldOut={isSoldOut}
                enabledQuickShopBIS={enabledQuickShopBIS}
                quickShopBISSingleText={quickShopBISSingleText}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

QuickShopOptions.displayName = 'QuickShopOptions';
QuickShopOptions.propTypes = {
  quickShopMultiText: PropTypes.string,
  selectedProduct: PropTypes.object,
  selectedVariant: PropTypes.object,
  isCompact: PropTypes.bool,
  inventory: PropTypes.object,
  inventoryFetched: PropTypes.bool,
  isSoldOut: PropTypes.bool,
  quickShopBIS: PropTypes.object,
};
