import { CollectionSearchspringDropdownFilter } from './CollectionSearchspringDropdownFilter';
import { CollectionSearchspringFiltersSummary } from './CollectionSearchspringFiltersSummary';
import { CollectionSearchspringMenuSidebar } from '../CollectionSearchspringMenuSidebar';
import { Svg } from '../../../Svg';

export function CollectionSearchspringMobileFilters({
  openFilter,
  setOpenFilter,
  filters,
  filterSummary,
  addToSelectedFilters,
  removeFromSelectedFilters,
  clearSelectedFilters,
  optionsMaxCount,
  showCount,
  swatchesMap,
  isOpen,
  setIsOpen,
}) {
  // const totalFilters = Object.keys(filterSummary).length;
  const totalFilters = filterSummary ? Object.keys(filterSummary).length : 0;

  return (
    <>
      <button
        aria-label={`${isOpen ? 'Close' : 'Open'} filters sidebar`}
        className="shadow-hairline flex w-max items-center justify-between bg-offWhite p-3 text-base lg:hidden lg:px-0"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <Svg
          className="text-text"
          src="/svgs/filter.svg#filter"
          alt="Filter Icon"
          title="Filter"
          viewBox="0 0 24 24"
          width="24px"
          height="24px"
        />
        <div className="flex flex-1 items-center gap-2 overflow-hidden xs:gap-3">
          <h3 className="text-base">Filters</h3>

          {totalFilters > 0 && (
            <p className="truncate text-xs text-mediumGray xs:text-sm">
              {totalFilters} Selected
            </p>
          )}
        </div>
      </button>

      <CollectionSearchspringMenuSidebar
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Filters"
      >
        <div className="shadow-hairline-b sticky top-0 z-[1] bg-offWhite p-5">
          <div className="mb-4 flex justify-between gap-2">
            <h3 className="text-nav">
              Filters Summary{' '}
              <span className="text-xs">
                {totalFilters ? `(${totalFilters})` : ''}
              </span>
            </h3>

            {totalFilters > 0 && (
              <button
                className="text-main-underline text-xs"
                onClick={() => {
                  clearSelectedFilters();
                  const clearFilterElement = document.querySelector('[clear-filter-icons="true"]');
                  if (clearFilterElement) clearFilterElement.click();
                }}
                type="button"
              >
                Clear All
              </button>
            )}
          </div>

          <div className="scrollbar-hide max-h-[10rem] min-h-[2rem] overflow-y-auto">
            {totalFilters ? (
              <CollectionSearchspringFiltersSummary
                filterSummary={filterSummary}
                removeFromSelectedFilters={removeFromSelectedFilters}
                clearSelectedFilters={clearSelectedFilters}
                hideClearButton
              />
            ) : (
              <p className="leading-[2rem] text-mediumGray">
                No filters selected yet
              </p>
            )}
          </div>
        </div>

        <ul className="bg-white">
          {filters?.map((filter, index) => {
            const filterClass = filter.type === 'palette' ? 'hidden' : 'list-item';
            return (
              <li key={index} className={filterClass}>
                <CollectionSearchspringDropdownFilter
                  openFilter={openFilter}
                  setOpenFilter={setOpenFilter}
                  filterSummary={filterSummary}
                  filter={filter}
                  optionsMaxCount={optionsMaxCount}
                  showCount={showCount}
                  addToSelectedFilters={addToSelectedFilters}
                  removeFromSelectedFilters={removeFromSelectedFilters}
                  swatchesMap={swatchesMap}
                />
              </li>
            );
          })}
        </ul>
      </CollectionSearchspringMenuSidebar>
    </>
  );
}

CollectionSearchspringMobileFilters.displayName = 'CollectionSearchspringMobileFilters';
