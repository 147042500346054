import { useState } from 'react';

import { Svg } from '../Svg';
import { ProductReviewsReview } from './ProductReviewsReview';

export function ProductReviewsReviews ({
  currentPage,
  reviewsPerPage,
  selectedRate,
  selectedTopic,
  searchQuery,
  allReviewsData,
  filteredReviews,
  reviewCount,
}){
  const [viewMode, setViewMode] = useState('list');
  
  const setGridView = () => {
    setViewMode('grid');
  };
  const setListView = () => {
    setViewMode('list');
  };
  const containerClass = viewMode === 'grid' ? 'columns-2 lg:columns-4 [column-fill:_balance] gap-5 space-y-5' : 'flex flex-col';
  
  const startIndex = (currentPage - 1) * reviewsPerPage;
  const endIndex = startIndex + reviewsPerPage;
  let currentReviews = [];

  if (selectedRate || selectedTopic || searchQuery) {
    currentReviews = filteredReviews
      .slice(startIndex, endIndex)
      .map((reviewId) => allReviewsData[reviewId]);
  } else {
    currentReviews = allReviewsData.slice(startIndex, endIndex);
  }
  if (currentReviews.length === 0) {
    return <p>No results found.</p>;
  }

  return (
    <>
      <div className="flex flex-row space-between">
        {reviewCount > 1 && (
          <>
          {selectedRate || selectedTopic || searchQuery ? (
            <p className="text-mediumDarkGray">
              Total Reviews Shown: <span className="font-medium">{filteredReviews.length}</span> of <span className="font-medium">{allReviewsData.length}</span>
            </p>
          ) : (
            <p className="text-mediumDarkGray">Total Reviews: <span className="font-medium">{allReviewsData.length}</span></p>
          )}
          </>
        )}
        
        <div className="flex flex-row gap-[5px] ml-auto">
          <button 
            className={`flex items-center justify-between shadow-hairline p-3 text-base bg-offWhite hover:bg-white ${
              viewMode === 'list' ? 'bg-white' : 'bg-offWhite'
            }`} 
            onClick={setListView}
            aria-label="List View"
            type="button"
          >
            <Svg
              className="w-4 text-text"
              src="/svgs/list.svg#list"
              alt="List View Icon"
              title="List"
              viewBox="0 0 24 24"
            />
          </button>
          <button 
            className={`flex items-center justify-between shadow-hairline p-3 text-base hover:bg-white ${
              viewMode === 'grid' ? 'bg-white' : 'bg-offWhite'
            }`} onClick={setGridView}
            aria-label="Grid"
            type="button"
          >
            <Svg
              className="w-4 text-text"
              src="/svgs/grid.svg#grid"
              alt="Grid View Icon"
              title="Grid View"
              viewBox="0 0 24 24"
            />
          </button>
          
        </div>
      </div>
      
      <div className={containerClass}>
        {currentReviews.map((review, index) => (
          <ProductReviewsReview
            review={review}
            key={review.id}
            index={index}
            viewMode={viewMode}
            currentReviews={currentReviews}
          />
        ))}
      </div>
    </>
  );
}

ProductReviewsReviews.displayName = 'ProductReviewsReviews';