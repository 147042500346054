import { useEffect } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { Link } from '../../Link';
import { SearchItem } from './SearchItem';
import { useDataLayerActions } from '../../../hooks';

export function SearchResults({
  closeSearch,
  collectionResults,
  productResults,
}) {
  const settings = useSettings();
  const { sendViewSearchResultsEvent } = useDataLayerActions();

  const { productsEnabled, collectionsEnabled } = {
    ...settings?.search?.results,
  };

  useEffect(() => {
    sendViewSearchResultsEvent({
      products: productResults,
    });
  }, [productResults, sendViewSearchResultsEvent]);

  return (
    <div className="bg-offWhite shadow-hairline scrollbar-hide relative flex flex-1 flex-col gap-5 overflow-y-auto p-5">
      {productsEnabled && productResults?.length > 0 && (
        <div>
          <h3 className="text-title-h5 mb-3">Products</h3>

          <ul className="flex flex-1 flex-col gap-5">
            {productResults.slice(0, 10).map((item, index) => {
              return (
                <li
                  key={index}
                >
                  <SearchItem
                    closeSearch={closeSearch}
                    index={index}
                    item={item}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {collectionsEnabled && collectionResults?.length > 0 && (
        <div>
          <h3 className="text-title-h5 mb-3">Collections</h3>

          <ul className="flex flex-col items-start gap-3">
            {collectionResults.map((item, index) => {
              return (
                <li key={index}>
                  <Link
                    aria-label={item.title}
                    href={`/collections/${item.handle}`}
                  >
                    <p className="text-underline">{item.title}</p>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

SearchResults.displayName = 'SearchResults';
