const replaceUrlParams = ({
  entriesToAdd = [],
  entriesToRemove = [],
  entriesToReplace = [],
  keysToRemove = [],
  page,
  updateUrlCallback,
}) => {
  const { origin, search, pathname } = window.location;
  const params = new URLSearchParams(search);

  // Create a copy of the current params to compare later
  const currentParams = new URLSearchParams(params.toString());

  entriesToAdd.forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(singleValue => {
        params.append(key, singleValue);
      });
    } else {
      params.append(key, value);
    }
  });

  entriesToRemove.forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(singleValue => {
        params.delete(key, singleValue);
      });
    } else {
      params.delete(key, value);
    }
  });

  entriesToReplace.forEach(([key, value]) => {
    if (key.startsWith('sort.')) {
      const keysToRemove = [];
      params.forEach((_, paramKey) => {
        if (paramKey.startsWith('sort.')) {
          keysToRemove.push(paramKey);
        }
      });
      keysToRemove.forEach(paramKey => params.delete(paramKey));
    }
    
    if (Array.isArray(value)) {
      value.forEach(singleValue => {
        params.set(key, singleValue);
      });
    } else { 
      params.set(key, value);
    }
  });

  keysToRemove.forEach((key) => {
    params.delete(key);
  });

  if (page !== undefined) {
    params.set('page', page);
  }

  // Check if the parameters have actually changed
  const updatedParams = params.toString();
  const currentParamsString = currentParams.toString();

  if (updatedParams !== currentParamsString) {
    const updatedUrl = `${origin}${pathname}?${updatedParams}`;
    updateUrlCallback(updatedUrl);
  }
};

export const routerReplaceUrlParams = (options) => {
  replaceUrlParams({
    ...options,
    updateUrlCallback: (updatedUrl) => {
      options.router.replace(updatedUrl, undefined, { shallow: true });
    },
  });
};

export const windowReplaceUrlParams = (options) => {
  replaceUrlParams({
    ...options,
    updateUrlCallback: (updatedUrl) => {
      window.history.replaceState(window.history.state, '', updatedUrl);
    },
  });
};