import React from 'react';
import PropTypes from 'prop-types';

import { Image } from '../Image';
import { Link } from '../Link';
import { Svg } from '../Svg';

export function TilesTile({ aspectRatio, item, textColor, iconColor}) {
const contentClass = item.image?.src ? 'items-start' : 'absolute justify-center items-start p-5';
const showArrow = item.showArrow ?? true;

const icons = item?.icons || [];
const fixedType = item?.link?.newTab ? 'isExternal' : 'isPage';

  return (
    <div className="w-full h-full relative" style={{ color: textColor }}>
      <div className={`relative mb-0.5 lg:mb-5 bg-offWhite ${item.style === 'primary' ? aspectRatio : 'min-h-full'}`}>
        <Link
          aria-label={
            item.link?.newTab
              ? `Open in a new tab to view ${item.heading}`
              : item.heading
          }
          href={item.link?.url}
          newTab={item.link?.newTab}
          tabIndex="-1"
          type={fixedType}
        >
          {item.image?.src && (
            <Image
              alt={item.alt}
              className={`${item.position}`}
              fill
              sizes="(min-width: 1440px) 1200px, (min-width: 768px) 50vw, 100vw"
              src={item.image.src}
            />
          )}
        </Link>
      </div>

      <div className={`inset-0 flex h-full w-full flex-col ${contentClass}`}>
        <Link
          aria-label={
            item.link?.newTab
              ? `Open in a new tab to view ${item.heading}`
              : item.heading
          }
          href={item.link?.url}
          newTab={item.link?.newTab}
          type={fixedType}
        >
          <div className="group flex">
            <h4 className="text-lg lg:text-xl">{item.heading}</h4>

            {item.link?.url && showArrow === true && (
              <span className="ml-[0.75rem] block w-5 transition-transform lg:group-hover:translate-x-2">
                <Svg
                  src="/svgs/arrow-right.svg#arrow-right"
                  alt="Open Link Icon"
                  title="Arrow"
                  viewBox="0 0 24 24"
                />
              </span>
            )}
          </div>
        </Link>

        {item.description && <p className="text-sm">{item.description}</p>}

        {item.link?.text && (
          <div>
            <Link
              aria-label={
                item.link.newTab
                  ? `Open in a new tab to view ${item.link.text}`
                  : item.link.text
              }
              className={`text-label text-main-underline ${item.image?.src ? '' : 'text-secondary'}`}
              href={item.link.url}
              newTab={item.link.newTab}
              tabIndex="-1"
              type={fixedType}
            >
              {item.link.text}
            </Link>
          </div>
        )}

        {icons?.length > 0 && (
          <div className="hidden relative w-full mt-3 md:block">
            <ul className="flex flex-col overflow-hidden gap-1">
              {icons?.map(({ icon, label}, index) => {
                return (
                  <li
                    key={index}
                    className="flex grow gap-1 md:gap-2.5"
                  >
                    <Svg
                      className="w-8 max-w-5"
                      src={`/svgs/noprecache/icons/${icon}.svg#${icon}`}
                      style={{ color: iconColor }}
                      alt={label || icon}
                      title={label || icon}
                      viewBox="0 0 24 24"
                    />

                    {label && <p className="text-sm">{label}</p>}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

TilesTile.displayName = 'TilesTile';
TilesTile.propTypes = {
  aspectRatio: PropTypes.string,
  item: PropTypes.object,
  textColor: PropTypes.string,
};
