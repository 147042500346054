import { useSettings } from '@backpackjs/storefront';

import { CollectionPackMobileFilters } from '../CollectionPackFilters/CollectionPackMobileFilters';
import { CollectionPackFiltersSummary } from '../CollectionPackFilters/CollectionPackFiltersSummary';

import { Svg } from '../../../Svg';

export function CollectionPackFilter({
  isOpen,
  setIsOpen,
  areOpen,
  setAreOpen,
  collectionFiltersData,
  collectionCount,
  swatchesMap,
}) {
  const settings = useSettings();
  const { optionsMaxCount, showCount } = {
    ...settings?.collection?.filters,
  };
  const { enabled: inStockFilterEnabled, label: inStockLabel } = {
    ...settings?.collection?.inStockFilter,
  };

  const {
    state: { activeFilters, filterByInStock, filters, filtersMap },
    actions: { addFilter, removeFilter, clearFilters, setFilterByInStock },
  } = collectionFiltersData;

  return (
    <div className="flex w-full max-lg:items-baseline">
      <div className="hidden w-auto lg:inline-block">
        <div className="relative w-full">
          <button
            aria-label="Open filter menu"
            className={`shadow-hairline flex w-full items-center justify-between gap-4 bg-offWhite p-3 text-base hover:bg-white ${
              areOpen ? '' : 'bg-white'
            }`}
            onClick={() => setAreOpen(!areOpen)}
            type="button"
          >
            <Svg
              className="text-text"
              src="/svgs/filter.svg#filter"
              alt="Filter Icon"
              title="Filter"
              viewBox="0 0 24 24"
              width="24px"
              height="24px"
            />
            <p className="font-medium text-text">Filter</p>
          </button>
        </div>
      </div>
      <div className="lg:hidden">
        <CollectionPackMobileFilters
          activeFilters={activeFilters}
          addFilter={addFilter}
          clearFilters={clearFilters}
          collectionCount={collectionCount}
          filterByInStock={filterByInStock}
          filters={filters}
          filtersMap={filtersMap}
          inStockFilterEnabled={inStockFilterEnabled}
          inStockLabel={inStockLabel}
          optionsMaxCount={optionsMaxCount}
          removeFilter={removeFilter}
          setFilterByInStock={setFilterByInStock}
          showCount={showCount}
          swatchesMap={swatchesMap}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </div>
      <div className="relative top-[4px] mr-auto hidden w-full md:block">
        <CollectionPackFiltersSummary
          activeFilters={activeFilters}
          clearFilters={clearFilters}
          filtersMap={filtersMap}
          removeFilter={removeFilter}
        />
      </div>
    </div>
  );
}

CollectionPackFilter.displayName = 'CollectionPackFilter';