import {
  useCartAddDiscountUrl,
  useStorefrontInit,
} from '@backpackjs/storefront';

import {
  Cart,
  DataLayerWithElevar,
  Footer,
  Header,
  Modal,
  Overlay,
  SchemaMarkup,
  Search,
  StorefrontHead,
} from '../snippets';
import { GlobalContextProvider, SearchspringContextProvider } from '../contexts';
import {
  usePromobar,
  useSetViewportHeightCssVar,
  useShopifyAnalytics,
  useThemeColors,
} from '../hooks';
import{ useState } from 'react';
import { useInView } from 'react-intersection-observer';

function StorefrontLayout({ ContentForLayout, ...props }) {
  // initialize customer, cart, shop and markets
  useStorefrontInit();

  // auto applies cart discount found on the landing url
  useCartAddDiscountUrl();

  // main padding top w/ or w/o promobar
  const { mainClassName } = usePromobar();

  // set css var for viewport height
  useSetViewportHeightCssVar();

  // set css vars for theme colors
  useThemeColors();

  // send page view and add to cart events to Shopify analytics
  useShopifyAnalytics({ collection: props.collection, product: props.product });

  const isProductPage = !!props.product;
  const [footerInView, setFooterInView] = useState(false);
  let refFooter = null;

  if (isProductPage) {
    const inViewRef = useInView({
      threshold: 0,
      triggerOnce: false,
      onChange: (inView) => {
        setFooterInView(inView);
      },
    });
    refFooter = inViewRef.ref;
  }
  
  return (
    <SearchspringContextProvider>
      <GlobalContextProvider>
        <StorefrontHead />

        {/* for Elevar integration use DataLayerWithElevar instead */}
        <DataLayerWithElevar {...props} />

        <SchemaMarkup {...props} />

        <Header />

        <main className={mainClassName}>
          {isProductPage ? <ContentForLayout {...props} footerInView={footerInView} /> : <ContentForLayout {...props} />}
        </main>

        {isProductPage ? <Footer refElement={refFooter} /> : <Footer />}

        <Overlay />

        <Cart />

        <Search />

        <Modal />
      </GlobalContextProvider>
    </SearchspringContextProvider>  
  );
}

export default StorefrontLayout;
