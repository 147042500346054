import { Svg } from '../../Svg';

export function SearchHeader({ closeSearch }) {
  return (
    <div className="h-[var(--menu-height)] relative flex items-center shadow-hairline-b bg-white transition gap-4 pr-5 mb-[0.5px]">
      <div className="h-full flex items-center pl-5">
        <button
          aria-label="Close search sidebar"
          className="w-6"
          onClick={closeSearch}
          type="button"
        >
          <Svg
            className="w-full text-text"
            src="/svgs/close.svg#close"
            alt="Close Icon"
            title="Close"
            viewBox="0 0 32 32"
          />
        </button>
      </div>
      
      <h3 className="text-center text-lg">Search</h3>
    </div>
  );
}

SearchHeader.displayName = 'SearchHeader';
