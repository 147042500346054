import { useMemo } from 'react';

export function SizeGuide({
  productType,
  sizeGuides,
}) {
  const sizeGuide = useMemo(() => sizeGuides?.sizeGuide ?? [], [sizeGuides]);

  return (
    <div>
      <h2 className="text-title-h3 text-center">Size Guide</h2>
      <h6 className="text-title-h6 text-center mb-6">{productType}</h6>
      {sizeGuide?.map((item, index) => {
        const types = item?.productTypes;
        const type = types?.some(type => type.productType === productType);

        return type === true ? (
          <table key={`size-guide-${index}`} className="w-full border-collapse">
            <thead>
              <tr>
                {item?.columns?.map((column, index) => (
                  <th key={`column-${index}`} className="px-4 py-2 border">{column?.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {item?.rows?.map((row, index) => (
                <tr key={`row-${index}`}>
                  {row?.values?.map((value, index) => (
                    <td key={`value-${index}`} className="px-4 py-2 border">{value.value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : null;
      })}
    </div>
  );
}

SizeGuide.displayName = 'SizeGuide';