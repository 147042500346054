import { useState } from 'react';
import { A11y, Navigation, Pagination, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useMatchMedia } from '../../hooks';

import { Spinner, Svg } from '../';
import { ThreeTilesTile } from './ThreeTilesTile';

export function ThreeTilesRow({
  heading,
  slider = {},
  sliderStyle = 'contained',
  isFullBleedAndCentered = false,
  isLoop = false,
  aspectRatio,
  maxWidthClass,
  textColor,
  tiles,
  itemsPerRow,
}) {
  const [swiper, setSwiper] = useState(null);

  const isMobileViewport = useMatchMedia('(max-width: 1023px)');

  const slidesPerViewTablet = slider?.slidesPerViewTablet || 3.4;
  const slidesPerViewMobile = slider?.slidesPerViewMobile || 1.4;
  const sliderArrowsMobile = slider?.sliderNavigationMobile === 'arrows' || slider?.sliderNavigationMobile === 'both' ? true : false;
  const sliderPaginationMobile = slider?.sliderNavigationMobile === 'dots' || slider?.sliderNavigationMobile === 'both' ? true : false;

  const tilesLength = tiles?.length > 3 ? tiles?.length : 3;
  const tilesPerRow = itemsPerRow || 'grid-cols-3';

  return tiles?.length > 0 && (
    <div className={`mx-auto ${maxWidthClass}`}>
      {isMobileViewport ? (
        <div className={`relative ${!swiper ? 'min-h-[25rem]' : ''}`}>
          <Swiper
            modules={[A11y, Navigation, Pagination, Keyboard]}
            a11y={{ enabled: true }}
            centeredSlides={
              isFullBleedAndCentered && tiles?.length >= slidesPerViewMobile * 2
            }
            grabCursor
            keyboard={{
              enabled: true,
              onlyInViewport: true,
            }}
            pagination={{
              el: '.swiper-pagination',
              clickable: true,
            }}
            loop={isLoop && tiles?.length >= slidesPerViewMobile * 2}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              disabledClass: 'cursor-not-allowed opacity-0',
            }}
            onSwiper={setSwiper}
            slidesOffsetAfter={isFullBleedAndCentered ? 0 : 0}
            slidesOffsetBefore={isFullBleedAndCentered ? 0 : 0}
            slidesPerView={slidesPerViewMobile}
            spaceBetween={16}
            breakpoints={{
              768: {
                centeredSlides:
                  isFullBleedAndCentered &&
                  tiles?.length >= slidesPerViewTablet * 2,
                loop: isLoop && tiles?.length >= slidesPerViewTablet * 2,
                slidesPerView: slidesPerViewTablet,
                slidesOffsetBefore: isFullBleedAndCentered ? 0 : 0,
                slidesOffsetAfter: isFullBleedAndCentered ? 0 : 0,
                spaceBetween: 16,
              },
            }}
            className={`relative w-full ${maxWidthClass} ${
              sliderStyle === 'fullBleedWithGradient'
                ? 'before:swiper-offset-gradient-270-left after:swiper-offset-gradient-270-right'
                : ''
            }`}
            role="region"
            aria-label={`${heading} Slider`}
            tabIndex="0"
          >
            {swiper &&
              tiles.slice(0, tilesLength).map((item, index) => {
                return (
                  <SwiperSlide className="w-full" key={index}>
                    <ThreeTilesTile
                      aspectRatio={aspectRatio}
                      item={item}
                      textColor={textColor}
                    />
                  </SwiperSlide>
                );
              })
            }

            {tiles?.length > slidesPerViewTablet || tiles?.length > slidesPerViewMobile && (
              <>
                <div className="z-1 absolute left-0 right-0 top-[calc(50%-28px)] md:px-8 xl:px-14">
                  <div
                    className={`relative mx-auto ${maxWidthClass} ${
                      isFullBleedAndCentered ? 'min-[90rem]:max-w-full' : ''
                    }`}
                  >
                    <div
                      className={`swiper-button-prev shadow-hairline inset-y-auto left-0 bg-slate-50/60 h-7 w-7 md:!h-14 md:!w-14 after:hidden ${
                        sliderArrowsMobile ? 'flex' : '!hidden'
                      } ${
                        !isFullBleedAndCentered ? 'md:-left-4' : ''
                      }`}
                      aria-label="Previous Slide"
                      type="button"
                      role="button"
                    >
                      <Svg
                        className="w-2.5 md:w-5 text-black"
                        src="/svgs/arrow-left.svg#arrow-left"
                        alt="Previous Slide Icon"
                        title="Arrow Left"
                        viewBox="0 0 24 24"
                      />
                    </div>

                    <div
                      className={`swiper-button-next shadow-hairline inset-y-auto right-0 bg-slate-50/60 h-7 w-7 md:!h-14 md:!w-14 after:hidden ${
                        sliderArrowsMobile ? 'flex' : '!hidden'
                      } ${
                        !isFullBleedAndCentered ? 'md:-right-4' : ''
                      }`}
                      aria-label="Next Slide Icon"
                      type="button"
                      role="button"
                    >
                      <Svg
                        className="w-2.5 md:w-5 text-black"
                        src="/svgs/arrow-right.svg#arrow-right"
                        alt="Next Slide icon"
                        title="Arrow Right"
                        viewBox="0 0 24 24"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`active-bullet-black swiper-pagination relative !bottom-0 !top-0 mt-2.5 w-full justify-center gap-2.5 ${
                    sliderPaginationMobile ? 'flex' : '!hidden'
                  }`}
                />
              </>
            )}
          </Swiper>

          {!swiper && (
            <Spinner
              width="32"
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            />
          )}
        </div>
      ) : (
        <div className={`hidden ${tilesPerRow} gap-x-5 lg:grid`}>
          {tiles.slice(0, tilesLength).map((item, blockIndex) => {
            return (
              <div key={blockIndex}>
                <ThreeTilesTile
                  aspectRatio={aspectRatio}
                  item={item}
                  textColor={textColor}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

ThreeTilesRow.displayName = 'ThreeTilesRow';
