import { COLORS } from '../../settings/common';

export function Schema() {
  return {
    category: 'text',
    label: 'Animated Text',
    key: 'animated-text',
    description: 'Heading, text, read more link, section settings',
    fields: [
      {
        label: 'Superheading',
        name: 'superheading',
        component: 'text',
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        label: 'Text',
        name: 'text',
        component: 'group-list',
        description: 'Add here the animated text',
        fields: [
          {
            label: 'text',
            name: 'text',
            component: 'text',
          },
          {
            label: 'Space Bellow',
            name: 'spaceBellow',
            component: 'toggle',
            description: 'Gives space under the text line',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ]
      },
      {
        label: 'Text Size',
        name: 'textSize',
        component: 'select',
        options: [
          { label: 'h1', value: 'text-title-h1' },
          { label: 'h2', value: 'text-title-h2' },
          { label: 'h3', value: 'text-title-h3' },
          { label: 'h4', value: 'text-title-h4' },
          { label: 'h5', value: 'text-title-h5' },
          { label: 'h6', value: 'text-title-h6' },
        ],
      },
      {
        label: 'Read more link',
        name: 'readMore',
        component: 'link',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Full width, full bleed, vertical padding',
        fields: [
          {
            label: 'Background Color',
            name: 'bgColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Text Alignment',
            name: 'textAlign',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              { label: 'Left', value: 'text-left' },
              { label: 'Center', value: 'text-center' },
              { label: 'Right', value: 'text-right' },
            ],
          },
          {
            label: 'Full Screen Height',
            name: 'fullHeight',
            component: 'toggle',
            description: 'Makes section full screen height',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width from contained styles',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Full Bleed',
            name: 'fullBleed',
            component: 'toggle',
            description: 'Removes horizontal padding of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Vertical Padding',
            name: 'verticalPadding',
            component: 'toggle',
            description: 'Adds vertical padding to this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Animation',
            name: 'animation',
            component: 'toggle',
            description: 'Turn on/off animation for this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          bgColor: 'var(--background)',
          fullWidth: true,
          fullBleed: true,
          verticalPadding: true,
        },
      },
    ]
  }
}