import { useEffect } from 'react';

import { useCartItems, useSettings } from '@backpackjs/storefront';

import { CartEmpty } from './CartEmpty';
import { CartHeader } from './CartHeader';
import { CartItem } from './CartItem';
import { CartTotals } from './CartTotals';
import { CartUpsell } from './CartUpsell';
import { CartUpsellRecommendations } from './CartUpsellRecommendations';
import { FreeShippingMeter } from './FreeShippingMeter';
import { useGlobalContext } from '../../contexts';

export function Cart() {
  const cartItems = useCartItems();
  const variantIds = cartItems.map(item => item.variant.product.id.split('/').pop());
  const resultIds = variantIds.join(',');
  
  const settings = useSettings();
  const {
    state: { cartOpen },
    actions: { closeCart },
  } = useGlobalContext();

  const enabledUpsell = settings?.cart?.upsell?.enabled;
  const appEnabledUpsell = settings?.cart?.upsell?.appEnabled;

  useEffect(() => {
    // Event listener for "Escape" key press
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeCart();
      }
    };
    if (cartOpen) {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [cartOpen, closeCart]);

  return (
    <aside
      className={`fixed left-full top-0 z-[70] flex h-full w-full flex-col justify-between overflow-hidden bg-white transition md:max-w-[var(--sidebar-width)] ${
        cartOpen
          ? 'pointer-events-auto -translate-x-full'
          : 'pointer-events-none translate-x-0'
      }`}
      aria-hidden={!cartOpen}
    >
      {cartOpen && (
        <>
          <CartHeader closeCart={closeCart} heading={settings?.cart?.heading} />

          <FreeShippingMeter />

          <ul className="bg-offWhite shadow-hairline scrollbar-hide relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden gap-5 p-5 pb-0">
            {cartItems?.length ? (
              cartItems.map((item) => {
                return (
                  <li
                    key={item.id}
                  >
                    <CartItem item={item} closeCart={closeCart} />
                  </li>
                );
              })
            ) : (
              <CartEmpty closeCart={closeCart} />
            )}

            {enabledUpsell && (
              appEnabledUpsell ? (
                <CartUpsellRecommendations cartItems={resultIds} />
              ) : (
                <CartUpsell closeCart={closeCart} />
              )
            )}
          </ul>
          <CartTotals />
        </>
      )}
    </aside>
  );
}

Cart.displayName = 'Cart';
