import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useRouter } from 'next/router';

const Context = createContext({
  state: {},
  actions: {},
});


const DEFAULT_RESULTS_PER_PAGE = 48;

const searchspringState = {
  // collection
  collection: null,
  currentResultsPage: 1,
  selectedFilters: [],
  selectedResultsPerPage: DEFAULT_RESULTS_PER_PAGE,
  selectedSort: null,
  loadedPages: [1],
  pageInView: 1,
  // search
  searchData: null,
  searchPageData: null,
  searchQuery: null,
  searchPageQuery: null,
  searchTags: [],
  searchResults: [],
  searchSuggestions: [],
  searchPageSuggestions: [],
  searchSpellCorrection: [],
  searchPageSpellCorrection: [],
  searchSuggested: null,
  searchPageSuggested: null,
  searchAlternatives: null,
  searchTrending: null,
  searchSelectedSort: null,
  searchSelectedFilters: [],
  searchSelectedResultsPerPage: DEFAULT_RESULTS_PER_PAGE,
  searchCurrentResultsPage: 1,
  searchLoadedPages: [1],
  searchPageInView: 1,
  searchSuggestionIsAutocomplete: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_COLLECTION':
      return {
        ...state,
        collection: action.payload,
      };
    case 'SET_CURRENT_RESULTS_PAGE':
      return {
        ...state,
        currentResultsPage: action.payload,
      };
    case 'SET_SELECTED_FILTERS':
      return {
        ...state,
        selectedFilters: action.payload,
      };
    case 'SET_SELECTED_RESULTS_PER_PAGE':
      return {
        ...state,
        selectedResultsPerPage: action.payload,
      };
    case 'SET_SELECTED_SORT':
      return {
        ...state,
        selectedSort: action.payload,
      };
    case 'SET_LOADED_PAGES':
      return {
        ...state,
        loadedPages: action.payload,
      };
    case `SET_PAGE_IN_VIEW`:
      return {
        ...state,
        pageInView: action.payload,
      };
    case 'SET_SEARCH_DATA':
      return {
        ...state,
        searchData: action.payload,
      };
    case 'SET_SEARCH_PAGE_DATA':
      return {
        ...state,
        searchPageData: action.payload,
      };
    case 'SET_SEARCH_QUERY':
      return {
        ...state,
        searchQuery: action.payload,
      };
    case 'SET_SEARCH_PAGE_QUERY':
      return {
        ...state,
        searchPageQuery: action.payload,
      };
    case 'SET_SEARCH_TAGS':
      return {
        ...state,
        searchTags: action.payload,
      };
    case 'SET_SEARCH_RESULTS':
      return {
        ...state,
        searchResults: action.payload,
      };
    case 'SET_SEARCH_SUGGESTIONS':
      return {
        ...state,
        searchSuggestions: action.payload,
      };
    case 'SET_SEARCH_PAGE_SUGGESTIONS':
      return {
        ...state,
        searchPageSuggestions: action.payload,
      };
    case 'SET_SEARCH_SPELL_CORRECTION':
      return {
        ...state,
        searchSpellCorrection: action.payload,
      };
    case 'SET_SEARCH_PAGE_SPELL_CORRECTION':
      return {
        ...state,
        searchPageSpellCorrection: action.payload,
      };
    case 'SET_SEARCH_SUGGESTED':
      return {
        ...state,
        searchSuggested: action.payload,
      };
    case 'SET_SEARCH_PAGE_SUGGESTED':
      return {
        ...state,
        searchPageSuggested: action.payload,
      };
    case 'SET_SEARCH_ALTERNATIVES':
      return {
        ...state,
        searchAlternatives: action.payload,
      };
    case 'SET_SEARCH_TRENDING':
      return {
        ...state,
        searchTrending: action.payload,
      };
    case 'SET_SEARCH_SELECTED_SORT':
      return {
        ...state,
        searchSelectedSort: action.payload,
      };
    case 'SET_SEARCH_SELECTED_FILTERS':
      return {
        ...state,
        searchSelectedFilters: action.payload,
      };
    case 'SET_SEARCH_SELECTED_RESULTS_PER_PAGE':
      return {
        ...state,
        searchSelectedResultsPerPage: action.payload,
      };
    case 'SET_SEARCH_CURRENT_RESULTS_PAGE':
      return {
        ...state,
        searchCurrentResultsPage: action.payload,
      };
    case 'SET_SEARCH_LOADED_PAGES':
      return {
        ...state,
        searchLoadedPages: action.payload,
      };
    case 'SET_SEARCH_PAGE_IN_VIEW':
      return {
        ...state,
        searchPageInView: action.payload,
      };
    case 'SET_SEARCH_SUGGESTION_IS_AUTOCOMPLETE':
      return {
        ...state,
        searchSuggestionIsAutocomplete: action.payload,
      };
    default:
      throw new Error(`Invalid Context action of type: ${action.type}`);
  }
};

const actions = (dispatch) => ({
  setCollection: (collection) => {
    dispatch({ type: 'SET_COLLECTION', payload: collection });
  },
  setCurrentResultsPage: (page) => {
    dispatch({ type: 'SET_CURRENT_RESULTS_PAGE', payload: page });
  },
  setSelectedFilters: (filters) => {
    dispatch({ type: 'SET_SELECTED_FILTERS', payload: filters });
  },
  setSelectedResultsPerPage: (resultsPerPage) => {
    dispatch({
      type: 'SET_SELECTED_RESULTS_PER_PAGE',
      payload: resultsPerPage,
    });
  },
  setSelectedSort: (sort) => {
    dispatch({ type: 'SET_SELECTED_SORT', payload: sort });
  },
  setLoadedPages: (pages) => {
    dispatch({ type: 'SET_LOADED_PAGES', payload: pages });
  },
  setPageInView: (page) => {
    dispatch({ type: 'SET_PAGE_IN_VIEW', payload: page });
  },
  setSearchData: (data) => {
    dispatch({ type: 'SET_SEARCH_DATA', payload: data });
  },
  setSearchPageData: (data) => {
    dispatch({ type: 'SET_SEARCH_PAGE_DATA', payload: data });
  },
  setSearchQuery: (query) => {
    dispatch({ type: 'SET_SEARCH_QUERY', payload: query });
  },
  setSearchPageQuery: (query) => {
    dispatch({ type: 'SET_SEARCH_PAGE_QUERY', payload: query });
  },
  setSearchTags: (tags) => {
    dispatch({ type: 'SET_SEARCH_TAGS', payload: tags });
  },
  setSearchResults: (results) => {
    dispatch({ type: 'SET_SEARCH_RESULTS', payload: results });
  },
  setSearchSuggestions: (suggestions) => {
    dispatch({ type: 'SET_SEARCH_SUGGESTIONS', payload: suggestions });
  },
  setSearchPageSuggestions: (suggestions) => {
    dispatch({ type: 'SET_SEARCH_PAGE_SUGGESTIONS', payload: suggestions });
  },
  setSearchSpellCorrection: (correction) => {
    dispatch({ type: 'SET_SEARCH_SPELL_CORRECTION', payload: correction });
  },
  setSearchPageSpellCorrection: (correction) => {
    dispatch({
      type: 'SET_SEARCH_PAGE_SPELL_CORRECTION',
      payload: correction,
    });
  },
  setSearchSuggested: (suggested) => {
    dispatch({ type: 'SET_SEARCH_SUGGESTED', payload: suggested });
  },
  setSearchPageSuggested: (suggested) => {
    dispatch({ type: 'SET_SEARCH_PAGE_SUGGESTED', payload: suggested });
  },
  setSearchAlternatives: (alternatives) => {
    dispatch({ type: 'SET_SEARCH_ALTERNATIVES', payload: alternatives });
  },
  setSearchTrending: (trending) => {
    dispatch({ type: 'SET_SEARCH_TRENDING', payload: trending });
  },
  setSearchSelectedSort: (sort) => {
    dispatch({ type: 'SET_SEARCH_SELECTED_SORT', payload: sort });
  },
  setSearchSelectedFilters: (filters) => {
    dispatch({ type: 'SET_SEARCH_SELECTED_FILTERS', payload: filters });
  },
  setSearchSelectedResultsPerPage: (resultsPerPage) => {
    dispatch({
      type: 'SET_SEARCH_SELECTED_RESULTS_PER_PAGE',
      payload: resultsPerPage,
    });
  },
  setSearchCurrentResultsPage: (page) => {
    dispatch({ type: 'SET_SEARCH_CURRENT_RESULTS_PAGE', payload: page });
  },
  setSearchLoadedPages: (pages) => {
    dispatch({ type: 'SET_SEARCH_LOADED_PAGES', payload: pages });
  },
  setSearchPageInView: (page) => {
    dispatch({ type: 'SET_SEARCH_PAGE_IN_VIEW', payload: page });
  },
  setSearchSuggestionIsAutocomplete: (isAutocomplete) => {
    dispatch({
      type: 'SET_SEARCH_SUGGESTION_IS_AUTOCOMPLETE',
      payload: isAutocomplete,
    });
  },
});

export function SearchspringContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, { ...searchspringState });
  const router = useRouter();
  const asPath = router.asPath.split('?')[0];

  const value = useMemo(() => ({ state, actions: actions(dispatch) }), [state]);

  // clear collection state on collection unmount
  useEffect(() => {
    if (!asPath.startsWith('/collections')) return undefined;
    return () => {
      actions(dispatch).setCollection(null);
      actions(dispatch).setCurrentResultsPage(1);
      actions(dispatch).setSelectedFilters([]);
      actions(dispatch).setSelectedResultsPerPage(DEFAULT_RESULTS_PER_PAGE);
      actions(dispatch).setSelectedSort(null);
      actions(dispatch).setLoadedPages([1]);
      actions(dispatch).setPageInView(false);
    };
  }, [asPath]);

  // clear search page state on search page unmount
  useEffect(() => {
    if (!asPath.startsWith('/search') && !asPath.startsWith('/pages/search'))
      return undefined;
    return () => {
      actions(dispatch).setSearchData(null);
      actions(dispatch).setSearchPageData(null);
      actions(dispatch).setSearchQuery(null);
      actions(dispatch).setSearchPageQuery(null);
      actions(dispatch).setSearchTags([]);
      actions(dispatch).setSearchPageSuggestions([]);
      actions(dispatch).setSearchPageSpellCorrection([]);
      actions(dispatch).setSearchPageSuggested(null);
      actions(dispatch).setSearchSelectedSort(null);
      actions(dispatch).setSearchSelectedFilters(null);
      actions(dispatch).setSearchSelectedResultsPerPage(
        DEFAULT_RESULTS_PER_PAGE
      );
      actions(dispatch).setSearchCurrentResultsPage(1);
      actions(dispatch).setSearchLoadedPages([1]);
      actions(dispatch).setSearchPageInView(false);
    };
  }, [asPath]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const useSearchspringContext = () => useContext(Context);