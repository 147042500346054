import { useState } from 'react';

import { CollectionPackFilterOption } from './CollectionPackFilterOption';
import { Svg } from '../../../Svg';

export function CollectionPackDropdownFilter({
  activeFilters,
  addFilter,
  defaultOpen,
  overrideOptionsMaxCount,
  filter,
  optionsMaxCount,
  removeFilter,
  showCount,
  swatchesMap,
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [maxOptions, setMaxOptions] = useState(
    overrideOptionsMaxCount ? filter.values.length : optionsMaxCount || filter.values.length
  );
  const totalSelectedOptions = activeFilters[filter.name]?.length || 0;

  return (
    <div className="shadow-hairline-b">
      <button
        aria-label={filter.label}
        className={`relative h-14 flex w-full items-center justify-between gap-4 text-left p-5 ${isOpen ? 'shadow-hairline-b' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <div className="flex flex-1 items-center">
          <h3 className="text-base">{filter.label}</h3>

          {totalSelectedOptions > 0 && (
            <p className="ml-1 text-xs text-mediumGray">
              ({totalSelectedOptions})
            </p>
          )}
        </div>

        <Svg
          className={`w-4 text-text ${isOpen ? 'rotate-180' : ''}`}
          src="/svgs/chevron-down.svg#chevron-down"
          alt={`${isOpen ? 'Close' : 'Open'} Filter Icon`}
          title="Chevron"
          viewBox="0 0 24 24"
        />
      </button>

      <div
        className={`bg-offWhite flex-col p-5 overflow-y-auto scrollbar-hide md:items-start md:max-h-72 md:gap-2 ${
          isOpen ? 'flex' : 'hidden'
        }`}
      >
        <ul className="flex flex-col gap-2 md:items-start">
          {filter.values.slice(0, maxOptions).map(({ count, value }, index) => {
            return (
              <li
                key={index}
              >
                <CollectionPackFilterOption
                  activeFilters={activeFilters}
                  addFilter={addFilter}
                  count={count}
                  isColor={filter.isColor}
                  name={filter.name}
                  removeFilter={removeFilter}
                  showCount={showCount}
                  swatchesMap={swatchesMap}
                  value={value}
                />
              </li>
            );
          })}
        </ul>

        {maxOptions < filter.values.length && (
          <button
            type="button"
            className="h-6 text-left text-sm font-bold uppercase max-md:h-11 max-md:px-4 md:text-xs"
            aria-label="Show all options"
            onClick={() => setMaxOptions(filter.values.length)}
          >
            + {filter.values.length - maxOptions} More
          </button>
        )}
      </div>
    </div>
  );
}

CollectionPackDropdownFilter.displayName = 'CollectionPackDropdownFilter';
