import { useCallback } from 'react';

import { useSearchspring } from './';
import { useSearchspringContext } from '../../contexts';

export const useSearchspringSearchTrending = () => {
  const { requestSearchspring } = useSearchspring();
  const {
    state: { searchTrending },
    actions: { setSearchTrending },
  } = useSearchspringContext();

  // Get trending search suggestions from past 90 days from ss
  const getSearchTrending = useCallback(async () => {
    try {
      const data = await requestSearchspring({
        action: 'getSearchTrending',
      });

      setSearchTrending(
        data?.trending?.queries?.length
          ? data.trending.queries.map(({ searchQuery }) => searchQuery)
          : []
      );
    } catch (error) {
      console.error(`Error with getSearchTrending: ${error.message}`);
      throw error;
    }
  }, []);

  return {
    state: { searchTrending },
    actions: { getSearchTrending },
  };
};