import { useCallback } from 'react';

import { useSearchspringSearchParameters, useSearchspringSearchPageResults, useSearchspringSearchSort, useSearchspringSearchFilters, useSearchspringSearchPagination } from '../../../hooks';

export default function useSearch() {

  const { searchPageParameters } = useSearchspringSearchParameters()[1];
  
  // Searchspring search results sort
  const [{ selectedSort, sortOptions }, { loadSelectedSort, sortCollection }] = useSearchspringSearchSort();

  // Searchspring search results filters
  const [
    { selectedFilters, filters, filterSummary },
    { addToSelectedFilters, removeFromSelectedFilters, clearSelectedFilters, loadSelectedFilters }
  ] = useSearchspringSearchFilters();

  // Searchspring search results pagination
  const [
    { resultsPerPage, currentPage, totalPages, searchLoadedPages },
    { goToPrevPage, goToNextPage, goToPage, updatePageURLInView }
  ] = useSearchspringSearchPagination();

  // Searchspring search results
  const { pageResults, totalPageResults, merchandising } = useSearchspringSearchPageResults();

  function withErrorHandling(callback) {
    return async (...args) => {
      try {
        await callback(...args);
      } catch (error) {
        console.error(`Error in ${callback.name} function`, error);
        // Handle the error (e.g., set an error state, retry the operation, etc.)
      }
    };
  }

  const setParameters = useCallback(withErrorHandling(async (query) => {
    const { query: searchQuery } = query;
    await searchPageParameters({ query: searchQuery });
  }), [searchPageParameters]);

  const loadSort = useCallback(withErrorHandling(async (sortFromParams) => {
    loadSelectedSort(sortFromParams);
  }), [loadSelectedSort]);

  const selectSort = useCallback(withErrorHandling(async (field, direction) => {
    await sortCollection({ field, direction });
  }), [sortCollection]);

  const loadFilters = useCallback(withErrorHandling(async (filtersFromParams) => {
    loadSelectedFilters(filtersFromParams);
  }), [loadSelectedFilters]);

  const addToFilters = useCallback(withErrorHandling(async (field, option) => {
    addToSelectedFilters(field, option);
  }), [addToSelectedFilters]);

  const removeFromFilters = useCallback(withErrorHandling(async (field, option) => {
    removeFromSelectedFilters(field, option);
  }), [removeFromSelectedFilters]);

  const clearFilters = useCallback(withErrorHandling(async () => {
    clearSelectedFilters();
  }), [clearSelectedFilters]);

  const goToPreviousPage = useCallback(withErrorHandling(async () => {
    goToPrevPage();
  }), [goToPrevPage]);

  const goToFollowingPage = useCallback(withErrorHandling(async () => {
    goToNextPage();
  }), [goToNextPage]);

  const goToSpecificPage = useCallback(withErrorHandling(async (num) => {
    goToPage(num);
  }), [goToPage]);

  const updatePageInView = useCallback(withErrorHandling(async (num) => {
    updatePageURLInView(num);
  }), [updatePageURLInView]);

  return {
    setParameters,
    pageResults,
    totalProducts: totalPageResults,
    merchandising,
    selectedSort,
    sortOptions,
    loadSort,
    selectSort,
    selectedFilters,
    filters,
    filterSummary,
    loadFilters,
    addToFilters,
    removeFromFilters,
    clearFilters,
    selectedResultsPerPage: resultsPerPage,
    currentResultsPage: currentPage,
    totalPages,
    loadedPages: searchLoadedPages,
    goToPreviousPage,
    goToFollowingPage,
    goToSpecificPage,
    updatePageInView,
  };
}