import { COLORS, OBJECT_POSITIONS } from '../../settings/common';

export function Schema() {
  return {
    category: 'Media',
    label: 'Tiles',
    key: 'tiles',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/three-tiles-preview.jpg?v=1675730352',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Tiles Heading',
      },
      {
        label: 'Hide Heading',
        name: 'hideHeading',
        component: 'toggle',
        description: 'Hide heading, toggle this off to mantain heading for accessibility',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
      },
      {
        label: 'Tiles',
        name: 'tiles',
        description: 'Max of 9 tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        validate: {
          maxItems: 9,
        },
        fields: [
          {
            label: 'Image Alt',
            name: 'alt',
            component: 'text',
            description: 'Brief description of image',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Image Position',
            name: 'position',
            component: 'select',
            options: OBJECT_POSITIONS.mobile,
          },
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Description',
            name: 'description',
            component: 'textarea',
          },
          {
            label: 'Icons',
            name: 'icons',
            component: 'group-list',
            itemProps: {
              label: '{{item.iconText}}',
            },
            fields: [
              {
                label: 'Icon',
                name: 'icon',
                component: 'select',
                options: [
                  { label: 'Four-way stretch', value: 'Four-way-stretch' },
                  { label: 'Lightweight fabric', value: 'Lightweight-wicking-fabric' },
                  { label: 'Durable wicking fabric', value: 'Durable-wicking-fabric'},
                  { label: 'Wrinkle free', value: 'Wrinkle-free' },
                  { label: 'Stretch waist', value: 'Stretch-waist-with-loops' },
                  { label: 'Ultra lightweight', value: 'Ultra-lightweight' },
                  { label: 'Elastic waistband', value: 'Elastic-Waistband' },
                  { label: 'Buit to transition', value: 'Buit-to-transition' },
                  { label: 'Built for activity', value: 'Built-for-activity' },
                  { label: 'Versatile', value: 'Versatile' },
                  { label: 'Odor resistant', value: 'Odor-resistant' },
                  { label: 'Athletic fit', value: 'Athletic-fit' },
                  { label: 'Moisture wicking', value: 'Moisture-wicking' },
                  { label: 'Breathable', value: 'Breathable' },
                  { label: 'Incredibly Soft', value: 'Incredibly-soft' },
                  { label: 'Kangaroo Pouch', value: 'Kangaroo-pouch' },
                ],
              },
              {
                label: 'Label',
                name: 'label',
                component: 'text',
              },
            ],
            defaultValue: [
              { icon: 'shipping', label: 'Free Shipping' },
              { icon: 'customer-service', label: 'Top Customer Service' },
              { icon: 'warranty', label: 'Lifetime Warranty' },
              { icon: 'innovation', label: 'Innovative Designs' },
            ],
            defaultItem: { icon: 'shipping', label: 'New Icon' },
          },
          {
            label: 'Show Arrow Icon',
            name: 'showArrow',
            component: 'toggle',
            description: 'Hide or show arrow icon',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: true,
          },
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Style',
            name: 'style',
            component: 'select',
            options: [
              { label: 'Primary', value: 'primary' },
              { label: 'Secondary', value: 'secondary' },
            ],
            defaultValue: 'primary',
          },
          {
            label: 'Hide on Mobile',
            name: 'hideMobile',
            component: 'toggle',
            description: 'Hide this tile on mobile devices',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: false,
          }
        ],
        defaultItem: {
          alt: 'Man in white and light tan outfit',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-in-white-and-light-tan-outfit.jpg?v=1672348139',
          },
          position: 'object-center',
          heading: 'Headline',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          link: {
            text: '',
            url: '',
          },
        },
        defaultValue: [
          {
            alt: 'Man in white and light tan outfit',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-in-white-and-light-tan-outfit.jpg?v=1672348139',
            },
            position: 'object-center',
            heading: 'Headline',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          },
          {
            alt: 'Man in brown coat sitting down',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/austin-wade-d2s8NQ6WD24-unsplash.jpg?v=1672348122',
            },
            position: 'object-center',
            heading: 'Headline',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          },
          {
            alt: 'Man in gray sweater and tan coat',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-poses-in-light-colored-overcoat.jpg?v=1672348143',
            },
            position: 'object-center',
            heading: 'Headline',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          },
        ],
      },
      {
        label: 'Items Per Row',
        name: 'itemsPerRow',
        component: 'select',
        description: 'Number of items per row',
        options: [
          { label: '2', value: 'grid-cols-2' },
          { label: '3', value: 'grid-cols-3' },
          { label: '4', value: 'grid-cols-4' },
          { label: '5', value: 'grid-cols-5' },
          { label: '6', value: 'grid-cols-6' },
          { label: '7', value: 'grid-cols-7' },
          { label: '8', value: 'grid-cols-8' },
          { label: '9', value: 'grid-cols-9' },
        ],
        defaultValue: 'grid-cols-3',
      },
      {
        label: 'Footer Button',
        name: 'button',
        component: 'link',
      },
      {
        label: 'Slider Settings',
        name: 'slider',
        component: 'group',
        description: 'Slider style, slides per view',
        fields: [
          {
            label: 'Slider Style',
            name: 'sliderStyle',
            component: 'select',
            description:
              'Loop and centered settings only apply if the number of products is at least twice the number of slides per view',
            options: [
              { label: 'Contained', value: 'contained' },
              { label: 'Contained w/ Loop', value: 'containedWithLoop' },
              { label: 'Full Bleed, Centered w/ Loop', value: 'fullBleed' },
              {
                label: 'Full Bleed, Centered w/ Loop & Gradient',
                value: 'fullBleedWithGradient',
              },
            ],
          },
          {
            label: 'Slides Per View (tablet)',
            name: 'slidesPerViewTablet',
            component: 'number',
            description: 'Tip: use decimals to show partial slides',
          },
          {
            label: 'Slides Per View (mobile)',
            name: 'slidesPerViewMobile',
            component: 'number',
            description: 'Tip: use decimals to show partial slides',
          },
          {
            label: 'Slider Navigation (mobile)',
            name: 'sliderNavigationMobile',
            component: 'select',
            options: [
              { label: 'None', value: 'none' },
              { label: 'Arrows', value: 'arrows' },
              { label: 'Dots', value: 'dots' },
              { label: 'Both', value: 'both' },
            ],
          },
        ],
        defaultValue: {
          sliderStyle: 'contained',
          slidesPerViewTablet: 3.4,
          slidesPerViewMobile: 1.4,
          sliderNavigationMobile: 'both',
        },
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description:
          'Image aspect ratio, background color, text color, full width',
        fields: [
          {
            label: 'Image Aspect Ratio',
            name: 'aspectRatio',
            component: 'select',
            options: [
              { label: '3:2', value: 'aspect-[3/2]' },
              { label: '4:3', value: 'aspect-[4/3]' },
              { label: '5:4', value: 'aspect-[5/4]' },
              { label: '8:7', value: 'aspect-[8/7]' },
              { label: '1:1', value: 'aspect-[1/1]' },
              { label: '7:8', value: 'aspect-[7/8]' },
              { label: '4:5', value: 'aspect-[4/5]' },
              { label: '3:4', value: 'aspect-[3/4]' },
              { label: '2:3', value: 'aspect-[2/3]' },
            ],
          },
          {
            label: 'Background Color',
            name: 'bgColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Text Color',
            name: 'textColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Icon Color',
            name: 'iconColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Button Style',
            name: 'buttonStyle',
            component: 'select',
            options: [
              { label: 'Primary', value: 'btn-primary' },
              { label: 'Secondary', value: 'btn-secondary' },
              { label: 'Inverse Light', value: 'btn-inverse-light' },
              { label: 'Inverse Dark', value: 'btn-inverse-dark' },
            ],
          },
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Spacing',
            name: 'spacing',
            component: 'select',
            description: 'Addjust space around this section',
            options: [
              { label: 'Vertical and horizontal padding', value: 'px-contained py-contained' },
              { label: 'Only vertical padding', value: 'py-contained' },
              { label: 'Only horizontal padding', value: 'px-contained' },
              { label: 'Remove top padding', value: 'px-contained py-contained !pt-0' },
              { label: 'Remove bottom padding', value: 'px-contained py-contained !pb-0' },
            ],
            defaultValue: 'px-contained py-contained',
          },
        ],
        defaultValue: {
          aspectRatio: 'aspect-[3/4]',
          bgColor: 'var(--background)',
          textColor: 'var(--text)',
          buttonStyle: 'btn-primary',
          fullWidth: false,
        },
      },
    ],
  };
}
