import { useSettings } from '@backpackjs/storefront';

import { CollectionSearchspringMobileFilters } from '../CollectionSearchspringFilters/CollectionSearchspringMobileFilters';
import { CollectionSearchspringFiltersSummary } from '../CollectionSearchspringFilters/CollectionSearchspringFiltersSummary';

import { Svg } from '../../../Svg';

export function CollectionSearchspringFilter({
  isOpen,
  setIsOpen,
  areOpen,
  setAreOpen,
  openFilter,
  setOpenFilter,
  filters,
  filterSummary,
  addToSelectedFilters,
  removeFromSelectedFilters,
  clearSelectedFilters,
  swatchesMap,
}) {
  const settings = useSettings();
  const { optionsMaxCount, showCount } = {
    ...settings?.collection?.filters,
  };

  return (
    <div className="flex w-full max-lg:items-baseline">
      <div className="hidden w-auto lg:inline-block">
        <div className="relative w-full">
          <button
            aria-label={`${ areOpen ? 'Open' : 'Close'} filter menu`}
            className={`shadow-hairline flex w-full items-center justify-between gap-4 bg-offWhite p-3 text-base hover:bg-white ${
              areOpen ? '' : 'bg-white'
            }`}
            onClick={() => setAreOpen(!areOpen)}
            type="button"
          >
            <Svg
              className="text-text"
              src="/svgs/filter.svg#filter"
              alt="Filter Icon"
              title="Filter"
              viewBox="0 0 24 24"
              width="24px"
              height="24px"
            />
            <p className="font-medium text-text">Filter</p>
          </button>
        </div>
      </div>
      <div className="lg:hidden">
        <CollectionSearchspringMobileFilters
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          filters={filters}
          filterSummary={filterSummary}
          addToSelectedFilters={addToSelectedFilters}
          removeFromSelectedFilters={removeFromSelectedFilters}
          clearSelectedFilters={clearSelectedFilters}
          optionsMaxCount={optionsMaxCount}
          showCount={showCount}
          swatchesMap={swatchesMap}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </div>
      <div className="relative top-[4px] mr-auto hidden w-full md:block">
        <CollectionSearchspringFiltersSummary
          filterSummary={filterSummary}
          removeFromSelectedFilters={removeFromSelectedFilters}
          clearSelectedFilters={clearSelectedFilters}
        />
      </div>
    </div>
  );
}

CollectionSearchspringFilter.displayName = 'CollectionSearchspringFilter';