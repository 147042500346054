import { Fragment } from 'react';
import { Image, Link, Svg } from '../../snippets';
import { ImageTilesVideo } from './ImageTilesVideo';

export function ImageTile({ aspectRatio, content, item, isMobileViewport }) {
  const firstLink = item.buttons?.[0]?.link;
  const secondLink = item.buttons?.[1]?.link;

  const svgWidth = isMobileViewport ? item?.svg?.svgMobileWidth : item?.svg?.svgDesktopWidth;
  const svgHeight = isMobileViewport ? item?.svg?.svgMobileHeight : item?.svg?.svgDesktopHeight;

  return (
    <Link
      aria-label={
        firstLink?.newTab
          ? `Open in a new tab to view ${firstLink?.text}`
          : firstLink?.text
      }
      className="w-full"
      href={content?.clickableImage ? firstLink?.url : ''}
      newTab={firstLink?.newTab}
      type={firstLink?.type}
    >
      <div className={`relative bg-offWhite ${aspectRatio}`}>
        {item.image?.src && (
          <Image
            alt={item.alt}
            className={`${item.position}`}
            fill
            sizes="(min-width: 1024px) 30vw, (min-width: 768px) 50vw, 100vw"
            src={item.image.src}
          />
        )}

        {item?.video?.src && (
          <ImageTilesVideo
            autoplay={item?.video.autoplay}
            posterSrc={item?.video.poster?.src}
            sound={item?.video.sound}
            videoAlt={item?.videoAlt}
            videoSrc={item?.video.src}
          />
        )}
      </div>

      <div
        className={`pointer-events-none absolute inset-0 flex h-full w-full p-2.5 text-center xl:p-5 ${
          content?.darkOverlay ? 'bg-[rgba(0,0,0,0.2)]' : ''
        } ${content?.contentPosition}`}
      >
        <div className="pointer-events-auto flex flex-col gap-3 lg:gap-4">
          <div>
            {item?.superheading && (
              <p className={`text-superheading text-white ${content?.textShadow && '[text-shadow:_2px_2px_2px_rgb(0_0_0_/_1)]'}`}>{item?.superheading}</p>
            )}

            {item?.heading && (
              <h3 className={`${content?.headingStyle == 'big' ? 'text-5xl md:text-8xl uppercase' : 'text-lg uppercase xl:text-2xl' } text-white ${content?.textShadow && '[text-shadow:_2px_2px_2px_rgb(0_0_0_/_1)]'} ${item?.svg?.svgName ? 'sr-only' : ''}`}>{item.heading}</h3>
            )}

            {item?.svg?.svgName && (
              <Svg
                src={`/svgs/noprecache/content/${item?.svg?.svgName}.svg#${item?.svg?.svgName}`}
                alt={`${item?.svg?.svgAlt} Icon`}
                title={`${item?.svg?.svgAlt}`}
                style={{ width: `${svgWidth}px`, height: `${svgHeight}px` }}
              />
            )}
          </div>

          {!content?.hideButtons && (firstLink?.text || secondLink?.text) && (
            <div className="flex flex-wrap justify-center gap-3">
              {item.buttons.slice(0, 2).map(({ link }, index) => {
                // hide second button if clickable image is enabled
                if (content?.clickableImage && index > 0) return null;

                return link?.text ? (
                  <Fragment key={index}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                      aria-label={
                        link?.newTab 
                          ? `Open in a new tab to view ${link?.text}` 
                          : link?.text
                      }
                      className={`${
                        index === 1
                          ? content?.secondaryButtonStyle
                          : content?.primaryButtonStyle
                      }`}
                      href={!content?.clickableImage ? link.url : ''}
                      newTab={link.newTab}
                      type={link.type}
                    >
                      {link.text}
                    </Link>
                  </Fragment>
                ) : null;
              })}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}

ImageTile.displayName = 'ImageTile';
