import Script from 'next/script';

import {
  useDataLayerAccount,
  useDataLayerCart,
  useDataLayerCollection,
  useDataLayerCustomer,
  useDataLayerInit,
  useDataLayerProduct,
  useDataLayerSearch,
  useDataLayerSubscribe,
} from './hooks';

// Debug via Elevar's Data Layer Listener, add to the console:
// Turn on: window.ElevarGtmSuiteListener.utils.debug(true)
// Turn off: window.ElevarGtmSuiteListener.utils.debug(false)

// Envs to set:
// * NEXT_PUBLIC_SITE_TITLE // provides backup brand name

const DEBUG = false; // local debugging (logs whether passed or failed with elevar)

export function DataLayerWithElevar() {
  const { generateUserProperties, userProperties } = useDataLayerInit({
    DEBUG,
  });

  const { userDataEvent, userDataEventTriggered } = useDataLayerCustomer({
    DEBUG,
    userProperties,
  });

  useDataLayerAccount({
    DEBUG,
    generateUserProperties,
    userDataEvent,
    userDataEventTriggered,
  });

  useDataLayerCart({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerProduct({
    DEBUG,
    userDataEvent,
    userProperties,
  });

  useDataLayerCollection({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSearch({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSubscribe({
    DEBUG,
    userDataEventTriggered,
  });

  const loadScript = `
    (async function() {
      try {
        const response = await fetch("https://shopify-gtm-suite.getelevar.com/configs/73569655f1fc1690cc74888ecec8943d4e42ecce/config.json");
        const config = await response.json();
        const scriptUrl = config.script_src_custom_pages;

        if (scriptUrl) {
          const module = await import(scriptUrl);
          const handler = module.default || module.handler;
          await handler(config);
        }
      } catch (error) {
        console.error("Elevar Error:", error);
      }
    })();`;

  return (
    <Script type="module" id="elevar-script">
      {loadScript}
    </Script>
  );
}

DataLayerWithElevar.displayName = 'DataLayerWithElevar';
