import { useMemo } from 'react';

import { useMatchMedia } from '../../hooks';

import { Link, Markdown, Svg } from '../../snippets';

export function HalfHeroContent({ aboveTheFold, content }) {
  const {
    alignmentDesktop,
    alignmentMobile,
    buttons,
    color,
    heading,
    headingStyle,
    maxWidthDesktop,
    subtext,
    superheading,
    svg,
  } = { ...content };
  const isMobileViewport = useMatchMedia('(max-width: 1023px)');

  const alignmentClasses = `${alignmentMobile} ${alignmentDesktop}`;

  const headingWithBreaks = useMemo(() => {
    const splitHeading = heading?.split('\n');
    if (splitHeading?.length === 1) return heading;
    return splitHeading?.reduce((acc, line, index, arr) => {
      acc.push(<span key={index}>{line}</span>);
      if (index < arr.length - 1) acc.push(<br key={`br${index}`} />);
      return acc;
    }, []);
  }, [heading]);

  const svgWidth = isMobileViewport ? svg?.svgMobileWidth : svg?.svgDesktopWidth;
  const svgHeight = isMobileViewport ? svg?.svgMobileHeight : svg?.svgDesktopHeight;

  return (
    <div className="px-contained py-contained w-full">
      <div
        className={`mx-auto flex flex-col gap-5 ${alignmentClasses} ${maxWidthDesktop}`}
        style={{ color }}
      >
        <div>
          {superheading && (
            <p className="text-superheading mb-1 lg:mb-1.5">{superheading}</p>
          )}

          {aboveTheFold ? (
            <h1 className={`${headingStyle == 'big' ? 'text-5xl md:text-8xl uppercase' : 'text-title-h1' } ${svg?.svgName && 'sr-only'}`}>{headingWithBreaks}</h1>
          ) : (
            <h2 className={`${headingStyle == 'big' ? 'text-5xl md:text-8xl uppercase' : 'text-title-h2' } ${svg?.svgName && 'sr-only'}`}>{headingWithBreaks}</h2>
          )}
          
          {svg?.svgName && (
            <Svg
              src={`/svgs/noprecache/content/${svg?.svgName}.svg#${svg?.svgName}`}
              alt={`${svg?.svgAlt} Icon`}
              title={`${svg?.svgAlt}`}
              style={{ width: `${svgWidth}px`, height: `${svgHeight}px` }}
            />
          )}
        </div>

        {subtext && (
          <div className="[&_a]:underline [&_h1]:text-base [&_h2]:text-base [&_h3]:text-base [&_h4]:text-base [&_h5]:text-base [&_h6]:text-base [&_p]:text-base">
            <Markdown>{subtext}</Markdown>
          </div>
        )}

        {buttons?.length > 0 && (
          <ul className="flex flex-col justify-center gap-4 xs:flex-row">
            {buttons.slice(0, 2).map(({ link, style }, index) => {
              return (
                <li key={index}>
                  <Link
                    aria-label={
                      link?.newTab 
                        ? `Open in a new tab to view ${link?.text}` 
                        : link?.text
                    }
                    className={style}
                    href={link?.url}
                    newTab={link?.newTab}
                    type={link?.type}
                  >
                    {link?.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

HalfHeroContent.displayName = 'HalfHeroContent';
