import { useEffect } from 'react';
import { A11y, EffectFade, Autoplay, Navigation, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSettings } from '@backpackjs/storefront';

import { Link } from '../Link';

export function Promobar({
  promobarDisabled,
  promobarHidden,
  setPromobarHidden,
}) {
  const settings = useSettings();
  const { promobar } = { ...settings?.header };
  const { autohide, bgColor, color, delay, effect, enabled, messages, speed } =
    {
      ...promobar,
    };

  const swiperParams = {
    modules: [A11y, Autoplay, EffectFade, Navigation, Keyboard],
    a11y: { enabled: true },
    autoplay: {
      delay: delay || 5000,
      disableOnInteraction: false,
    },
    direction: effect === 'slide-vertical' ? 'vertical' : 'horizontal',
    effect: effect?.split('-')[0] || 'fade',
    fadeEffect: {
      crossFade: true,
    },
    loop: messages?.length > 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    speed: speed || 500,
    style: {
      '--swiper-navigation-color': color,
      '--swiper-navigation-size': '12px',
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    }
  };

  useEffect(() => {
    const setPromobarVisibility = () => {
      if (document.body.style.position === 'fixed') return;
      setPromobarHidden(window.scrollY > 48);
    };

    if (!autohide) {
      setPromobarHidden(false);
      window.removeEventListener('scroll', setPromobarVisibility);
      return undefined;
    }

    window.addEventListener('scroll', setPromobarVisibility);
    return () => {
      window.removeEventListener('scroll', setPromobarVisibility);
    };
  }, [autohide]);

  return (
    <div
      className={`overflow-hidden transition-[height] ease-out ${
        promobarHidden || promobarDisabled
          ? 'h-0 duration-[50ms]'
          : 'min-h-[var(--promobar-height)] duration-300'
      }`}
      style={{ backgroundColor: bgColor }}
    >
      {enabled && messages?.length ? (
        <Swiper
          {...swiperParams}
          className="swiper-wrapper-center relative flex h-full items-center"
          role="region"
          aria-label="Promotional Bar Slider"
          tabIndex="0"
        >
          {messages.map(({ message, link }, index) => {
            return (
              <SwiperSlide key={index} className="px-4">
                <div
                  className="px-2.5 xl:px-10 flex min-h-full items-center justify-center text-center text-xs font-normal uppercase tracking-[0.04em] sm:text-sm"
                  style={{ color }}
                >
                  <Link
                    aria-label={
                      link.newTab
                        ? `Open in a new tab to view ${message}`
                        : message
                    }
                    className="select-none"
                    draggable={false}
                    href={link.url}
                    newTab={link.newTab}
                  >
                    {message}
                  </Link>
                </div>
              </SwiperSlide>
            );
          })}

          <button
            className="swiper-button-prev !left-5"
            aria-label="Previous slide"
            type="button"
          />

          <button
            className="swiper-button-next !right-5"
            aria-label="Next slide"
            type="button"
          />
        </Swiper>
      ) : null}
    </div>
  );
}

Promobar.displayName = 'Promobar';
