import { useCallback } from 'react';
import {
  useLocalizedVariant,
  useProductByHandle,
} from '@backpackjs/storefront';

import { Image } from '../../Image';
import { Link } from '../../Link';
import { useDataLayerActions, useVariantPrices } from '../../../hooks';

export function SearchItem({ closeSearch, index, item }) {
  const { product } = useProductByHandle({ handle: item.handle });
  const firstVariant = product?.variants?.[0];
  const { price, compareAtPrice } = useVariantPrices({
    variant: firstVariant,
  });
  const { sendClickProductItemEvent } = useDataLayerActions();
  const { localized } = useLocalizedVariant({ variant: firstVariant });

  const handleClick = useCallback(() => {
    sendClickProductItemEvent({
      isSearchResult: true,
      listIndex: index,
      localized,
      product,
      selectedVariant: firstVariant,
    });
    closeSearch();
  }, [index, localized, product?.id]);

  const url = `/products/${item.handle}`;
  const color = item.optionsMap?.Color?.[0];
  const image = item.featuredImage;
  const title = item.title.split(' | ')[0];

  return (
    <Link
      aria-label={`View ${title}`}
      className="relative bg-white grid grid-cols-[auto_1fr]"
      href={url}
      onClick={handleClick}
    >
      <div
        className="h-full"
        style={{
          aspectRatio: image
            ? 4 / 5
            : 'var(--product-image-aspect-ratio)',
        }}
      >
        {image?.src && (
          <Image
            alt={title}
            className="bg-offWhite object-cover h-full"
            height={Math.floor(104 / (4 / 5))}
            src={image.src}
            width="104"
          />
        )}
      </div>

      <div className="flex flex-col justify-center gap-[5px] p-2.5">
        <div className="flex items-baseline">
          <h4 className="w-2/3 text-body">{title}</h4>
          <div className="w-1/3 flex flex-wrap items-baseline gap-x-1.5 ml-auto">
            {compareAtPrice ? (
              <>
                <p className="text-xs text-mediumGray line-through">
                  {compareAtPrice}
                </p>
                <p className="min-h-[1.25rem] text-sm text-secondary">{price}</p>
              </>
            ) : (
              <p className="min-h-[1.25rem]">{price}</p>              
            )}
          </div>
        </div>
        {color && (
          <p className="text-sm">{color.split('|').length > 1 ? color.split('|')[1].trim() : color}</p>
        )}
      </div>
    </Link>
  );
}

SearchItem.displayName = 'SearchItem';
