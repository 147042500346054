import { Svg } from '../../../Svg';

export function CollectionSearchspringFiltersSummary({
  filterSummary,
  removeFromSelectedFilters,
  clearSelectedFilters,
  hideClearButton,
}) {
  return filterSummary?.length ? (
    <div className="relative w-full flex">
      <ul className="w-full flex gap-2.5 max-md:flex-wrap p-px md:w-auto md:min-w-auto md:max-w-[calc(100%-9rem)] md:overflow-x-auto md:scrollbar-hide md:border-x-[0.625rem] md:border-solid md:border-white md:mx-px">
        {filterSummary.map(({ field, value, label, filterLabel, filterValue }, index) => {
          let isRange = typeof value === 'object';
          let option;
          option = isRange
            ? { type: 'range', low: value.rangeLow, high: value.rangeHigh }
            : { type: 'value', value: value };
          return (
            <li key={index} className="max-w-full">
              <button
                className="flex max-w-full items-center shadow-hairline bg-offWhite py-1 px-1.5 text-xs transition md:hover:bg-lightGray"
                onClick={() => {
                  removeFromSelectedFilters({ field, option });
                  const clearFilterType = document.querySelector(`[clear-filter-type="${field}-${option?.value}"]`);
                  if (clearFilterType) clearFilterType.click();
                }}
                type="button"
                aria-label={`Remove ${label} from filters`}
              >
                <div className="flex-1 truncate">
                  <span className="font-bold">{filterLabel}:</span>{' '}
                  {filterValue}
                </div>

                <Svg
                  className="ml-1 w-2.5 text-text"
                  src="/svgs/close.svg#close"
                  alt="Close Icon"
                  title="Close"
                  viewBox="0 0 24 24"
                />
              </button>
            </li>
          );
        })}
      </ul>
      {!hideClearButton && (
        <button
          aria-label="Clear all filters"
          className="sticky right-0 h-auto max-w-full min-w-[3rem] text-xs text-main-underline"
          onClick={() => {
            clearSelectedFilters();
            const clearFilterElement = document.querySelector('[clear-filter-icons="true"]');
            if (clearFilterElement) clearFilterElement.click();
          }}
          type="button"
        >
          Clear All
        </button>
      )}
    </div>
  ) : null;
}

CollectionSearchspringFiltersSummary.displayName = 'CollectionSearchspringFiltersSummary';
