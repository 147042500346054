import { useMemo } from 'react';
import { Widget } from '@typeform/embed-react'

export function ContentPopUp({
  content,
}) {
  const contentMarkup = useMemo(() => {
    return (
      <div className="w-full h-full">
        {content?.heading && <h2 className="text-title-h3 text-center">{content.heading}</h2>}
        {content?.text && <p>{content.text}</p>}
        {content?.html && (
          <div
            className="mx-auto flex flex-col [&>:first-child]:mt-0 [&>:last-child]:mb-0 [&_a]:underline [&_h1]:mb-6 [&_h2]:mt-8 [&_h2]:mb-5 [&_h3]:mb-4 [&_h3]:mt-6 [&_h4]:mb-4 [&_h4]:mt-4 [&_h5]:mb-4 [&_h5]:mt-2 [&_h6]:mb-4 [&_li>p]:mb-0 [&_li]:mb-2 [&_ol>li]:list-decimal [&_ol]:mb-4 [&_ol]:pl-8 [&_p]:mb-4 [&_ul>li]:list-disc [&_ul]:mb-4 [&_ul]:pl-8"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: content?.html }}
          />
        )}
        {content?.typeformId &&(
          <Widget id={content?.typeformId} style={{ width: '100%', height: '100%' }} className="my-form" />
        )}
    </div>
    );
  }, [content]);

  return contentMarkup;
}