import { Markdown, Svg } from '../../snippets';
import { Schema } from './IconRow.schema';

export function IconRow({ cms }) {
  const { heading, icons, section, subtext } = cms;
  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-lg-width)]';
  const alignmentClasses = `${section?.alignmentMobile} ${section?.alignmentDesktop}`;
  const iconPositionClass = `${section?.iconPosition}`;
  

  return (
    <div
      className={`px-contained ${section?.spacing}`}
      style={{ backgroundColor: section?.bgColor, color: section?.textColor }}
    >
      <div
        className={`flex flex-col gap-4 md:gap-6 ${maxWidthClass} ${alignmentClasses}`}
      >
        {heading && (
          <h2 className={`text-title-h2 max-w-[46rem] ${alignmentClasses}`}>{heading}</h2>
        )}

        {subtext && (
          <div className={`max-w-[46rem] [&_p]:text-base [&_h2]:text-base [&_h1]:text-base [&_h3]:text-base [&_h4]:text-base [&_h5]:text-base [&_h6]:text-base [&_a]:underline ${alignmentClasses}`}>
            <Markdown>{subtext}</Markdown>
          </div>
        )}

        {icons?.length > 0 && (
          <ul className="mt-4 flex flex-wrap justify-center">
            {icons.map(({ icon, label }, index) => {
              return (
                <li
                  key={index}
                  className={`flex max-w-[16rem] grow basis-1/2 p-4 md:basis-1/6 gap-2.5 ${alignmentClasses} ${iconPositionClass}`}
                >
                  <Svg
                    className="w-12"
                    src={`/svgs/noprecache/icons/${icon}.svg#${icon}`}
                    style={{ color: section?.iconColor }}
                    alt={label || icon}
                    title={label || icon}
                    viewBox="0 0 24 24"
                  />

                  {label && <p className="font-bold">{label}</p>}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

IconRow.displayName = 'IconRow';
IconRow.Schema = Schema;
