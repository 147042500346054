import { useCallback, useEffect, useState } from 'react';

import { useGlobalContext } from '../../contexts';

import { SocialAutomatedGalleryPost } from './SocialAutomatedGalleryPost';
import { Image, Svg } from '../../snippets';
import { Schema } from './SocialAutomatedGallery.schema';

export function SocialAutomatedGallery({ cms }) {
  const { section, video, productItem, slider } = cms;
  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-lg-width)]';

  const {
    actions: { openModal },
  } = useGlobalContext();

  const handleSocialAutomatedGalleryPostClick = useCallback((item) => {
    openModal(
      <SocialAutomatedGalleryPost
        section={section}
        item={item}
        video={video}
        productItem={productItem}
        slider={slider}
      />,
      { className: 'scrollbar-hide max-md:h-[95vh] max-md:overflow-y-auto md:max-w-[70rem] !p-0', extraClassName: '!p-0' },
    );
  }, []);
  
  const [images, setImages] = useState(null);

  const fetchImages = useCallback(async () => {
    try {
      const endpoint = '/api/yotpo/getPhotosByAlbum';
      const options = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: JSON.stringify({
          action: 'getPhotosByAlbum',
        }),
      };

      const response = await fetch(endpoint, options);

      if (!response.ok) {
        throw new Error(`Error fetching images: ${response.statusText}`);
      }

      const data = await response.json();
      const images = data?.response?.images || [];
      setImages(images);
    } catch (error) {
      console.error(`fetchImages error: ${error.message}`);
      // Add additional error handling or user feedback if needed
    }
  }, []);

  useEffect(() => {
    // Call the fetchImages function
    fetchImages();
  }, [fetchImages]);

  return images?.length > 0 && (
    <div className={`${section?.fullBleed ? '' : 'px-contained'}`}>
      <ul
        className={`mx-auto grid grid-cols-2 sm:grid-cols-4 ${maxWidthClass}`}
      >
        {images?.map((item, index) => {
          return (
            <li key={index}>
              <div 
                className="relative aspect-[4/5] bg-offWhite cursor-pointer"
                onClick={() => handleSocialAutomatedGalleryPostClick(item)}
                type="button"
              >
                {item?.original_image_url ? (
                  <Image
                    alt={item?.post?.content}
                    fill
                    sizes="(min-width: 768px) 25vw, 50vw"
                    src={`https:${item?.original_image_url}`}
                  />
                ) : (
                  <Image
                    alt={item?.post?.content}
                    fill
                    sizes="(min-width: 768px) 25vw, 50vw"
                    src={`https:${item?.thumbnail_url}/type/3/start_sec/1/end_sec/3/width/1920/height/1920/vid_slices/1/file_name/thumbnail.jpg`}
                  />
                )}

                <Svg
                  className="absolute top-2.5 right-2.5 w-4 text-white"
                  src={`/svgs/noprecache/social/instagram.svg#instagram`}
                  alt={`Instagram Icon`}
                  title="Instagram"
                  viewBox="0 0 24 24"
                />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

SocialAutomatedGallery.displayName = 'SocialAutomatedGallery';
SocialAutomatedGallery.Schema = Schema;
