import { useState, useCallback } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { useDataLayerActions } from '../../hooks';

export function EmailSignup() {
  const { sendSubscribeEvent } = useDataLayerActions();
  const settings = useSettings();
  const { enabled, heading, subtext, placeholder, buttonText } = {
    ...settings?.footer?.marketing,
  };

  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    try {
      // Call the subscribeToNewsletter function with the entered email
      const endpoint = '/api/newsletterForm';
      const options = {
        method: 'POST',
        headers: {
          revision: '2023-07-15',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'subscribeToNewsletter',
          email,
        }),
      };

      const response = await fetch(endpoint, options);
      // console.log('Subscription response:', response); // Check response data in console after submiting form
      if (response.ok && response.status === 202) {
        setSuccessMessage('Thank you for subscribing!');
        sendSubscribeEvent({ email });
      } else {
        setSuccessMessage('Failed to subscribe. Please try again later.');
      }
    } catch (error) {
      console.error('Subscription error:', error.message);
      setSuccessMessage('Failed to subscribe. Please try again later.');
    }
  }, [sendSubscribeEvent, email]);

  return enabled ? (
    <form
      className="shadow-hairline-b px-5 py-5 md:shadow-none md:p-0"
      onSubmit={handleSubmit}
    >
      <h3 className="text-nav text-current">{heading}</h3>

      {subtext && (
        <p className="mt-2.5 text-base text-current md:text-sm">{subtext}</p>
      )}

      <label className="sr-only" htmlFor="newsletterEmail">Email:</label>
      <input
        id="newsletterEmail"
        className="input-text mt-2.5 text-text"
        name="newsletterEmail"
        placeholder={placeholder}
        required
        aria-required="true"
        type="email"
        value={email}
        autoComplete="email"
        onChange={handleEmailChange}
      />

      <button
        aria-label={buttonText}
        className="btn-primary mt-2.5 w-full"
        type="submit"
      >
        {buttonText}
      </button>
      {successMessage && (
        <p className="transition-all mt-2.5 text-base text-current md:text-sm">{successMessage}</p>
      )}
    </form>
  ) : null;
}

EmailSignup.displayName = 'EmailSignup';