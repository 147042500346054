import { useCallback, useEffect } from 'react';

import { useSearchspringContext } from '../../contexts';

import { windowReplaceUrlParams } from './';

export const useSearchspringSearchPagination = () => {
  const {
    state: {
      searchPageData,
      searchCurrentResultsPage,
      searchSelectedResultsPerPage,
      searchLoadedPages,
    },
    actions: {
      setSearchCurrentResultsPage,
      setSearchSelectedResultsPerPage,
      setSearchLoadedPages,
      setSearchPageInView,
    },
  } = useSearchspringContext();

  const { previousPage, nextPage, totalPages } =
    searchPageData?.pagination || {};

  const updateResultsPerPage = useCallback((num) => {
    if (!num) {
      return console.error(
        'updateResultsPerPage: an integer from 1-100 must be passed'
      );
    }
    setSearchSelectedResultsPerPage(num);
  }, []);

  const goToPrevPage = useCallback(() => {
    if (!previousPage) return;
    const minLoadedPage = Math.min(...searchLoadedPages);
    if (minLoadedPage === 1) return;

    const newPage = minLoadedPage - 1;
    const newPages = [...new Set([...searchLoadedPages, newPage])];

    windowReplaceUrlParams({
      page: newPage === 1 ? undefined : newPage,
      ...(newPage === 1 && { keysToRemove: ['page'] }),
    });

    setSearchCurrentResultsPage(newPage);
    setSearchLoadedPages(newPages);
  }, [previousPage]);

  const goToNextPage = useCallback(() => {
    if (!nextPage) return;
    const maxLoadedPage = Math.max(...searchLoadedPages);
    if (maxLoadedPage === totalPages) return;

    const newPage = maxLoadedPage + 1;
    const newPages = [...new Set([...searchLoadedPages, newPage])];

    windowReplaceUrlParams({
      page: newPage
    });

    setSearchCurrentResultsPage(newPage);
    setSearchLoadedPages(newPages);
  }, [nextPage]);

  const goToPage = useCallback(
    (num) => {
      if (!num || num > totalPages) return;
      setSearchCurrentResultsPage(num);
      setSearchLoadedPages([num]);
    },
    [totalPages]
  );

  const updatePageURLInView = useCallback(
    (num) => {
      windowReplaceUrlParams({
        page: num === 1 ? undefined : num,
        ...(num === 1 && { keysToRemove: ['page'] }),
      });
      setSearchPageInView(num);
    },
    [setSearchPageInView]
  );

  useEffect(() => {
    goToPage(1);
  }, []);

  return [
    // state
    {
      resultsPerPage: searchSelectedResultsPerPage,
      currentPage: searchCurrentResultsPage,
      totalPages: totalPages || 1,
      searchLoadedPages,
    },
    // actions
    {
      updateResultsPerPage,
      goToPrevPage,
      goToNextPage,
      goToPage,
      updatePageURLInView,
    },
  ];
};