import PropTypes from 'prop-types';

import { Spinner } from '../Spinner';
import { Svg } from '../Svg';

export function QuantitySelector({
  disableDecrement = false,
  handleDecrement = undefined,
  handleIncrement = undefined,
  isUpdating = false,
  productTitle = 'product',
  quantity = 1,
  isCompact = false,
}) {
  return (
    <div className={`flex items-center justify-between ${
      isCompact ? 'w-[5rem] ' : 'w-[6.5rem]'
    }`}>
      <button
        aria-label={`Reduce quantity of ${productTitle} by 1 to ${
          quantity - 1
        }`}
        className={`relative bg-offWhite shadow-hairline transition disabled:opacity-50 ${
          disableDecrement ? 'text-gray cursor-not-allowed' : 'md:hover:bg-white'
        } ${
          isCompact ? 'h-6 w-6' : 'h-8 w-8'
        }`}
        disabled={isUpdating || disableDecrement}
        onClick={handleDecrement}
        type="button"
      >
        <Svg
          className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-text ${
            isCompact ? 'w-2' : 'w-4'
          }`}
          src="/svgs/minus.svg#minus"
          alt="Reduce Quantity Icon"
          title="Minus"
          viewBox="0 0 24 24"
        />
      </button>

      <div className="relative flex flex-1 items-center justify-center">
        {isUpdating ? (
          <Spinner color="var(--gray)" width="20" />
        ) : (
          <p className="w-full text-center outline-none">{quantity}</p>
        )}
      </div>

      <button
        aria-label={`Increase quantity of ${productTitle} by 1 to ${
          quantity + 1
        }`}
        className={`relative bg-offWhite shadow-hairline transition disabled:opacity-50 ${
          isCompact ? 'h-6 w-6' : 'h-8 w-8'
        }`}
        disabled={isUpdating}
        onClick={handleIncrement}
        type="button"
      >
        <Svg
          className={`absolute top-1/2 left-1/2 w-4 -translate-x-1/2 -translate-y-1/2 text-text ${
            isCompact ? 'w-2' : 'w-4'
          }`}
          src="/svgs/plus.svg#plus"
          alt="Increase Quantity Icon"
          title="Plus"
          viewBox="0 0 24 24"
        />
      </button>
    </div>
  );
}

QuantitySelector.displayName = 'QuantitySelector';
QuantitySelector.propTypes = {
  disableDecrement: PropTypes.bool,
  handleDecrement: PropTypes.func,
  handleIncrement: PropTypes.func,
  isUpdating: PropTypes.bool,
  productTitle: PropTypes.string,
  quantity: PropTypes.number,
  isCompact: PropTypes.bool,
};
