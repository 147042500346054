import { useMemo } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { CollectionPackMenuSidebar } from '../CollectionPackMenuSidebar';
import { Select } from '../../..';
import { sortKeys } from '../utils';

export function CollectionPackSort({ 
  selectedSort, 
  selectSort,
  isOpen,
  setIsOpen,
 }) {
  const settings = useSettings();
  const sortOptions = { ...settings?.collection?.sort };

  const options = useMemo(() => {
    return sortKeys.map((key) => ({
      value: key,
      label: sortOptions[`${key}Label`],
    }));
  }, [sortOptions, sortKeys]);

  return (
    <>
      {/* desktop */}
      <div className="ml-auto hidden max-w-[11rem] lg:block">
        <Select
          onSelect={(option) => selectSort(option)}
          options={options}
          placeholder="Sort"
          selectedOption={selectedSort}
          placeholderClass="text-text"
        />
      </div>

      {/* mobile */}
      <button
        aria-label="Open sort sidebar"
        className="flex w-auto items-center justify-between bg-offWhite  shadow-hairline p-3 text-base ml-auto lg:hidden"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <div className="flex flex-1 items-center gap-2 overflow-hidden xs:gap-3">
          <h3 className="text-base">Sort</h3>

          {selectedSort?.value && (
            <p className="truncate text-xs text-mediumGray xs:text-sm">
              {selectedSort.label}
            </p>
          )}
        </div>
      </button>

      <CollectionPackMenuSidebar title="Sort" isOpen={isOpen} setIsOpen={setIsOpen}>
        <ul className="overflow-y-auto shadow-hairline-b bg-white gap-2">
          {options.map((option, index) => {
            const isActive = option.value === selectedSort?.value;
            return (
              <li key={index}>
                <button
                  aria-label={option.label}
                  className={`h-14 flex w-full items-center gap-4 shadow-hairline-b p-5 text-left text-text ${
                    isActive ? 'text-text' : 'text-mediumGray'
                  }`}
                  onClick={() => {
                    selectSort(option);
                    setIsOpen(false);
                  }}
                  type="button"
                >
                  <div
                    className={`relative flex h-6 w-6 items-center justify-center overflow-hidden border border-border transition md:hover:border-text ${
                      isActive ? 'border-text' : ''
                    }`}
                  >
                    <div
                      className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 overflow-hidden transition-[height,width] ${
                        isActive
                          ? 'h-[calc(100%-5px)] w-[calc(100%-5px)] bg-primary'
                          : 'h-full w-full bg-offWhite'
                      }`}
                    />
                  </div>

                  <span className="flex-1">{option.label}</span>
                </button>
              </li>
            );
          })}
        </ul>
      </CollectionPackMenuSidebar>
    </>
  );
}

CollectionPackSort.displayName = 'CollectionPackSort';
