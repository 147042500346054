import { useState, useEffect } from 'react';
import { useSettings } from '@backpackjs/storefront';
import PropTypes from 'prop-types';

import { CollectionSearchspringFilters } from './CollectionSearchspringFilters';
import { CollectionSearchspringSortFilterBar } from './CollectionSearchspringSortFilterBar';
import { CollectionSearchspringGrid } from './CollectionSearchspringGrid';
import { useCollectionProducts } from './useCollectionProducts';
import { useColorSwatches } from '../../../hooks';

export function CollectionSearchspring({
  handle = undefined,
  title = '',
  products = [],
  searchspringProducts = [],
  totalProducts = 0,
  selectedResultsPerPage = 0,
  currentResultsPage = 0,
  totalPages = 0,
  loadedPages = [],
  selectedSort = '',
  sortOptions = [],
  filters = [],
  filterSummary = [],
  selectSort = () => {},
  addToFilters = () => {},
  removeFromFilters = () => {},
  clearFilters = () => {},
  goToPreviousPage = () => {},
  goToFollowingPage = () => {},
  goToSpecificPage = () => {},
  updatePageInView = () => {},
  savedProductId = null,
}) {
  const isSearchResults = handle === 'search';

  const renderedProducts = isSearchResults ? products : searchspringProducts;

  const { swatchesMap } = useColorSwatches();
  const settings = useSettings();
  const {
    filters: filtersSettings,
    sort: sortSettings,
    pagination,
  } = { ...settings?.collection };

  const enabledFilters = filtersSettings?.enabled;
  const enabledSort = sortSettings?.enabled;

  // Open desktop filters
  const [areOpen, setAreOpen] = useState(true);

  // Open filter dropdown
  const defaultOpenFilter = filters?.find(filter => filter.collapse === 0)?.label || null;
  const [openFilter, setOpenFilter] = useState(defaultOpenFilter);

  // Close filter if it no longer exists
  useEffect(() => {
    // If the currently open filter no longer exists, close the open filter
    if (openFilter && !filters?.some(filter => filter.label === openFilter)) {
      setOpenFilter(null);
    }
  }, [filters, openFilter, setOpenFilter]);

  const collectionProductsData = useCollectionProducts({
    pagination,
    goToNextPage: goToFollowingPage,
    goToPrevPage: goToPreviousPage,
    goToSpecificPage,
    resultsPerPage: selectedResultsPerPage,
    currentPage: currentResultsPage,
    totalPages,
    loadedPages,
    updatePageInView,
    savedProductId,
  });

  return (
    <div className="mx-auto flex max-w-[var(--content-max-xl-width)] flex-col">
      <CollectionSearchspringSortFilterBar
        isSearchResults={isSearchResults}
        enabledSort={enabledSort}
        enabledFilters={enabledFilters}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        selectedSort={selectedSort}
        selectSort={selectSort}
        sortOptions={sortOptions}
        areOpen={areOpen}
        setAreOpen={setAreOpen}
        filters={filters}
        filterSummary={filterSummary}
        addToSelectedFilters={addToFilters}
        removeFromSelectedFilters={removeFromFilters}
        clearSelectedFilters={clearFilters}
        swatchesMap={swatchesMap}
      />
      <div className="collection-container mt-5 flex w-full flex-row flex-wrap">
        {enabledFilters && (
          <div
            className={`order-3 ${
              areOpen
                ? 'max-md:w-1/2 md:hidden'
                : 'md:block md:w-1/5 md:pr-5 xl:pr-10'
            }`}
          >
            <CollectionSearchspringFilters
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              filterSummary={filterSummary}
              filters={filters}
              addToSelectedFilters={addToFilters}
              removeFromSelectedFilters={removeFromFilters}
              swatchesMap={swatchesMap}
            />
          </div>
        )}
        <div className={`order-4 ${areOpen ? 'w-full' : 'w-full md:w-4/5'}`}>
          <CollectionSearchspringGrid
            collectionTitle={title}
            collectionHandle={handle}
            collectionProductsData={collectionProductsData}
            products={renderedProducts}
            enabledFilters={enabledFilters}
            isSearchResults={isSearchResults}
            swatchesMap={swatchesMap}
            totalProducts={totalProducts}
            currentPage={currentResultsPage}
          />
        </div>
      </div>
    </div>
  );
}

CollectionSearchspring.displayName = 'CollectionSearchspring';
CollectionSearchspring.propTypes = {
  handle: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
};
