import { Accordions } from './Accordions';
import { AnimatedText } from './AnimatedText/AnimatedText';
import { BlogCategories } from './BlogCategories';
import { BlogGrid } from './BlogGrid';
import { Collection } from './Collection';
import { CollectionHero } from './CollectionHero';
import { CollectionIconRow } from './CollectionIconRow';
import { CollectionSlider } from './CollectionSlider';
import { FormBuilder } from './FormBuilder';
import { HalfHero } from './HalfHero';
import { Hero } from './Hero';
import { Html } from './Html';
import { IconRow } from './IconRow';
import { Image } from './Image';
import { ImagesGrid } from './ImagesGrid';
import { ImageTiles } from './ImageTiles';
import { Markdown } from './Markdown';
import { PressSlider } from './PressSlider';
import { ProductDetailAccordions } from './ProductDetailAccordions';
import { ProductsSlider } from './ProductsSlider';
import { Referral } from './Referral';
import { SearchResults } from './SearchResults';
import { SocialImagesGrid } from './SocialImagesGrid';
import { SocialAutomatedGallery } from './SocialAutomatedGallery';
import { TabbedThreeTiles } from './TabbedThreeTiles';
import { TestimonialSlider } from './TestimonialSlider';
import { TextBlock } from './TextBlock';
import { ThreeTiles } from './ThreeTiles';
import { Tiles } from './Tiles';
import { TwoTiles } from './TwoTiles';
import { Video } from './Video';

export default [
  Accordions,
  AnimatedText,
  BlogCategories,
  BlogGrid,
  Collection,
  CollectionHero,
  CollectionIconRow,
  CollectionSlider,
  FormBuilder,
  HalfHero,
  Hero,
  IconRow,
  Image,
  ImagesGrid,
  ImageTiles,
  Markdown,
  PressSlider,
  ProductDetailAccordions,
  ProductsSlider,
  Referral,
  SearchResults,
  SocialImagesGrid,
  SocialAutomatedGallery,
  TabbedThreeTiles,
  TestimonialSlider,
  TextBlock,
  ThreeTiles,
  Tiles,
  TwoTiles,
  Video,
  Html,
];
