import { useSettings } from '@backpackjs/storefront';

import { TrustBuilders } from './TrustBuilders';
import { EmailSignup } from './EmailSignup';
import { FooterLegal } from './FooterLegal';
import { FooterMenu } from './FooterMenu';
import { FooterSocial } from './FooterSocial';
import { Link } from '../Link';
import { Svg } from '../Svg';

export function Footer({ refElement }) {
  const settings = useSettings();
  const { bgColor, textColor } = { ...settings?.footer };

  return (
    <footer
      ref={refElement}
      className="shadow-hairline"
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      <TrustBuilders />
      <div className="container mx-auto py-5 md:py-7 lg:p-10">
        <div className="mx-auto grid grid-cols-1 md:grid-cols-12 md:gap-x-5 md:gap-y-10 xl:grid-cols-12 xl:gap-x-20">
          <div className="order-1 col-span-1 md:col-span-12 xl:col-span-3">
            <div className="flex flex-col gap-5 shadow-hairline-b px-5 pb-5 md:flex-row md:gap-0 md:shadow-none md:p-0 xl:flex-col xl:gap-6">
              <Link href="/" aria-label="Go to home page">
                <Svg
                  className="text-current"
                  src="/svgs/logo-text.svg#logo-text"
                  alt="Rhoback Icon"
                  title="Rhoback"
                  width="86px"
                  height="15px"
                  viewBox="0 0 86 15"
                />
              </Link>

              <FooterSocial />
            </div>
          </div>

          <div className="order-3 w-full md:col-span-7 md:order-2 xl:col-span-5">
            <FooterMenu />
          </div>

          <div className="order-2 w-full md:col-span-5 md:order-3 xl:col-span-4">
            <EmailSignup />
          </div>

          <div className="order-4 col-span-1 w-full md:col-span-12 xl:col-span-12">
            <FooterLegal />
          </div>
        </div>      
      </div>
    </footer>
  );
}

Footer.displayName = 'Footer';
