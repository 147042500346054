import { useCallback, useEffect, useRef, useState } from 'react';
import { useCart, useCurrency } from '@backpackjs/storefront';
import { useRouter } from 'next/router';
import { nanoid } from 'nanoid';

import { mapLineItem } from './utils';

const IGNORED_PATHS = ['cart', 'collections', 'products'];
const LOGGED_OUT_ACCOUNT_PATHS = [
  '/account/login',
  '/account/register',
  '/account/reset',
  '/account/activate',
];

export function useDataLayerCustomer({ DEBUG, userProperties }) {
  const asPathRef = useRef(null);
  const cart = useCart();
  const { asPath } = useRouter();
  const currencyCode = useCurrency();

  const [userDataEventTriggered, setUserDataEventTriggered] = useState(false);

  const userDataEvent = useCallback(
    ({ currencyCode: passedCurrencyCode, userProperties: _userProperties }) => {
      const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
      const list =
        (window.location.pathname.startsWith('/collections') &&
          window.location.pathname) ||
        (previousPath?.startsWith('/collections') && previousPath) ||
        '';
      const event = {
        event: 'user_data',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        cart_total: cart?.estimatedCost?.totalAmount?.amount || '0.0',
        user_properties: _userProperties,
        ecommerce: {
          currencyCode:
            passedCurrencyCode ||
            currencyCode ||
            cart?.estimatedCost?.totalAmount?.currencyCode,
          cart_contents: {
            products: cart?.lines?.map(mapLineItem(list)) || [],
          },
        },
      };

      window.gtag('event', event.event, event);
      if (DEBUG) console.log(`DataLayer:gtag:${event.event}`, event);
      setUserDataEventTriggered(true);
    },
    [cart?.updatedAt, currencyCode]
  );

  // Trigger 'user_data' event on path change after base data is ready,
  // excluding account and paths that trigger event directly before their respective events
  useEffect(() => {
    if (
      !userProperties ||
      IGNORED_PATHS.includes(asPath.split('/')[1]) ||
      asPath.startsWith('/account') ||
      asPath.startsWith('/pages/search') ||
      asPath === asPathRef.current
    )
      return undefined;

    userDataEvent({ userProperties });

    asPathRef.current = asPath;
    return () => {
      asPathRef.current = null;
    };
  }, [asPath, !!userProperties]);

  // Trigger 'user_data' event on account pages after base data is ready,
  // excluding after login/register events
  useEffect(() => {
    if (
      !userProperties ||
      !asPath.startsWith('/account') ||
      asPath === asPathRef.current
    )
      return undefined;

    const prevPath = sessionStorage.getItem('PREVIOUS_PATH');
    const currentPath = sessionStorage.getItem('CURRENT_PATH');
    const prevPathInlcRefresh = sessionStorage.getItem(
      'PREVIOUS_PATH_INLC_REFRESH'
    );

    // On login or register, prevent event as it triggers directly from the login/register events
    const isFromLoginOrRegisterEvent =
      (prevPath.startsWith('/account/login') ||
        prevPath.startsWith('/account/register')) &&
      currentPath !== prevPathInlcRefresh;
    const isLoggedOutAccountPath = LOGGED_OUT_ACCOUNT_PATHS.some((path) =>
      asPath.startsWith(path)
    );
    if (isFromLoginOrRegisterEvent && !isLoggedOutAccountPath) return undefined;

    // On log out, trigger event with guest visitor type
    const isFromLogoutEvent =
      asPath.startsWith('/account/login') && prevPath.startsWith('/account');
    const prevPathIsLoggedOutAccountPath = LOGGED_OUT_ACCOUNT_PATHS.some(
      (path) => prevPath.startsWith(path)
    );
    if (isFromLogoutEvent && !prevPathIsLoggedOutAccountPath) {
      userDataEvent({
        userProperties: { visitor_type: 'guest', user_consent: '' },
      });
    } else {
      // Else trigger event for all other instances
      userDataEvent({ userProperties });
    }

    asPathRef.current = asPath;
    return () => {
      asPathRef.current = null;
    };
  }, [asPath, !!userProperties]);

  return { userDataEvent, userDataEventTriggered };
}
