import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useColorSwatches } from '../../hooks';

import { ProductItem } from '../ProductItem';

export function ProductUpsellRecommendations({ legacyResourceId, upsellProducts }) {
  const { swatchesMap } = useColorSwatches();
  const { productItem } = { ...upsellProducts };

  const [recommendedProducts, setRecommendedProducts] = useState([]);

  const fetchRecommendations = useCallback(async (productId) => {
    try {
      if (!productId) return;

      const endpoint = '/api/productUpsellRecommendations';
      const options = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: JSON.stringify({
          action: 'getProductUpsellRecommendations',
          productId,
        }),
      };

      const response = await fetch(endpoint, options);
      const data = await response.json();
      // console.log('response from cloud:', data); // Log the data received from the cloud function
      // Extract the 'results' array directly and set it in the state
      const results = data[0]?.results || [];
      setRecommendedProducts(results);
    } catch (error) {
      console.error(`fetchRecommendations error: ${error.message}`);
      throw error;
    }
  }, []);

  useEffect(() => {
    // Call the fetchRecommendations function with the provided productId
    fetchRecommendations(legacyResourceId);
  }, [fetchRecommendations, legacyResourceId]);

  return recommendedProducts?.length > 0 && (
    <div className="flex flex-col gap-5 my-5">
      <h3 className="text-title-h3">{upsellProducts?.message}</h3>
      <div className="grid grid-cols-2 gap-5">
        {recommendedProducts.map((product, index) => {
            const { url } = product?.mappings?.core;
            const cleanUrl = url ? url.substring(url.lastIndexOf('/products/') + '/products/'.length) : '';

            return (
              <div key={`product-upsell-${index}`} className="flex flex-col">
                <ProductItem
                  key={`${product.id}-${index}`}
                  enabledColorNameOnHover={productItem?.enabledColorNameOnHover}
                  enabledColorSelector={productItem?.enabledColorSelector}
                  enabledQuickShop={productItem?.enabledQuickShop}
                  enabledQuickShopBIS={productItem?.enabledQuickShopBIS}
                  enabledStarRating={productItem?.enabledStarRating}
                  enabledProductType={productItem?.enabledProductType}
                  handle={cleanUrl}
                  index={index}
                  swatchesMap={swatchesMap}
                />
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

ProductUpsellRecommendations.displayName = 'ProductUpsellRecommendations';
ProductUpsellRecommendations.propTypes = {
  legacyResourceId: PropTypes.string,
  upsellProducts: PropTypes.object,
};