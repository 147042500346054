import { useSettings } from '@backpackjs/storefront';

import { CollectionPackDropdownFilter } from './CollectionPackDropdownFilter';
import { Svg } from '../../../Svg';

export function CollectionPackFilters({
  collectionCount,
  collectionFiltersData,
  swatchesMap,
}) {
  const settings = useSettings();
  const { optionsMaxCount, showCount, sticky } = {
    ...settings?.collection?.filters,
  };
  const { enabled: inStockFilterEnabled, label: inStockLabel } = {
    ...settings?.collection?.inStockFilter,
  };
  const stickyPromobar =
    settings?.header?.promobar?.enabled &&
    !settings?.header?.promobar?.autohide;
  const stickyTopClass = stickyPromobar
    ? 'md:top-[calc(var(--header-height)+var(--promobar-height)+3rem)]'
    : 'md:top-[calc(var(--header-height)+1.78rem)] xl:top-[calc(var(--header-height)+3rem)]';
  const {
    state: { activeFilters, filterByInStock, filters },
    actions: { addFilter, removeFilter, setFilterByInStock },
  } = collectionFiltersData;

  return (
    <div
      className={`filter-sidebar flex flex-col max-md:gap-5 md:sticky ${
        sticky ? stickyTopClass : ''
      }`}
    >
      <ul className="shadow-hairline bg-white max-md:hidden">
        {filters.map((filter, index) => {
          if (
            !filter.values.length ||
            (filter.values.length === 1 &&
              filter.values[0].count === collectionCount)
          )
            return null;

          return (
            <li key={index} className={filter.hideFromFilter ? 'hidden' : ''}>
              <CollectionPackDropdownFilter
                activeFilters={activeFilters}
                addFilter={addFilter}
                defaultOpen={filter.defaultOpenDesktop}
                overrideOptionsMaxCount={filter.overrideOptionsMaxCount}
                filter={filter}
                optionsMaxCount={optionsMaxCount}
                removeFilter={removeFilter}
                showCount={showCount}
                swatchesMap={swatchesMap}
              />
            </li>
          );
        })}
      </ul>

      {inStockFilterEnabled && (
        <div className="flex mt-2.5 gap-1">
          <button
            aria-label={inStockLabel}
            className={`relative group h-fit flex gap-3 text-left text-base transition max-md:hidden max-md:w-full max-md:items-center md:gap-2 hover:md:text-text ${
              filterByInStock ? 'text-text' : 'text-mediumGray'
            }`}
            onClick={() => setFilterByInStock(!filterByInStock)}
            type="button"
          >
            <div className="shadow-hairline bg-offWhite w-11 h-auto flex p-0.5 group-hover:bg-white">
              <div
                className={`shadow-hairline relative flex h-5 w-5 items-center justify-center overflow-hidden transition md:h-[18px] md:w-[18px] group-hover:md:border-text ${
                  filterByInStock ? 'border border-text bg-primary ml-auto' : 'bg-white group-hover:bg-offWhite '
                }`}
              >
                <div
                  className={`absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 border-white transition-[border-width] duration-100 ${
                    filterByInStock
                      ? 'border-[0px] md:border-[0px]'
                      : 'border-[0px]'
                  }`}
                />

                <Svg
                  src="/svgs/checkmark.svg#checkmark"
                  viewBox="0 0 24 24"
                  className={`pointer-events-none text-white transition md:w-2.5 ${
                    filterByInStock ? 'opacity-100' : 'opacity-0'
                  }`}
                  alt="Select Filter Icon"
                  title="Filter Checkmark"
                />
              </div>
            </div>
            <p className="text-sm mb-0">{inStockLabel}</p>
          </button>
          
        </div>
      )}
    </div>
  );
}

CollectionPackFilters.displayName = 'CollectionPackFilters';
