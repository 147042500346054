import { useCallback } from 'react';

import { useSearchspringContext } from '../../contexts';

export const useSearchspringSearchParameters = () => {
  const {
    state: { searchQuery, searchPageQuery, searchTags },
    actions: {
      setSearchQuery,
      setSearchPageQuery,
      setSearchTags,
    },
  } = useSearchspringContext();

  const searchParameters = useCallback(
    async ({ query }) => {
      setSearchQuery(query);
    },
    [setSearchQuery]
  );

  const searchPageParameters = useCallback(
    async ({ query }) => {
      setSearchPageQuery(query);
      if (query?.tags) {
        setSearchTags(query.tags);
      }
    },
    [setSearchPageQuery]
  );

  return [
    // state
    {
      searchQuery,
      searchPageQuery,
      searchTags,
    },
    // actions
    {
      searchParameters,
      searchPageParameters,
    },
  ];
};
