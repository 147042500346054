import { useCallback } from 'react';

import { useSearchspringContext } from '../../contexts';

import { windowReplaceUrlParams } from './';

export const useSearchspringSearchSort = () => {
  const {
    state: { searchPageData, searchSelectedSort },
    actions: { setSearchSelectedSort, setSearchCurrentResultsPage, setSearchLoadedPages },
  } = useSearchspringContext();

  // get filter entries based on type
  const getSortEntries = useCallback(({ field, direction }) => {
    let _sort = {};

    const [key, value] = [`sort.${field}`, direction || ''];
    _sort = {
      [`${key}.${value}`]: { key, value },
    };

    return _sort;
  }, []);

  // transform entries to modify URL params
  const transformEntries = useCallback(({ field, direction }) => {
    const entries = getSortEntries({ field, direction });
    let transformedEntries = {};
    Object.keys(entries).forEach(key => {
      let entry = entries[key];
      transformedEntries[entry.key] = [entry.value];
    });
    return transformedEntries;
  }, []);

  // load sort from params
  const loadSelectedSort = useCallback(
    (sortFromParams) => {
      if (!sortFromParams) {
        return console.error('loadSelectedSort: missing sortFromParams');
      }

      const sortObject = sortFromParams[0]; // Extract the first element of the array

      setSearchSelectedSort(sortObject);
      setSearchLoadedPages([1]);
    },[]
  );

  const sortCollection = useCallback(async ({ field, direction }) => {
    if (!field || !direction) {
      // clear sort
      setSearchSelectedSort(null);
      return;
    }

    // Examples
    // { field: 'ss_price', direction: 'asc' },
    // { field: 'title', direction: 'desc' }
    // { field: 'ss_days_since_published', direction: 'asc' }

    // Entries to add to URL params
    const addedEntries = transformEntries({ field, direction });

    windowReplaceUrlParams({
      entriesToReplace: Object.entries(addedEntries),
      keysToRemove: ['page'],
    });

    setSearchSelectedSort({ field, direction });
    setSearchCurrentResultsPage(1);
    setSearchLoadedPages([1]);
  }, []);

  return [
    // state
    {
      selectedSort: searchSelectedSort,
      sortOptions: searchPageData?.sorting?.options || null,
    },
    // actions
    {
      loadSelectedSort,
      sortCollection,
    },
  ];
};