import { useMemo } from 'react';
import { useSettings } from '@backpackjs/storefront';
import { useProductInventoryByHandle } from '@backpackjs/storefront';
import PropTypes from 'prop-types';

import { AddToCart } from '../../AddToCart';
import { QuickShopOptions } from './QuickShopOptions';
import { Svg } from '../../Svg';

export function QuickShop({
  selectedProduct,
  selectedVariant,
  isCompact = false,
  isTrigger = false,
  setShowQuickShop = null,
  showQuickShop = false,
  soldOutQty,
  enabledQuickShopBIS,
}) {
  const { inventory, success: inventoryFetched } = useProductInventoryByHandle({
    handle: selectedProduct?.handle,
    withInventory: false,
  });

  const settings = useSettings();
  const { quickShopMultiText, quickShopSingleText, quickShopBISSingleText, quickShopBISMultitext } = {
    ...settings?.collection?.productItem,
  };
  
  const qualifiesForQuickShop = useMemo(() => {
    if (!selectedProduct) return false;

    const initialOptions = selectedProduct.grouping?.isTransformed
      ? selectedProduct.grouping.options
      : selectedProduct.options;
    const options = initialOptions.filter((option) => option.name !== 'Color');

    const hasOnlySingleValueOptions =
      options.every((option) => {
        return option.values.length === 1;
      }) || false;
    const hasOnlyOneOptionWithMultipleValues =
      options.reduce((acc, option) => {
        return acc + (option.values.length > 1 ? 1 : 0);
      }, 0) === 1 || false;

    return (
      hasOnlySingleValueOptions || hasOnlyOneOptionWithMultipleValues
    );
  }, [selectedProduct?.id]);

  const hasOneVariant = selectedProduct?.variants?.length === 1;
  const hasOneOptionAndIsColor = useMemo(() => {
    if (!selectedProduct) return false;
    
    const options = selectedProduct.options || [];
    const colorOption = options.find(option => option.name === 'Color');
    
    return options.length === 1 && colorOption !== undefined;
  }, [selectedProduct]);

  const isSoldOut = selectedProduct?.isGiftCard ? false : inventory?.totalInventory < soldOutQty;

  return qualifiesForQuickShop && selectedVariant ? (
    <>
      {isTrigger ? (
        <div className={`relative w-full mt-auto ${
          isCompact ? '' : 'lg:hidden'
        }`}>
          {(hasOneVariant || hasOneOptionAndIsColor) ? (
            <AddToCart
              addToCartText={quickShopSingleText}
              className="btn-sm btn-sm-secondary !w-auto"
              selectedVariant={selectedVariant}
            />
          ): (
            <button 
              aria-label={`${showQuickShop ? 'Hide' : 'Show'} Quick Add`}
              className="group/item btn-sm btn-sm-secondary"
              onClick={() => setShowQuickShop(!showQuickShop)}
              type="button"
            >
              Quick Add {showQuickShop ? (
                <Svg
                  className="text-text group-hover/item:md:text-white ml-1"
                  src="/svgs/minus.svg#minus"
                  alt="Hide Quick Add Icon"
                  title="Minus"
                  viewBox="0 0 24 24"
                  width="8"
                  height="8"
                />
              ) : (
                <Svg
                  className="text-text group-hover/item:md:text-white ml-1"
                  src="/svgs/plus.svg#plus"
                  alt="Show Quick Add Icon"
                  title="Plus"
                  viewBox="0 0 24 24"
                  width="8"
                  height="8"
                />
              )}
            </button>            
          )}
          
        </div>
      ) : (
        <div className={`w-full bg-white px-[.5px] ${
          isCompact ? '' : 'absolute bottom-0 z-0 hidden opacity-0 transition md:group-hover:opacity-100 md:group-hover:block'
        }`}>
          {(hasOneVariant || hasOneOptionAndIsColor) && (
            <AddToCart
              addToCartText={quickShopSingleText}
              className="btn-primary"
              selectedVariant={selectedVariant}
              isOutOfStock={isSoldOut}
            />
          )}
    
          {!hasOneVariant && !hasOneOptionAndIsColor && (
            <QuickShopOptions
              quickShopMultiText={quickShopMultiText}
              selectedProduct={selectedProduct}
              selectedVariant={selectedVariant}
              isCompact={isCompact}
              inventory={inventory}
              inventoryFetched={inventoryFetched}
              isSoldOut={isSoldOut}
              enabledQuickShopBIS={enabledQuickShopBIS}
              quickShopBISSingleText={quickShopBISSingleText}
              quickShopBISMultitext={quickShopBISMultitext}
            />
          )}
        </div>
      )}
    </>
    
  ) : null;
}

QuickShop.displayName = 'QuickShop';
QuickShop.propTypes = {
  selectedProduct: PropTypes.object,
  selectedVariant: PropTypes.object,
  isCompact: PropTypes.bool,
  isTrigger: PropTypes.bool,
  setShowQuickShop: PropTypes.func,
  showQuickShop: PropTypes.bool,
  quickShopBIS: PropTypes.bool,
};
