import { useCallback } from 'react';

import { useSearchspring } from './';
import { useSearchspringContext } from '../../contexts';

export const useSearchspringSearchSuggestions = () => {
  const { requestSearchspring } = useSearchspring();
  const {
    state: {
      searchSuggestions,
      searchPageSuggestions,
      searchSuggested,
      searchSpellCorrection,
      searchPageSuggested,
      searchPageSpellCorrection,
      searchSuggestionIsAutocomplete,
    },
    actions: {
      setSearchSuggestions,
      setSearchPageSuggestions,
      setSearchSuggested,
      setSearchPageSuggested,
      setSearchSpellCorrection,
      setSearchPageSpellCorrection,
      setSearchSuggestionIsAutocomplete,
    },
  } = useSearchspringContext();

  // Get search searchSuggestions based off query from ss
  const getSearchSuggestions = useCallback(async ({ query, isPage }) => {
    try {
      if (!query) {
        setSearchSuggestions([]);
        setSearchSuggested(null);
        setSearchSuggestionIsAutocomplete(false);
        setSearchSpellCorrection(null);
        return false;
      }

      if (isPage && !query) {
        setSearchPageSuggestions([]);
        setSearchPageSuggested(null);
        setSearchPageSpellCorrection(null);
        return false;
      }

      const data = await requestSearchspring({
        action: 'getSearchSuggestions',
        params: { query },
      });

      const _alternatives = data?.alternatives?.length
        ? data.alternatives.map(({ text }) => text)
        : [];

      // combine top searchSuggested suggestion and alternative searchSuggestions into one array
      const _suggestions = [
        ...(data?.searchSuggested?.type === 'completed'
          ? [data.searchSuggested.text]
          : []),
        ..._alternatives,
      ];

      if (isPage) {
        setSearchPageSuggestions(_suggestions);
        setSearchPageSuggested(data?.searchSuggested || null);
        setSearchPageSpellCorrection(data?.['corrected-query'] || null);
        return data;
      }

      setSearchSuggestions(_suggestions);
      setSearchSuggested(data?.searchSuggested || null);
      setSearchSuggestionIsAutocomplete(
        data?.searchSuggested?.type === 'completed' || false
      );
      setSearchSpellCorrection(data?.['corrected-query'] || null);
      return data;
    } catch (error) {
      console.error(`Error with getSearchSuggestions: ${error.message}`);
      throw error;
    }
  }, []);

  return {
    state: {
      suggestions: searchSuggestions,
      pageSuggestions: searchPageSuggestions,
      suggested: searchSuggested,
      spellCorrection: searchSpellCorrection,
      isAutocomplete: searchSuggestionIsAutocomplete,
      pageSuggested: searchPageSuggested,
      pageSpellCorrection: searchPageSpellCorrection,
    },
    actions: {
      getSearchSuggestions,
    },
  };
};