import { useMemo } from 'react';

import { Image } from '../Image';
import { Link } from '../Link';
import { Spinner } from '../Spinner';
// import { Svg } from '../Svg';
import { QuantitySelector } from '../QuantitySelector';
import { useCartItem } from './useCartItem';
import { useCartItemImage } from './useCartItemImage';
import { useCartItemPrices } from './useCartItemPrices';

export function CartItem({ closeCart, item }) {
  const { quantity, variant } = item;

  const {
    isUpdatingItem,
    isRemovingItem,
    handleDecrement,
    handleIncrement,
    handleRemove,
  } = useCartItem({ item });

  const { price, compareAtPrice } = useCartItemPrices({ item });

  const image = useCartItemImage({ item });

  const url = useMemo(() => {
    return `/products/${variant.product.handle}?variant=${variant.id
      .split('/')
      .pop()}`;
  }, [variant.id]);

  const productTitle = variant.product.title.split(' | ')[0];

  return (
    <div className="relative bg-white flex flex-row">
      <div className=" relative w-[30%]">
        <div className="aspect-[var(--product-image-aspect-ratio)]">
          <Link
            aria-label={`View ${productTitle}`}
            href={url}
            onClick={closeCart}
            tabIndex="-1"
          >
            {image?.src && (
              <Image
                alt={productTitle}
                fill
                sizes="(min-width: 768px) 33vw, 20vw"
                className="bg-offWhite"
                src={image.src}
                srcSetSizes={[240, 480]}
              />
            )}
          </Link>
        </div>
      </div>

      <div className="w-[70%] flex flex-col gap-[5px] p-2.5">
        <div className="relative">
            <Link
              aria-label={`View ${productTitle}`}
              href={url}
              onClick={closeCart}
            >
              <h3 className="text-body-lg truncate">{productTitle}</h3>
            </Link>

            {variant.title !== 'Default Title' && (
              <p className="text-sm text-darkGray">
                {/* {variant.title} */}
                {variant.title.split('|').length > 1 ? variant.title.split('|')[1].trim() : variant.title}
                </p>
            )}
        </div>
        <div className="flex flex-1 flex-wrap items-baseline gap-x-1">
          <p className={`${
            compareAtPrice ? 'text-secondary' : 'text-primary'
          }`}>{price}</p>
          {compareAtPrice && (
            <p className="text-sm text-mediumGray line-through">{compareAtPrice}</p>
          )}
        </div>
        <QuantitySelector
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
          isUpdating={isUpdatingItem}
          productTitle={productTitle}
          quantity={quantity}
          isCompact={true}
        />
        <button
            aria-label={`Remove ${productTitle} from cart`}
            className="text-link-sm absolute right-2.5 bottom-2.5"
            onClick={handleRemove}
            type="button"
          >
            Remove
          </button>
      </div>

      {isRemovingItem && (
        <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-[rgba(255,255,255,0.6)] text-mediumGray">
          <Spinner width="20" />
        </div>
      )}
    </div>
  );
}

CartItem.displayName = 'CartItem';
