import { useEffect, useState } from 'react';
import { ReviewStars } from '../ReviewStars';

const decodeHtmlEntities = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.documentElement.textContent;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear().toString().substr(-2);
  return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
};

export function ProductReviewsReview({
  review,
  index,
  viewMode,
  currentReviews,
}) {
  const reviewClass = viewMode === 'grid' ? 'min-h-[308px] break-inside-avoid flex flex-col' : 'h-full flex flex-col py-5 first:pt-0 last:shadow-none';
  const reviewContentClass = viewMode === 'grid' ? 'overflow-y-hidden' : '!h-auto';
  const reviewMoreClass = viewMode === 'grid' ? 'block' : 'hidden';

  useEffect(() => {
    const handleReviewHeight = () => {
      const reviewElement = document.querySelectorAll('[data-review]');
      reviewElement.forEach((reviewElement) => {
        const reviewContent = reviewElement.querySelector('[data-review-content]');
        const reviewReadMore = reviewElement.querySelector('[data-review-read-more]');
        
        if (reviewElement.clientHeight > 308) {
          reviewContent.style.height = '194px'; // Adjust as needed
          reviewReadMore.classList.remove('hidden');
        } 
      });
    };
  
    handleReviewHeight();
    window.addEventListener('resize', handleReviewHeight);
  
    return () => {
      window.removeEventListener('resize', handleReviewHeight);
    };
  }, [currentReviews]);

  const [expandedStates, setExpandedStates] = useState(currentReviews.map(() => false));

  const toggleContentForReview = (index) => {
    setExpandedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };
  const toggleContent = (index) => {
    toggleContentForReview(index);
  };  

  const customFields = review.custom_fields || {};
  const customFieldFitId = '--95085';
  let customFieldFitTitle = '';
  let customFieldFitValue = '';
  let sizeMapping = {
    1: 'Small',
    2: 'True to size',
    3: 'Big'
  };
  if (customFields && customFields[customFieldFitId] && customFields[customFieldFitId].title) {
    customFieldFitTitle = customFields[customFieldFitId].title;
    customFieldFitValue = customFields[customFieldFitId].value;
  }
  const size = sizeMapping[customFieldFitValue];

  return (
    <div 
      className={`${reviewClass} review-element`} 
      data-review 
    >
      <div 
        className={`relative ${reviewContentClass} ${
          expandedStates[index] ? '!h-auto pb-4' : 'review-content-collapsed'
        }`} 
        data-review-content
      >
        <ReviewStars rating={review?.score} size="small" />
        <p className="text-body-lg font-medium mt-2.5">{decodeHtmlEntities(review?.title)}</p>
        <p>{decodeHtmlEntities(review.content)}</p>
        {customFieldFitTitle && <p className="text-body-sm">{customFieldFitTitle}: <span className="font-medium">{size}</span></p>}
      <div className={`absolute w-full bottom-0 ${reviewMoreClass}`}>
        <div 
          className="w-full h-full hidden" 
          data-review-read-more
        >
          <div className={`w-full h-20 bg-gradient-to-b from-transparent to-white ${expandedStates[index] ? 'hidden' : ''}`}></div>
          <div className="flex flex-row bg-white">
            <button 
              className="review-more-button flex-row text-xs" 
              onClick={() => toggleContent(index)}
              aria-label={`${expandedStates[index] ? 'Collapse' : 'Expand'} review`}
              type="button"
            >
              {expandedStates[index] ? '- Read Less' : '+ Read More'}
            </button>
          </div>
        </div>
      </div>
      </div>
      <div className="mt-auto">
        <p className="font-medium truncate mt-2.5">{decodeHtmlEntities(review.user?.display_name)}{review.verified_buyer && <span className="text-xs font-normal"> Verified Buyer</span>}</p>
        <p className="text-body-sm">{formatDate(review.created_at)}</p>
        {/* uncomment the following line to show the product name for the Review. Firs you need to uncomment code from ProductReviews */}
        {/* {review.product_name && <p className="text-xs">Reviewed on: {review.product_name}</p>} */}
      </div>
    </div>
  )
}

ProductReviewsReview.displayName = 'ProductReviewsReview';