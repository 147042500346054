import { useState, useCallback, useEffect } from 'react';
import { A11y, Navigation, Pagination, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ProductItem } from '../ProductItem';
import { Spinner } from '../Spinner';
import { Svg } from '../Svg';
import { useColorSwatches } from '../../hooks';

export function ProductSimilarRecommendations({
  similarProducts,
  legacyResourceId,
}) {
  const { swatchesMap } = useColorSwatches();

  const [swiper, setSwiper] = useState(null);

  const { slider } = { ...similarProducts };
  const { sliderStyle } = { ...slider };
  const { section } = { ...similarProducts };
  const { productItem } = { ...similarProducts };
  const slidesPerViewDesktop = slider?.slidesPerViewDesktop || 4;
  const slidesPerViewTablet = slider?.slidesPerViewTablet || 3.4;
  const slidesPerViewMobile = slider?.slidesPerViewMobile || 1.4;
  const isFullBleedAndCentered =
    sliderStyle === 'fullBleed' || sliderStyle === 'fullBleedWithGradient';
  const isLoop = isFullBleedAndCentered || sliderStyle === 'containedWithLoop';
  const maxWidthClass =
    section?.fullWidth || isFullBleedAndCentered
      ? 'max-w-none'
      : 'max-w-[var(--content-max-lg-width)]';
  const sliderArrowsDesktop = slider?.sliderNavigationDesktop === 'arrows' || slider?.sliderNavigationDesktop === 'both' ? true : false;
  const sliderPaginationDesktop = slider?.sliderNavigationDesktop === 'dots' || slider?.sliderNavigationDesktop === 'both' ? true : false;
  const sliderArrowsMobile = slider?.sliderNavigationMobile === 'arrows' || slider?.sliderNavigationMobile === 'both' ? true : false;
  const sliderPaginationMobile = slider?.sliderNavigationMobile === 'dots' || slider?.sliderNavigationMobile === 'both' ? true : false;

  const [recommendedProducts, setRecommendedProducts] = useState([]);

  const fetchRecommendations = useCallback(async (productId) => {
    try {
      if (!productId) return;

      const endpoint = '/api/productSimilarRecommendations';
      const options = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: JSON.stringify({
          action: 'getProductSimilarRecommendations',
          productId,
        }),
      };

      const response = await fetch(endpoint, options);
      const data = await response.json();
      // Extract the 'results' array directly and set it in the state
      const results = data[0]?.results || [];
      setRecommendedProducts(results);
    } catch (error) {
      console.error(`fetchRecommendations error: ${error.message}`);
      throw error;
    }
  }, []);

  useEffect(() => {
    // Call the fetchRecommendations function with the provided productId
    fetchRecommendations(legacyResourceId);
  }, [fetchRecommendations, legacyResourceId]);

  return recommendedProducts?.length > 0 && (
    <div
      className={`${section?.spacing} ${
        !isFullBleedAndCentered ? 'px-contained' : ''
      }`}
    >
      <div className="m-auto flex flex-col max-md:items-center">
        <div className="flex w-full items-center gap-5 px-5">
          <h2 className="text-title-h2 text-left">
            {similarProducts?.heading}
          </h2>
        </div>

        <Swiper
          modules={[A11y, Navigation, Pagination, Keyboard]}
          a11y={{ enabled: true }}
          centeredSlides={
            isFullBleedAndCentered &&
            recommendedProducts?.length >=
              slidesPerViewMobile * 2
          }
          grabCursor
          keyboard={{
            enabled: true,
            onlyInViewport: true,
          }}
          pagination={{
            el: '.swiper-pagination',
            clickable: true,
          }}
          loop={
            isLoop &&
            recommendedProducts?.length >=
              slidesPerViewMobile * 2
          }
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            disabledClass: 'cursor-not-allowed opacity-20',
          }}
          onSwiper={setSwiper}
          slidesOffsetAfter={isFullBleedAndCentered ? 0 : 0}
          slidesOffsetBefore={isFullBleedAndCentered ? 0 : 0}
          slidesPerView={slidesPerViewMobile}
          spaceBetween={20}
          breakpoints={{
            768: {
              centeredSlides:
                isFullBleedAndCentered &&
                recommendedProducts?.length >=
                  slidesPerViewTablet * 2,
              loop:
                isLoop &&
                recommendedProducts?.length >=
                  slidesPerViewTablet * 2,
              slidesPerView: slidesPerViewTablet,
              slidesOffsetBefore: isFullBleedAndCentered ? 0 : 0,
              slidesOffsetAfter: isFullBleedAndCentered ? 0 : 0,
            },
            1024: {
              centeredSlides:
                isFullBleedAndCentered &&
                recommendedProducts?.length >=
                  slidesPerViewDesktop * 2,
              loop:
                isLoop &&
                recommendedProducts?.length >=
                  slidesPerViewDesktop * 2,
              slidesPerView: slidesPerViewDesktop,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
          }}
          className={`relative mt-5 w-full md:mt-10 ${maxWidthClass} ${
            sliderStyle === 'fullBleedWithGradient'
              ? 'before:swiper-offset-gradient-270-left after:swiper-offset-gradient-270-right'
              : ''
          }`}
          role="region"
          aria-label={`${similarProducts?.heading} Slider`}
          tabIndex="0"
        >
          {swiper &&
            recommendedProducts.map((product, index) => {
              const { url } = product?.mappings?.core;
              const cleanUrl = url
                ? url.substring(
                    url.lastIndexOf('/products/') + '/products/'.length
                  )
                : '';

              return (
                <SwiperSlide
                  key={`product-similar-${index}`}
                  className="pb-px md:h-auto"
                >
                  <ProductItem
                    key={`${product.id}-${index}`}
                    enabledColorNameOnHover={
                      productItem?.enabledColorNameOnHover
                    }
                    enabledColorSelector={productItem?.enabledColorSelector}
                    enabledQuickShop={productItem?.enabledQuickShop}
                    enabledQuickShopBIS={productItem?.enabledQuickShopBIS}
                    enabledStarRating={productItem?.enabledStarRating}
                    enabledProductType={productItem?.enabledProductType}
                    handle={cleanUrl}
                    index={index}
                    swatchesMap={swatchesMap}
                  />
                </SwiperSlide>
              );
            })}

          {recommendedProducts?.length > slidesPerViewMobile && (
             <>
              <div className="z-1 absolute left-0 right-0 top-[calc(50%-28px)] md:px-8 xl:px-14">
                <div
                  className={`relative mx-auto ${maxWidthClass} ${
                    isFullBleedAndCentered ? 'min-[90rem]:max-w-full' : ''
                  }`}
                >
                  <div
                    className={`swiper-button-prev shadow-hairline left-2.5 top-[calc(50%-1.6875rem)] bg-slate-50/60 h-7 w-7 md:!h-14 md:!w-14 after:hidden ${
                      sliderArrowsDesktop ? 'lg:flex' : 'lg:hidden'
                    } ${sliderArrowsMobile ? 'flex' : '!hidden'} ${
                      !isFullBleedAndCentered ? 'xl:-left-[1.6875rem]' : ''
                    }`}
                    aria-label="Previous Slide"
                    type="button"
                    role="button"
                  >
                    <Svg
                      className="w-2.5 md:w-5 text-black"
                      src="/svgs/arrow-left.svg#arrow-left"
                      alt="Previous Slide Icon"
                      title="Arrow Left"
                      viewBox="0 0 24 24"
                    />
                  </div>

                  <div
                    className={`swiper-button-next shadow-hairline right-2.5 top-[calc(50%-1.6875rem)] bg-slate-50/60 h-7 w-7 md:!h-14 md:!w-14 after:hidden ${
                      sliderArrowsDesktop ? 'lg:flex' : 'lg:hidden'
                    } ${sliderArrowsMobile ? 'flex' : '!hidden'} ${
                      !isFullBleedAndCentered ? 'xl:-right-[1.6875rem]' : ''
                    }`}
                    aria-label="Next Slide Icon"
                    type="button"
                    role="button"
                  >
                    <Svg
                      className="w-2.5 md:w-5 text-black"
                      src="/svgs/arrow-right.svg#arrow-right"
                      alt="Next Slide icon"
                      title="Arrow Right"
                      viewBox="0 0 24 24"
                    />
                  </div>
                </div>
              </div>

              <div
                className={`active-bullet-black swiper-pagination relative !bottom-0 !top-0 mt-2.5 w-full justify-center gap-2.5 ${
                  sliderPaginationDesktop ? 'lg:flex' : 'lg:hidden'
                } ${
                  sliderPaginationMobile ? 'flex' : '!hidden'}
                `}
              />
            </>
          )}
        </Swiper>

        {!swiper && (
          <div className="relative flex min-h-[20rem] items-center justify-center">
            <Spinner width="32" />
          </div>
        )}
      </div>
    </div>
  );
}

ProductSimilarRecommendations.displayName = 'ProductSimilarRecommendations';
