import { useCartCount } from '@backpackjs/storefront';

import { Svg } from '../Svg';

export function CartHeader({ closeCart, heading }) {
  const cartCount = useCartCount();

  return (
    <div className="h-[var(--menu-height)] relative flex items-center shadow-hairline-b bg-white transition gap-4 mb-[0.5px]">
      <div className="h-full flex items-center pl-5">
        <button
          aria-label="Close cart"
          className="w-6"
          onClick={closeCart}
          type="button"
        >
          <Svg
              className="w-full text-text"
              src="/svgs/close.svg#close"
              alt="Close Cart Icon"
              title="Close"
              viewBox="0 0 32 32"
            />
        </button>
      </div>

      <h3 className="text-center text-lg">{heading || 'My Cart'}</h3>

      <div className="flex items-center ml-auto px-5">
        <Svg
          className="w-5 text-text"
          src="/svgs/cart.svg#cart"
          alt="Cart Icon"
          title="Cart"
          viewBox="0 0 32 32"
        />

        <p className="text-label-sm w-4 whitespace-nowrap pl-px font-bold">
          ({cartCount || 0})
        </p>
      </div>
    </div>
  );
}

CartHeader.displayName = 'CartHeader';
