import { Schema } from './SearchResults.schema';
import { useSettings } from '@backpackjs/storefront';

import { SearchResultsFuse } from './SearchResultsFuse';
// import { SearchResultsSearchspring } from './SearchResultsSearchspring';
import { CollectionSearch } from '../../snippets/Collection/CollectionSearch';

export function SearchResults() {
  const settings = useSettings();

  const appEnabled = settings?.search?.appEnabled;

  return (
    <>
      {appEnabled ? (
        <CollectionSearch />
      ) : (
        <SearchResultsFuse />
      )}
    </>
  )
}

SearchResults.displayName = 'SearchResults';
SearchResults.Schema = Schema;
